import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LiftGroup } from 'app/services/api.types';
@Component({
  selector: 'group-lift-card',
  templateUrl: './group-lift-card.component.html',
  styleUrls: ['./group-lift-card.component.scss'],
})
export class GroupLiftCardComponent implements OnInit {
  @Input() liftGroup: LiftGroup;

  public ngOnInit(): void {}
}
