import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { INotification } from 'app/layout/common/notifications/notifications.types';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private _notifications: ReplaySubject<INotification[]> = new ReplaySubject<
    INotification[]
  >(1);
  private headers = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  /**
   * Get all notifications
   */
  updateCounter() {
    this.getCounter().subscribe((data: any) => {
      if (data.result) {
        this._notifications.next(data.result.list);
      }
    });
  }

  getNotificationList(
    id: string,
    page: number,
    size: number,
    checked: boolean
  ): Observable<any> {
    const FILTER = [
      {
        name: 'status',
        column: 'notifications.read',
        operator: 'eq',
        value: ['false'],
        extra: '',
        dataType: '',
        virtual: false,
      },
    ];
    const param = {
      pageNo: page,
      pageSize: size,
      order: [
        {
          name: 'CreatedTime',
          column: 'notifications.created_at',
          type: 'desc',
        },
      ],
      filter: [
        {
          name: 'userId',
          column: 'notifications.user_id',
          operator: 'eq',
          value: [id],
          extra: '',
          dataType: '',
          virtual: false,
        },
        ...(checked ? FILTER : []),
      ],
    };

    return this._httpClient.post<any>(
      'api/v2/notification/list',
      param,
      this.headers
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for notifications
   */
  get notifications$(): Observable<INotification[]> {
    return this._notifications.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Store notifications on the service
   *
   * @param notifications
   */
  store(notifications: INotification[]): Observable<INotification[]> {
    // Load the notifications
    this._notifications.next(notifications);

    // Return the notifications
    return this.notifications$;
  }

  /**
   * Create a notification
   *
   * @param notification
   */
  // create(notification: Notification): Observable<Notification>
  // {
  //     return this.notifications$.pipe(
  //         take(1),
  //         switchMap(notifications => this._httpClient.post<Notification>('api/common/notifications', {notification}).pipe(
  //             map((newNotification) => {

  //                 // Update the notifications with the new notification
  //                 this._notifications.next([...notifications, newNotification]);

  //                 // Return the new notification from observable
  //                 return newNotification;
  //             })
  //         ))
  //     );
  // }

  /**
   * Read the notification
   *
   * @param id
   * @param notification
   */
  readNotification(notification: INotification): Observable<INotification> {
    return this._httpClient.put<INotification>(
      '/api/v2/notification',
      {
        id: notification.id,
        read: true,
      },
      this.headers
    );
  }

  /**
   * Delete the notification
   *
   * @param id
   */
  // delete(id: string): Observable<boolean>
  // {
  //     return this.notifications$.pipe(
  //         take(1),
  //         switchMap(notifications => this._httpClient.delete<boolean>('api/common/notifications', {params: {id}}).pipe(
  //             map((isDeleted: boolean) => {

  //                 // Find the index of the deleted notification
  //                 const index = notifications.findIndex(item => item.id === id);

  //                 // Delete the notification
  //                 notifications.splice(index, 1);

  //                 // Update the notifications
  //                 this._notifications.next(notifications);

  //                 // Return the deleted status
  //                 return isDeleted;
  //             })
  //         ))
  //     );
  // }

  // get Counter
  getCounter(): Observable<any> {
    return this._httpClient.get<any>('api/v2/count/notification', this.headers);
  }

  /**
   * Mark all notifications as read
   *
   * @param id
   * @param notification
   */
  markAllAsRead(): Observable<any> {
    return this._httpClient.patch<any>(
      '/api/v2/notification',
      { action: 'read' },
      this.headers
    );
  }
}
