import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MissionCardModule } from './mission-card/mission-card.module';
import { EventCardModule } from './event-card/event-card.module';

import { CardComponent } from './card.component';
import { LogHistoryCardModule } from './log-history-card/log-history-card.module';

@NgModule({
  declarations: [CardComponent],
  imports: [
    CommonModule,
    MissionCardModule,
    EventCardModule,
    LogHistoryCardModule,
  ],
  exports: [CardComponent],
})
export class CardModule {}
