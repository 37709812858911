import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IResponse,
  IRobotMap,
} from 'app/modules/settings/robot_maps/robot_maps.model';
import { RobotMapsService } from 'app/modules/settings/robot_maps/robot_maps.service';
import _ from 'lodash';

@Component({
  selector: 'robotmaps-dropdown-input',
  templateUrl: './robotmaps-dropdown-input.component.html',
  styleUrls: ['./robotmaps-dropdown-input.component.scss'],
})
export class RobotMapsDropdownInputComponent implements OnInit {
  @Input() robotMap: IRobotMap;
  @Input() panelClass: string;
  @Input() isShowValidationError: boolean;
  @Input() disabled: boolean = false;
  mapTypes = [];
  filterMapTypes = [];

  constructor(
    private _service: RobotMapsService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getMapTypes();
  }

  getMapTypes() {
    this._service.getMapTypes().subscribe((data: IResponse) => {
      switch (data.code) {
        case 200:
          this.mapTypes = [];
          const ranks = {
            GIS: 1,
            ROS: 2,
            LIDAR: 3,
          };
          const newData = data.result.map((i) => {
            return {
              ...i,
              sortOrder: ranks[i.dictValue],
            };
          });
          const sorted = _.sortBy(newData, 'sortOrder');
          sorted.forEach((i) => {
            if (i.dictValue === 'ROS') {
              this.mapTypes.push(i.dictValue);
            }
          });
          this.filterMapTypes = this.mapTypes;
          break;
      }
    });
  }

  createMapType(_value) {
    this._service.createMapType(_value, _value).subscribe((data: IResponse) => {
      switch (data.code) {
        case 200:
          this.getMapTypes();
          break;
      }
    });
  }

  handleMapTypeChange(selected: string) {
    this._service.isDirty = true;
    this.robotMap.type = selected;
  }

  handleInputChange(e) {
    if (!e.includes('+')) {
      this.robotMap.type = e;
    } else {
      this.robotMap.type = e.split('+').pop().trim();
      this.createMapType(this.robotMap.type);
    }
  }

  onTypeUpdate(e) {
    this._service.isDirty = true;
    this.robotMap.type = e;
    this.filterMapTypes = this.mapTypes.filter((item) => {
      return item.toLowerCase().includes(e.toLowerCase());
    });

    if (this.filterMapTypes.length === 0) {
      this.filterMapTypes.push(`New+ ${e}`);
    }
  }
}
