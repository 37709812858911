import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent implements OnInit {
  @Input() type: string;
  @Input() small: boolean = false;
  @Input() disabled: boolean;
  @Output() clickEvent = new EventEmitter();

  ngOnInit(): void {}

  handleAction(): void {
    this.clickEvent.emit();
  }
}
