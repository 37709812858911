import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Card, CardEvent } from 'app/shared/interface/card';
import { Mission } from 'app/shared/interface/mission';
import { Event } from 'app/shared/interface/event';
import { LogHistory } from 'app/shared/interface/logHistory';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() type: string;
  @Input() item: Mission | Event | LogHistory;
  @Input() hideRobotInfo: boolean;
  @Input() hideLocationInfo: boolean = false;
  @Output() cardEvent = new EventEmitter<CardEvent>();

  card: Card = {
    isOpen: true,
    isSelected: false,
  };

  constructor() {}

  ngOnInit(): void {}

  onCardEvent(event: CardEvent): void {
    this.cardEvent.emit(event);
  }
}
