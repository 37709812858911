export const MARKER_TYPE = {
  marker: 'marker',
  lift: 'lift-marker',
  sensor: 'sensor',
  transitPoint: 'transit',
  waitingPoint: 'waiting',
  exitPoint: 'exit',
  startPoint: 'start',
  endPoint: 'end',
  charging: 'charging-marker',
  door: 'door-marker',
  trafficGraph: 'graph',
  zoneNode: 'zone',
  linkArea: 'link-area',
  linkareaEntry: 'linkarea-entry',
  linkareaExit: 'linkarea-exit',
  fireHolding: 'emergency-parking-point',
};

export const AVAILABLE_ZONE_AREA_COLOR = {
  blue: '#4c9aff',
  red: '#ff7452',
  yellow: '#ffc400',
  grey: '#bfbfbf',
  violet: '#8777d9',
  green: '#2aad27',
  indigo: '#04b4cd',
};
