import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, switchMap, map } from 'rxjs/operators';

interface AssetsCreateResponse {
  code: number;
  error: string;
  message: string;
  result: string;
}

@Injectable({
  providedIn: 'root',
})
export class FileInputService {
  watchIdValue$ = new ReplaySubject<string>();

  constructor(private http: HttpClient) {}

  uploadFile(file: File): Observable<any> {
    return this.http
      .post('/api/v2/asset/create', {
        name: file.name,
        description: file.type,
      })
      .pipe(
        switchMap((response: AssetsCreateResponse) => {
          const formData = new FormData();

          formData.append('id', response.result);
          formData.append('file', file);

          return this.http
            .post('/api/v2/asset/upload', formData, {
              reportProgress: true,
              observe: 'events',
            })
            .pipe(
              map((res: any) => ({
                ...res,
                body: { ...res.body, result: response.result },
              }))
            );
        })
      );
  }

  getAsset(id: string): Observable<any> {
    return this.http
      .get(`/api/v2/asset/get-info/${id}`)
      .pipe(catchError(this.errorMgmt));
  }

  removeAsset(id: string): Observable<any> {
    return this.http
      .delete(`/api/v2/asset/${id}`)
      .pipe(catchError(this.errorMgmt));
  }

  errorMgmt(error: HttpErrorResponse) {
    // console.log('ERR', error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return errorMessage;
  }
}
