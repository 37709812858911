import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBase } from 'app/services/api-base.service';
import { Observable } from 'rxjs';
import { ResponseOne } from './api.types';

@Injectable({
  providedIn: 'root',
})
export class ApiMonitor extends ApiBase<any> {
  constructor(http: HttpClient) {
    super(http);
    this.resourceBaseUrl = 'monitor';
  }

  getLayoutRbtSum(layoutId: string) {
    if (layoutId) {
      return this.get(`monitor/robot-summary?layoutId=${layoutId}`);
    } else {
      return this.get('monitor/robot-summary');
    }
  }

  getDataDictByType(moduleName: string) {
    return this.post('datadict/getByType', {
      dictType: moduleName,
    });
  }

  getMESummary(layoutId: string) {
    if (layoutId) {
      return this.get(`monitor/overview?layoutId=${layoutId}`);
    } else {
      return this.get('monitor/overview');
    }
  }

  getComponies() {
    return this.http.get(`/api/v2/get-companies`);
  }

  getVideoAccessToken(): Observable<ResponseOne<string>> {
    return this.http.get<ResponseOne<string>>(
      '/api/v2/monitor/generate-vc-token'
    );
  }
}
