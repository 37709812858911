import { Pipe, PipeTransform } from '@angular/core';
import { RoleResource, Resource } from 'app/services/api.types';

@Pipe({
  name: 'assignedresources',
})
export class AssignedResourcesFilterPipe implements PipeTransform {
  transform(
    list: RoleResource[],
    roleId: string,
    listAllResources: Resource[]
  ): string {
    const roleResourceRelation = list.filter(
      (relation) => relation.roleId === roleId
    );

    // return '-' if no role-resource relations found
    if (roleResourceRelation.length === 0) {
      return '-';
    }

    // get list of resources to show

    const allResources = roleResourceRelation.map((relation) =>
      listAllResources.find((module) => module.id === relation.resourceId)
    );

    // sort the allResources by url alphabetically
    const sortedResources = allResources.sort((a, b) =>
      a.url.localeCompare(b.url)
    );

    // return comma separated list of assigned resources
    // if there are more than 6 assigned resources, show '+n more'
    if (sortedResources.length <= 6) {
      return sortedResources
        .map(
          (item) =>
            ` ${
              item.name
                ? item.name
                : item.url.replace('/api/v2/', '').replace('/api/', '')
            } `
        )
        .join(', ');
    } else {
      return sortedResources
        .slice(0, 4)
        .map(
          (item) =>
            ` ${
              item.name
                ? item.name
                : item.url.replace('/api/v2/', '').replace('/api/', '')
            }`
        )
        .join(', ')
        .concat(
          `, +${
            sortedResources.length <= 103 ? sortedResources.length - 4 : '99'
          } more`
        );
    }
  }
}
