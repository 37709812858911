import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'app/shared/shared.module';
import { SearchModule } from '../search/search.module';
import { UserMenuModule } from '../user-menu/user-menu.module';
import { CompanyModule } from '../company/company.module';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { HeaderComponent } from './header.component';
import { NotificationsModule } from '../notifications/notifications.module';
import { MessagesModule } from '../messages/messages.module';
import { LanguageModule } from '../switch-language/language.module';
import { TranslocoService } from '@ngneat/transloco';
import { CallInterfaceModule } from '../call-interface/call-interface.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    RouterModule,
    OverlayModule,
    PortalModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    SharedModule,
    SearchModule,
    UserMenuModule,
    SharedModule,
    CompanyModule,
    BreadcrumbModule,
    NotificationsModule,
    LanguageModule,
    MessagesModule,
    CallInterfaceModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
