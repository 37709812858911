import { Component } from '@angular/core';
@Component({
  selector: 'rm-container-scroll',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        width: 100%;
        height: calc(100vh - 40px);
        overflow-y: scroll;
      }
    `,
  ],
})
export class ContainerScrollComponent {
  constructor() {}
}
