import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { RobotSensorComponent } from './robot-sensor.component';
import { MatMenuModule } from '@angular/material/menu';
import { ActionButtonModule } from '../action-button/action-button.module';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [RobotSensorComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    TranslocoRootModule,
    ActionButtonModule,
    MatCardModule,
  ],
  exports: [RobotSensorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RobotSensorModule {}
