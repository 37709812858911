/* eslint-disable arrow-parens */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'select-dropdown-input',
  templateUrl: './select-dropdown-input.component.html',
  styleUrls: ['./select-dropdown-input.component.scss'],
})
export class SelectDropdownInputComponent implements OnInit {
  @Input() title: string;
  @Input() options: any[] = [];
  @Input() iconName: string;
  @Input() btnTitle: string;
  @Output() selectChange = new EventEmitter();

  isShow: boolean = false;
  isActive: boolean = false;
  selection = new SelectionModel<any>(true, []);
  userSelects = [];

  constructor() {}
  ngOnInit(): void {
    this.selection.changed.subscribe(() => {
      this.selectChange.emit(this.selection.selected);
    });
  }

  toggleShow(): void {
    this.isShow = !this.isShow;
    this.isActive = !this.isActive;
  }

  isSelected(s: any) {
    return this.userSelects.findIndex((item) => item.name === s.name) > -1
      ? true
      : false;
  }

  selectSuggestion(s) {
    this.userSelects.find((item) => item.name === s.name)
      ? (this.userSelects = this.userSelects.filter(
          (item) => item.name !== s.name
        ))
      : this.userSelects.push(s);
    this.selectChange.emit(this.userSelects);
  }

  resetSelection() {
    this.userSelects = [];
    this.selectChange.emit(this.userSelects);
  }
}
