import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { SelectRobotComponent } from './select-robots.component';

@NgModule({
  declarations: [SelectRobotComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslocoModule,
    VirtualScrollerModule,
    MatInputModule,
    MatIconModule,
    MatSlideToggleModule,
  ],
  exports: [SelectRobotComponent],
})
export class SelectRobotModule {}
