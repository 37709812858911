import { NgModule } from '@angular/core';
import { CallInterfaceComponent } from './call-interface.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { PortalModule } from '@angular/cdk/portal';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  declarations: [CallInterfaceComponent],
  imports: [OverlayModule, MatIconModule, PortalModule, SharedModule],
  exports: [CallInterfaceComponent],
})
export class CallInterfaceModule {}
