import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CallInterfaceService {
  private headers = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  /**
   * Constructor
   */
  constructor(private _http: HttpClient) {}

  getRobotDetails(id): Observable<any> {
    return this._http.get('/api/v2/robot/' + id, this.headers);
  }
}
