import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Language, LANGUAGES } from './language.model';

@Component({
  selector: 'app-switch-language',
  templateUrl: './switch-language.component.html',
  styleUrls: ['./switch-language.component.scss'],
})
export class SwitchLanguageComponent implements OnInit {
  languagesListArr = LANGUAGES;
  defaultLan: Language = this.languagesListArr[0];
  isExpanded: boolean = false;
  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    const languageType: string = localStorage.getItem('lag');
    if (languageType) {
      this.translocoService.setActiveLang(languageType);
      this.defaultLan = this.languagesListArr.find(
        (item) => item.type === languageType
      );
    } else {
      const localLag = navigator.language;
      this.defaultLan = this.getSameLangArr(localLag, this.languagesListArr);
      if (this.defaultLan.type) {
        this.translocoService.setActiveLang(this.defaultLan.type);
        localStorage.setItem('lag', this.defaultLan['type']);
      } else {
        this.translocoService.setActiveLang(this.languagesListArr[0].type);
        localStorage.setItem('lag', this.languagesListArr[0].type);
      }
    }
  }

  getSameLangArr(localLag, lagArr): Language {
    const lag = lagArr.find((language) =>
      localLag.toLowerCase().includes(language.type)
    );
    if (lag) {
      return lag;
    } else {
      return this.languagesListArr[0];
    }
  }

  switchLang(type) {
    this.translocoService.setActiveLang(type);
  }

  switchLanguage(e) {
    this.isExpanded = !this.isExpanded;
    this.switchLang(e.type);
    localStorage.setItem('lag', e.type);
    this.defaultLan = this.languagesListArr.find((lag) => lag.type == e.type);
  }
}
