import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rm-clickable-label-value',
  template: `
    <div class="my-4">
      <label class=" font-bold">{{ label }}</label>
      <div>
        <span
          [ngClass]="{
            'text-cyan': value,
            'cursor-pointer': value
          }"
          (click)="onHandleClick()"
        >
          {{ value ? value : '-' }}
        </span>
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class ClickableLabelValueComponent {
  @Input() label: string;
  @Input() value: string;
  @Output() handleClick: EventEmitter<void> = new EventEmitter<void>();

  public onHandleClick(): void {
    if (this.value) {
      this.handleClick.emit();
    }
  }
}
