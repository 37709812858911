import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RightSidebarLayoutService {
  toggleSidebar$ = new ReplaySubject<boolean>();

  constructor() {}
}
