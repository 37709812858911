import { Component, Input } from '@angular/core';
import { Notification } from 'app/services/api.types';

@Component({
  selector: 'rm-notification-detail',
  template: `
    <div *ngIf="item" class="mt-6">
      <ng-container *transloco="let t">
        <mat-divider></mat-divider>
        <rm-label-value [label]="t('ID')">
          {{ item.id }}
        </rm-label-value>
        <rm-label-value [label]="t('Date')">
          {{ item.createdAt | date: 'short' }}
        </rm-label-value>
        <rm-label-value [label]="t('Title')">
          {{ item.title }}
        </rm-label-value>
        <rm-label-value [label]="t('Content')">
          {{ item.content }}
        </rm-label-value>
        <rm-label-value [label]="t('Notification Source')">
          {{ item.moduleName | titlecase }}
          <rm-short-uuid [uuid]="item.url"></rm-short-uuid>
        </rm-label-value>
      </ng-container>
    </div>
  `,
})
export class NotificationDetailComponent {
  @Input() item: Notification;

  constructor() {}
}
