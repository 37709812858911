import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { MatMenuModule } from '@angular/material/menu';
import { ActionButtonModule } from '../action-button/action-button.module';
import { DoorCardComponent } from './door-card.component';
import { TextSofiaModule } from 'app/shared/ui-components/text-sofia/text-sofia.module';
@NgModule({
  declarations: [DoorCardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    TranslocoRootModule,
    ActionButtonModule,
    TextSofiaModule,
  ],
  exports: [DoorCardComponent],
})
export class DoorCardModule {}
