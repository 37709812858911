import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBase } from './api-base.service';
import { Layout } from './api.types';

@Injectable({
  providedIn: 'root',
})
export class ApiLayout extends ApiBase<Layout> {
  constructor(http: HttpClient) {
    super(http);
    this.resourceBaseUrl = 'layouts';
  }

  // @todo refactor this. This should be in the api-asset.service.ts
  get3DFileInfo(id: string) {
    return this.http.get(`/api/v2/asset/get-info/${id}`);
  }
}
