import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { mainMenuAccessConfig } from 'app/modules/auth/access-config.constant';

@Injectable({
  providedIn: 'root',
})
export class MainAccessGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> {
    const userRole = this.authService.role;
    console.log(
      'mainMenuAccessConfig',
      mainMenuAccessConfig[route.data.menuItemId]?.includes(userRole)
    );

    // Check if the user's role is allowed for the route.
    if (mainMenuAccessConfig[route.data.menuItemId]?.includes(userRole)) {
      return true;
    }

    // If the user's role is not allowed, navigate to a forbidden page or return a UrlTree to redirect.
    this.router.navigate(['/forbidden']);
    return false;
  }
}
