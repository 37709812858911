import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissionCardComponent } from './mission-card.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TextSofiaModule } from 'app/shared/ui-components/text-sofia/text-sofia.module';
import { DirectiveModule } from 'app/shared/directives/directive.module';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { StatusModule } from '../../status.component';

@NgModule({
  declarations: [MissionCardComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TextSofiaModule,
    DirectiveModule,
    TranslocoRootModule,
    StatusModule,
  ],
  exports: [MissionCardComponent],
})
export class MissionCardModule {}
