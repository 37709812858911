import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar.component';
import { RobotAvatarComponent } from './robot-avatar';
import { MatBadgeModule } from '@angular/material/badge';
import { AppsAvatarComponent } from './apps-avatar';

@NgModule({
  declarations: [AvatarComponent, RobotAvatarComponent, AppsAvatarComponent],
  imports: [CommonModule, MatBadgeModule],
  exports: [AvatarComponent, RobotAvatarComponent, AppsAvatarComponent],
})
export class AvatarModule {}
