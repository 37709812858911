import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthInterceptor } from 'app/core/auth/auth.interceptor';
// import { TranslocoCoreModule } from './transloco/transloco.module';

@NgModule({
  imports: [
    HttpClientModule,
    // TranslocoCoreModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(
    private _domSanitizer: DomSanitizer,
    private _matIconRegistry: MatIconRegistry,
    @Optional() @SkipSelf() parentModule?: CoreModule
  ) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. Import this module in the AppModule only.'
      );
    }

    // Register icon sets
    this._matIconRegistry.addSvgIconSet(
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/material-twotone.svg'
      )
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'mat_outline',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/material-outline.svg'
      )
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'mat_solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/material-solid.svg'
      )
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'iconsmind',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/iconsmind.svg'
      )
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'feather',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/feather.svg'
      )
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_outline',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/heroicons-outline.svg'
      )
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/heroicons-solid.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'rm_robot',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/robot-black.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'rm_edit',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'vid-audio-on',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/vid-audio-on.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'vid-audio-muted',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/vid-audio-muted.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'vid-camera-on',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/vid-camera-on.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'vid-camera-off',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/vid-camera-off.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'marker-tool',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/marker-tool.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'lift-floor-tool',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/lift-floor-tool.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'charging-point-tool',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/charging-point-tool.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'door-tool',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/door-tool.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'traffic-graph-tool',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/traffic-graph-tool.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'zone-tool',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/zone-tool.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'single-lane',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/single-lane-map-legends.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'double-lane',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/double-lane-map-legends.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'fire-alarm-tool',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/fire-alarm-tool.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'link-area',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/link-fill.svg'
      )
    );
  }
}
