import { RoleEnum } from './role.constant';

// Define a configuration object that maps main menu item IDs to allowed roles.
export const mainMenuAccessConfig: Record<string, string[]> = {
  opsApps: [
    RoleEnum.superAdmin,
    RoleEnum.admin,
    RoleEnum.developer,
    RoleEnum.operator,
  ],
  opsMonitoring: [
    RoleEnum.superAdmin,
    RoleEnum.admin,
    RoleEnum.developer,
    RoleEnum.operator,
  ],
  opsVideos: [
    RoleEnum.superAdmin,
    RoleEnum.admin,
    RoleEnum.developer,
    RoleEnum.operator,
  ],
  opsEvents: [
    RoleEnum.superAdmin,
    RoleEnum.admin,
    RoleEnum.developer,
    RoleEnum.operator,
  ],
  opsMissions: [
    RoleEnum.superAdmin,
    RoleEnum.admin,
    RoleEnum.developer,
    RoleEnum.operator,
  ],
  opsRobots: [
    RoleEnum.superAdmin,
    RoleEnum.admin,
    RoleEnum.developer,
    RoleEnum.operator,
  ],
  opsLifts: [
    RoleEnum.superAdmin,
    RoleEnum.admin,
    RoleEnum.developer,
    RoleEnum.operator,
  ],
  opsDoors: [
    RoleEnum.superAdmin,
    RoleEnum.admin,
    RoleEnum.developer,
    RoleEnum.operator,
  ],
};

// Define a configuration object that maps settings/sub menu item IDs to allowed roles.
export const subMenuAccessConfig: Record<string, string[]> = {
  overview: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  robotParent: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  robots: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  skills: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  robotMaps: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  robotBatteryPolicy: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  videoAnalyticsParent: [
    RoleEnum.superAdmin,
    RoleEnum.admin,
    RoleEnum.developer,
  ],
  vaProviders: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  vaZones: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  operationsParent: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  layouts: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  missions: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  activityLogs: [RoleEnum.superAdmin, RoleEnum.admin],
  infrastructureParent: [
    RoleEnum.superAdmin,
    RoleEnum.admin,
    RoleEnum.developer,
  ],
  eventSources: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  lifts: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  doors: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  accountParent: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  users: [RoleEnum.superAdmin, RoleEnum.admin],
  company: [RoleEnum.superAdmin, RoleEnum.admin],
  accesskey: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  appsParent: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  apps: [RoleEnum.superAdmin, RoleEnum.admin, RoleEnum.developer],
  linkAreas: [RoleEnum.superAdmin, RoleEnum.admin],
  reportIssue: [RoleEnum.superAdmin, RoleEnum.admin],
};
