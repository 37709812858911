import { Observable } from 'rxjs';
import hash from 'hash-it';
import * as moment from 'moment';
import { ResponseOne } from './api.types';

export class CacheService<T> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly CACHE_DURATION_IN_MINUTES = 5;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly DEFAULT_KEY = 'RMCORE';

  private cache: {
    [id: string]: {
      expires: Date;
      value: Observable<ResponseOne<T>>;
    };
  } = {};

  private listCache: {
    [id: string]: {
      expires: Date;
      value: Observable<T[]>;
    };
  } = {};

  public getValue(object?: any): Observable<ResponseOne<T>> {
    const key = object ? hash(object).toString() : this.DEFAULT_KEY;
    const item = this.cache[key];
    if (!item) {
      return null;
    }

    if (moment(new Date()).isAfter(item.expires)) {
      return null;
    }

    return item.value;
  }

  public setValue(value: Observable<ResponseOne<T>>, object?: any): void {
    const key = object ? hash(object).toString() : this.DEFAULT_KEY;
    const expires = moment(new Date())
      .add(this.CACHE_DURATION_IN_MINUTES, 'minutes')
      .toDate();
    this.cache[key] = { expires, value };
  }

  public clearCache(): void {
    this.cache = {};
  }

  public getListValue(object?: any): Observable<T[]> {
    const key = object ? hash(object).toString() : this.DEFAULT_KEY;
    const item = this.listCache[key];
    if (!item) {
      return null;
    }

    if (moment(new Date()).isAfter(item.expires)) {
      return null;
    }

    return item.value;
  }

  public setListValue(value: Observable<T[]>, object?: any): void {
    const key = object ? hash(object).toString() : this.DEFAULT_KEY;
    const expires = moment(new Date())
      .add(this.CACHE_DURATION_IN_MINUTES, 'minutes')
      .toDate();
    this.listCache[key] = { expires, value };
  }

  public clearListCache(): void {
    this.listCache = {};
  }
}
