import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Card, CardEvent } from 'app/shared/interface/card';
import { Event as IEvent } from 'app/shared/interface/event';
import { CardService } from '../card.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IResponse } from 'app/modules/robots/robots.model';
import { SnackbarService } from 'app/services/snackbarService';
import { Utils } from 'app/services/utils';

@Component({
  selector: 'event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit, OnDestroy {
  @Input() item: IEvent;
  @Input() card: Card;
  @Input() hideRobotInfo: boolean;
  @Input() hideLocationInfo: boolean;
  @Output() action = new EventEmitter<CardEvent>();
  mediaIndex: number = 0;
  media: Object;

  constructor(
    private _cardService: CardService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router,
    private _snackbar: SnackbarService,
    private utils: Utils
  ) {}

  ngOnInit(): void {
    // this.item.isSelected = false;
    this.item.date = new Date(this.item.date).toLocaleString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    if (this.item.mediaFiles) {
      this.item.mediaFiles.map((mediaFile) => {
        this._cardService
          .getAsset(mediaFile.mediaUrl)
          .subscribe((result: any) => {
            const unsafeImageUrl = URL.createObjectURL(result);
            mediaFile.blob =
              this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
          });
        return mediaFile;
      });
      if (this.item.mediaFiles) {
        this.media = this.item.mediaFiles[this.mediaIndex];
      } else {
        this.media = null;
      }
    }
  }

  ngOnDestroy(): void {}

  handleNavigate(type: string, event: Event): void {
    event.stopPropagation();
    if (type === 'event') {
      this.action.emit({
        type: 'navigate',
        id: this.item.id,
        data: type,
      });
      // this.router.navigate([`/events/details/${this.item.id}`]);
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/events/details/${this.item.id}`])
      );

      window.open(url, '_blank');
    } else if (type === 'source') {
      this.action.emit({
        type: 'navigate',
        id: this.item.robotId,
        data: type,
      });
      // this.router.navigate([`/robots/details/${this.item.robotId}`]);
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/robots/details/${this.item.robotId}`])
      );

      window.open(url, '_blank');
    }
  }

  toggleFlag(): void {
    this.item.severity = this.item.severity > 1 ? 1 : 2;
    const updateEventSeverityPayload = {
      id: this.item.id,
      severity: this.item.severity,
    };
    this._cardService
      .updateEvent(updateEventSeverityPayload)
      .subscribe((response: IResponse) => {
        switch (response.code) {
          case 200:
            break;
          case 400:
            console.log('updateEvent error', response);
            break;
          default:
            console.log(response.code, response.message);
        }
      });

    this.action.emit({
      type: 'flag',
      id: this.item.id,
      data: this.item.severity,
    });
  }

  toggleAction(type: string, event: Event): void {
    event.stopPropagation();
    switch (type) {
      case 'resolve':
        this.item.status = 1;
        break;
      case 'unresolve':
        this.item.status = 2;
        break;
    }
    const updateEventStatusPayload = {
      id: this.item.id,
      status: this.item.status,
    };
    this._cardService
      .updateEvent(updateEventStatusPayload)
      .subscribe((response: IResponse) => {
        switch (response.code) {
          case 200:
            // foundEvent.status = updateEventStatusPayload.status;
            this._snackbar.openSnackBar({
              message: this.utils.getTranslation('Success'),
              type: 'success',
            });
            break;
          case 400:
            this._snackbar.openSnackBar({
              message: response.message,
              type: 'failed',
            });
            console.log('updateEvent error', response);
            break;
          default:
            console.log(response.code, response.message);
        }
      });
    this.action.emit({
      type: 'action',
      id: this.item.id,
      data: type,
    });
  }

  toggleSelected(): void {
    this.card.isSelected = !this.card.isSelected;
    this.action.emit({
      type: 'select',
      id: this.item.id,
      data: this.card.isSelected,
    });
  }

  /**
   * Replace the media file with 1x1 transparent image if cant load the image file.
   * Apply the display: none style to the media element.
   *
   * @param event
   */
  hideErrorImage(event: MouseEvent): void {
    (event.target as HTMLImageElement).src =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  }

  /**
   * Make the video player width full after click play button
   *
   * @param event
   */
  fullsizeVideo(event: MouseEvent): void {
    console.log(event);
    (event.target as HTMLVideoElement).classList.add('w-full');
  }
}
