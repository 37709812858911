import {
  ListQuery,
  ResponseList,
  Map,
  ResponseOne,
  Skill,
  Camera,
} from './../../../services/api.types';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { RobotMapRelation } from './robot';
import { IRobot } from 'app/modules/robots/robots.model';

@Injectable({
  providedIn: 'root',
})
export class RobotService {
  copiedAccessKey: string;
  isDirty = false;
  robotData = new Subject<IRobot>();
  cameraData = new Subject<Camera[]>();
  _robotData: IRobot;
  _cameraData: Camera[];
  sidebarHeader: string;

  //replay subject as variable to remove image profile and thread if user discard the input/saved
  public isRemoveImage = new ReplaySubject<boolean>(1);

  /** Constructor **/
  constructor(private http: HttpClient) {}
  private headers = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  setRobotData(data) {
    this._robotData = data;
    this.robotData.next(this._robotData);
  }

  setCameraData(data) {
    this._cameraData = data;
    this.cameraData.next(this._cameraData);
  }

  /** Profile Save **/
  /** @param profileData **/
  saveProfile(profileData) {
    // return null;
    return this.http.post('/api/reset-password', profileData);
  }

  /** Get Robot List **/
  getRobotData(data): Observable<any> {
    return this.http.post('/api/v2/robot/list', data, this.headers);
  }

  /** Get Robot Data By ID **/
  getRobotDataByID(id: string): Observable<ResponseOne<IRobot>> {
    return this.http.get<ResponseOne<IRobot>>(
      `/api/v2/robot/${id}`,
      this.headers
    );
  }

  /** Delete Robot Data By ID **/
  deleteRobotDataByID(ID) {
    return this.http.delete('/api/v2/robot/' + ID, this.headers);
  }

  getDropdownData(dropdownData) {
    return this.http.post(
      '/api/v2/datadict/getByType',
      dropdownData,
      this.headers
    );
  }

  /** Create Robot */
  createRobot(data) {
    return this.http.post('/api/v2/robot/create', data, this.headers);
  }

  /** Update Robot */
  updateRobot(id, data) {
    return this.http.put('api/v2/robot/' + id, data, this.headers);
  }

  /** Create datadict */
  createDatadict(data) {
    return this.http.post('/api/v2/datadict/create', data, this.headers);
  }

  /** Get all maps */
  getMapList(data: ListQuery): Observable<ResponseList<Map>> {
    return this.http.post<ResponseList<Map>>(
      '/api/v2/map/list',
      data,
      this.headers
    );
  }

  /** Assign map to robot */
  assignMaptoRobot(
    mapId: string,
    robotId: string
  ): Observable<ResponseOne<Map>> {
    const payload = {
      mapId: mapId,
      robotId: robotId,
    };
    return this.http.post<ResponseOne<Map>>(
      '/api/v2/robot-map',
      payload,
      this.headers
    );
  }

  /** Unassign map from robot */
  unassignMapFromRobot(
    mapId: string,
    robotId: string
  ): Observable<ResponseOne<Map>> {
    const payload = {
      mapId: mapId,
      robotId: robotId,
    };
    return this.http.post<ResponseOne<Map>>(
      '/api/v2/robot-map/unbind',
      payload,
      this.headers
    );
    // return this.http.delete(`/api/v2/robot-map/${relationshipid}`, this.headers);
  }

  /** Get skills list */
  getSkillsList(data) {
    return this.http.post('/api/v2/skill/list', data, this.headers);
  }

  /** Get skillset list */
  getSkillsetList(data) {
    return this.http.post('/api/v2/skillset/list', data, this.headers);
  }

  /** Assign skills to robot */
  assignSkills(
    robotId: string,
    skillId: string
  ): Observable<ResponseOne<void>> {
    const payload = {
      robotId: robotId,
      skillId,
    };
    return this.http.post<ResponseOne<void>>(
      '/api/v2/robot/assignSkills',
      payload,
      this.headers
    );
  }

  /** Unassign skills to robot */
  unAssignSkills(
    robotId: string,
    skillId: string
  ): Observable<ResponseOne<void>> {
    const payload = {
      robotId: robotId,
      skillId,
    };
    return this.http.post<ResponseOne<void>>(
      '/api/v2/robot/unassignSkills',
      payload,
      this.headers
    );
  }

  /** get Robot skill relationship */
  getRobotSkillRelationship(
    robotIds: string[],
    skillIds: string[]
  ): Observable<ResponseOne<SkillRobot[]>> {
    const payload = {
      robotIds,
      skillsIds: skillIds,
    };
    return this.http.post<ResponseOne<SkillRobot[]>>(
      '/api/v2/robot-skills',
      payload,
      this.headers
    );
  }

  getSkillbyRobot(robotId: string): Observable<ResponseOne<SkillWithAssign[]>> {
    return this.http.post<ResponseOne<SkillWithAssign[]>>(
      `/api/v2/skill/filter-by-robot?robotIds=${robotId}`,
      this.headers
    );
  }

  /** get Robot map relationship */
  getRobotMapRelationship(data): Observable<ResponseOne<RobotMapRelation[]>> {
    return this.http.post<ResponseOne<RobotMapRelation[]>>(
      '/api/v2/robot-map/list',
      data,
      this.headers
    );
  }

  /** Get file from assets */
  getAssetFile(id: string): Observable<Blob> {
    return this.http.get('/api/v2/asset/get-file/' + id, {
      responseType: 'blob',
    });
  }

  /** Get asset info */
  getAssetInfo(id) {
    return this.http.get('/api/v2/asset/get-info/' + id, this.headers);
  }

  /** Create robot map */
  createRobotMap(data) {
    return this.http.post('/api/v2/map', data, this.headers);
  }
}
interface SkillRobot {
  companyId: string;
  id: string;
  skillId: string;
  robotId: string;
}

interface SkillWithAssign extends Skill {
  assigned: boolean;
}
