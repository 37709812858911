import { Location } from '@angular/common';
import {
  Component,
  HostBinding,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'top-header',
  templateUrl: './top-header.component.html',
})
export class TopHeaderComponent implements OnInit {
  @HostBinding('class') class = 'w-full';
  @Input() title: string;
  @Input() withBackButton: boolean = true;
  @Input() withCustomBack: boolean = false;
  @Output() customBack: EventEmitter<void> = new EventEmitter<void>();
  @Input() withBorder: boolean = true;

  constructor(private location: Location) {}

  ngOnInit(): void {
    this.withBackButton = this.withBackButton ?? false;
    this.withCustomBack = this.withCustomBack ?? false;
  }

  public goBack(): void {
    this.location.back();
  }
  public goCustomBack(): void {
    this.customBack.emit();
  }
}
