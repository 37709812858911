import { Pipe, PipeTransform } from '@angular/core';
import { RoleUser, User } from 'app/services/api.types';

@Pipe({
  name: 'assignedUsers',
})
export class AssignedUsersFilterPipe implements PipeTransform {
  transform(list: RoleUser[], roleId: string, listAllUsers: User[]): string {
    // get list of role-user relations from roleId param
    const usersOfRoleUser = list.filter(
      (roleUser) => roleUser.roleId === roleId
    );

    // return '-' if no usersOfRoleUser relations found
    if (usersOfRoleUser.length === 0) {
      return '-';
    }

    // get list of users to show
    const users: User[] = [];
    usersOfRoleUser.map((roleUser) => {
      listAllUsers.map((user) => {
        if (user.userId === roleUser.userId) {
          users.push(user);
        }
      });
    });

    // return comma separated list of assigned users
    // if there are more than 4 users, show '+n more'
    if (users.length <= 4) {
      return users.map((user) => user.displayName).join(', ');
    } else {
      return users
        .slice(0, 4)
        .map((user) => user.displayName)
        .join(', ')
        .concat(`, +${users.length <= 103 ? users.length - 4 : '99'} more`);
    }
  }
}
