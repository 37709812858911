import { Component, Input } from '@angular/core';

@Component({
  selector: 'rm-notification-status',
  template: `
    <div
      class="w-2 h-2 rounded-full"
      [ngClass]="{
        'bg-gray-400 dark:bg-gray-500 sm:opacity-0': isRead,
        'bg-primary': !isRead
      }"
    ></div>
  `,
})
export class NotificationStatusComponent {
  @Input() isRead: boolean;
  constructor() {}
}
