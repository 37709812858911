import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { v4 as uuid } from 'uuid';
import { SnackbarService } from 'app/services/snackbarService';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    public snackBarService: SnackbarService,
    private router: Router
  ) {}
  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone({
      setHeaders: { TraceId: uuid() },
    });
    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (this._authService.accessToken) {
      newReq = newReq.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this._authService.accessToken,
        },
      });
    }
    // Response
    return next.handle(newReq).pipe(
      // catchError((error) => {
      //     // Catch "401 Unauthorized" responses
      //     if (error instanceof HttpErrorResponse && error.status === 401) {
      //         // Sign out
      //         this._authService.signOut();
      //         // Reload the app
      //         location.reload();
      //     }
      //     return throwError(error);
      // })

      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            if (event.status == 200) {
              if (event.body.code === 401) {
                this._authService.signOut();
                // Call AuthService method to handle unauthorized events
                this._authService.handleUnauthorized();
              }
            } else {
              console.log(event.body);
            }
          }
        },
        (error) => {
          // this.openSnackBar(error.message, 'warning-red', 'warning');
          console.log(error.message);
        }
      )
    );
  }
}
