import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private translocoService: TranslocoService) {}

  public getTranslationTxt(modulename: string, str: string) {
    if (!str || str === '-') {
      return '-';
    }
    let result: string;
    if (!modulename) {
      result = this.translocoService.translate(str);
    } else {
      const regex = /\W/;
      if (regex.test(str)) {
        return this.translocoService.translate(str);
      }
      result = this.translocoService.translate(
        modulename + '.' + str.toUpperCase()
      );
    }

    if (result === modulename + '.' + str.toUpperCase()) {
      return str;
    }
    return result;
  }
}
