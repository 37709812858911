import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { SubNavi } from 'app/layout/layouts/vertical/thin/thin.service';
import { Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import {
  MAIN_NAVIGATION_ITEMS,
  SETTING_NAVIGATION_ITEMS,
} from 'app/modules/settings/constants';
import { RoleEnum } from 'app/modules/auth/role.constant';
import {
  mainMenuAccessConfig,
  subMenuAccessConfig,
} from 'app/modules/auth/access-config.constant';
import { AuthService } from 'app/core/auth/auth.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MenuService implements OnDestroy {
  private allMenus$: Observable<FuseNavigationItem[]>;
  private settingMenus$ = new ReplaySubject<SubNavi[]>();
  private mainMenus$ = new ReplaySubject<FuseNavigationItem[]>();
  private unsubscribeAll = new Subject();
  constructor(private router: Router, private _authService: AuthService) {
    this.accessMainMenu();
    this.accessSettingMenu();

    // Combine the two observables into one
    this.allMenus$ = combineLatest([this.settingMenus$, this.mainMenus$]).pipe(
      map(([settingMenus, mainMenus]) => ({
        settingMenus,
        mainMenus,
      })),
      map(this.expandSettingMenus)
    );
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private accessMainMenu(): void {
    const mainMenu = MAIN_NAVIGATION_ITEMS.map((item) => ({
      ...item,
      hidden: () => !this.hasAccess(item.id, true),
    }));
    this.mainMenus$.next(mainMenu);
  }

  private accessSettingMenu(): void {
    const settingMenu = SETTING_NAVIGATION_ITEMS.map((item) => ({
      ...item,
      hidden: () => !this.hasAccess(item.id, false),
      children: item.children
        ? item.children.map((child) => ({
            ...child,
            hidden: () => !this.hasAccess(child.id, false),
          }))
        : [],
    }));
    this.settingMenus$.next(settingMenu);
  }

  /**
   * Helper function to check if a user role has access to a menu item.
   *
   */
  private hasAccess(menuItemId: string, isMainMenu: boolean): boolean {
    if (!this._authService.role) {
      return false;
    }

    // Check if the user role is admin or super admin.
    // if user role is admin or super admin, then allow access to all menu items.
    if (
      this._authService.role === RoleEnum.admin ||
      this._authService.role === RoleEnum.superAdmin
    ) {
      return true;
    }

    // First check if the menu is main menu or sub menu,
    // then check if the user role is in the allowed roles for the menu item.
    const allowedRoles: string[] = isMainMenu
      ? mainMenuAccessConfig[menuItemId]
      : subMenuAccessConfig[menuItemId];
    return allowedRoles ? allowedRoles.includes(this._authService.role) : false;
  }

  private expandSettingMenus({
    settingMenus,
    mainMenus,
  }: CombinedMenu): FuseNavigationItem[] {
    const expandedSettingMenus: FuseNavigationItem[] = [];

    // Combine the setting menu children as one menu
    settingMenus.forEach((settingMenu) => {
      if (settingMenu.children && settingMenu.children.length > 0) {
        expandedSettingMenus.push(...settingMenu.children);
      }
    });

    return [...expandedSettingMenus, ...mainMenus];
  }

  public getSettingMenus(): Observable<SubNavi[]> {
    return this.settingMenus$.asObservable();
  }

  public getMainMenus(): Observable<FuseNavigationItem[]> {
    return this.mainMenus$.asObservable();
  }

  public getAllMenus(): Observable<FuseNavigationItem[]> {
    return this.allMenus$;
  }
}

interface CombinedMenu {
  settingMenus: SubNavi[];
  mainMenus: FuseNavigationItem[];
}
