import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectComponent } from './multi-select.component';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
  declarations: [MultiSelectComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatCheckboxModule,
    TranslocoRootModule,
  ],
  exports: [MultiSelectComponent],
})
export class MultiSelectModule {}
