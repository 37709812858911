import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'robot-avatar',
  template: `
    <div class="relative">
      <span
        class="badgePosition"
        [class.active]="status === 1"
        [class.inactive]="status === 2"
      ></span>
      <avatar [srcUrl]="src"></avatar>
    </div>
  `,
  styles: [
    `
      .badgePosition {
        position: absolute;
        top: 20px;
        left: 37px;
        height: 15px;
        width: 15px;
        border-radius: 100%;
      }
      .active {
        background: #45e310;
      }
      .inactive {
        background: gray;
      }
    `,
  ],
})
export class RobotAvatarComponent implements OnInit {
  @Input() src?: string;
  @Input() status?: number;
  constructor() {}

  ngOnInit(): void {
    this.src = this.src === '' ? 'assets/icons/robot.svg' : this.src;
  }
}
