import { TranslocoService } from '@ngneat/transloco';
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AutoCompleteService } from './autocomplete-input.service';
import { orderBy } from 'lodash';

interface InputSelection {
  value: string;
  text: string;
}

interface DataDictResponse {
  dictValue: string;
}

@Component({
  selector: 'autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
})
export class AutoCompleteInputComponent implements OnInit {
  [x: string]: any;
  @Input() modelName: 'Brand' | 'Model' | 'MapType';
  @Input() title: string;
  @Input() placeholder: string;
  @Output() valueChange = new EventEmitter();
  @Input() itemCtrl: FormControl;
  @Input()
  maxlength: string | number | null;
  @Input()
  formControl: any;
  filteredItems: Observable<any[]>;
  showAddButton: boolean = false;
  newItemIdentifier = 'new_item_id';
  size: any;
  items: InputSelection[] = [];
  @Input() disableOptions;

  constructor(
    private service: AutoCompleteService,
    private translocoService: TranslocoService
  ) {
    if (this.itemCtrl === undefined) {
      this.itemCtrl = new FormControl();
    }
  }

  getPrompt() {
    return this.translocoService.translate(`New+`);
  }
  async fetchSelections() {
    const selectionResult = await this.service.getSelections({
      dictType: this.modelName,
    });

    const selectionData = await selectionResult.result.map(
      (item: DataDictResponse) => ({
        value: item.dictValue,
        text: item.dictValue,
      })
    );

    this.items = orderBy(
      selectionData,
      [(item: InputSelection) => item.text.toLowerCase()],
      'asc'
    );

    this.filteredItems = this.itemCtrl.valueChanges.pipe(
      startWith(''),
      map((search: string) =>
        search ? this.filterItems(search) : this.items.slice()
      )
    );
  }

  async ngOnInit() {
    this.fetchSelections();
    this.textValue = this.value;

    if (this.formControl) {
      this.textValue = this.formControl.value;
      this.formControl.valueChanges.subscribe((val) => {
        this.textValue = val;
      });
    }
  }

  filterItems(search: string) {
    const results: InputSelection[] = orderBy(
      this.items.filter(
        (item: InputSelection) =>
          item.text.toLowerCase().indexOf(search.toLowerCase()) === 0
      ),
      [(item: InputSelection) => item.text.toLowerCase()],
      ['asc']
    );

    this.showAddButton = results.length === 0;

    if (search !== null && search.trim() !== '') {
      results.push({
        value: `${this.newItemIdentifier}${search}`,
        text: `<span class="rm-create-new-selection">${this.getPrompt()}</span><span>&nbsp;${search}</span>`,
      });
    }

    const reg = new RegExp(search, 'gi');

    return results.map((selection: InputSelection, index: number) => {
      const highlightedText = selection.text.replace(
        reg,
        (str: string) => '<span class="rm-bold-selection">' + str + '</span>'
      );

      return {
        ...selection,
        text:
          index !== results.length - 1
            ? `<span>${highlightedText}</span>`
            : selection.text,
      };
    });
  }

  async optionSelected(option) {
    if (option.value.indexOf(this.newItemIdentifier) === 0) {
      const cleanedValue = option.value.replace(this.newItemIdentifier, '');
      this.itemCtrl.setValue(cleanedValue);

      const result = await this.service.addRecord(this.modelName, cleanedValue);

      if (result) {
        this.fetchSelections();
        this.filterItems(cleanedValue);
        this.valueChange.emit(cleanedValue);
      } else {
        console.log('Something Went Wrong!');
        //error handling
      }
    } else {
      this.valueChange.emit(option.value);
    }
  }
}
