import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiBase } from './api-base.service';
import { LayoutMarker, ResponseOne } from './api.types';

@Injectable({
  providedIn: 'root',
})
export class ApiLayoutMarker extends ApiBase<LayoutMarker> {
  constructor(http: HttpClient) {
    super(http);
    this.resourceBaseUrl = 'layout-markers';
  }

  public create = (payload: Partial<LayoutMarker>) =>
    this.http.post<ResponseOne<string>>(
      `${this.base}/${this.resourceBaseUrl}`,
      payload,
      this.headers
    );

  public listLayoutMarkers = (layoutId: string) =>
    this.http.get<ResponseOne<LayoutMarker[]>>(
      `${this.base}/${this.resourceBaseUrl}/${layoutId}`
    );

  public listPathNodes = (layoutId: string) =>
    this.http.get<ResponseOne<LayoutMarker[]>>(
      `/api/v2/graph?layoutId=${layoutId}`
    );

  public update = (
    id: string,
    payload: Partial<LayoutMarker>
  ): Observable<void> => this.put(`${this.resourceBaseUrl}`, payload);

  /**
   * Another workaround because the server doesnt provide
   * a GET /id end point to get the marker's detail list.
   *
   * @param id
   * @param layoutId
   * @returns
   */
  public getLayoutMarker = (
    id: string,
    layoutId: string
  ): Observable<LayoutMarker> =>
    this.listLayoutMarkers(layoutId).pipe(
      map((res) => res.result.find((item) => item.id === id))
    );
}
