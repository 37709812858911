import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'rm-paginator',
  template: `
    <mat-paginator
      [pageIndex]="pageIndex"
      [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="onSelectedPage($event)"
      aria-label="Select page"
    >
    </mat-paginator>
  `,
  styles: [
    `
      :host ::ng-deep mat-paginator {
        width: 100%;
        background: transparent !important;
        margin: 1rem 0;

        .mat-paginator-container {
          justify-content: flex-end;
          -webkit-justify-content: flex-end;
          padding: 0px !important;
          color: #fff !important;

          .mat-form-field-flex {
            position: static;
            width: 79px;
            height: 42px;
            border-radius: 3px !important;
          }

          .mat-select .mat-select-trigger .mat-select-value {
            padding: 0px !important;
          }
        }

        .mat-paginator-page-size-label,
        .mat-paginator-range-label {
          font-size: 14px !important;
          margin-right: 0px !important;
        }

        .mat-paginator-page-size-label {
          padding-right: 15px !important;
        }

        .mat-paginator-page-size {
          align-items: center;
        }

        .mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper {
          margin-bottom: 3px;
        }

        .mat-paginator-container
          .mat-paginator-page-size
          .mat-paginator-page-size-select
          .mat-form-field-wrapper
          .mat-form-field-flex {
          background: rgba(0, 0, 0, 0.71) !important;
          border: 1px solid #505f79 !important;
          border-radius: 3px !important;

          .mat-select-arrow {
            border: none !important;
          }

          .mat-select-arrow::after {
            content: url('/assets/icons/Icon_after.svg');
            position: absolute;
            bottom: -14px;
            left: -10px;
          }

          mat-form-field-wrapper {
            margin-bottom: 0px;
          }
        }
      }
    `,
  ],
})
export class PaginatorComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() pageIndex: number = -1;
  @Input() pageEvent: number;
  @Input() length: number;
  @Input() pageSize: number;

  @Output() page = new EventEmitter<PageEvent>();

  public pageSizeOptions = [10, 20, 50, 100];

  constructor() {}

  public onSelectedPage = (event: PageEvent): void => {
    this.page.emit(event);
  };

  public firstPage = (): void => {
    this.paginator.firstPage();
  };
}
