/* eslint-disable max-len */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { SubNavi } from 'app/layout/layouts/vertical/thin/thin.service';
import { environment } from 'environments/environment';

/**
 * It is necessary to add a new menu item in the admin tools website and connect resources to it within the admin
 * tools. Please refer to the following link for more information:
 * https://www.notion.so/robotmanager/Users-and-Roles-4d957ca4772a42f7a5b66884de50c03d
 */
export const SETTING_NAVIGATION_ITEMS: SubNavi[] = [
  {
    id: 'overview',
    title: 'Overview',
    type: 'basic',
    link: '/settings/overview',
    classes: {
      title: '-ml-3',
    },
  },
  {
    id: 'robotParent',
    title: 'Robots',
    type: 'collapsable',
    children: [
      {
        id: 'robots',
        title: 'Robots',
        type: 'basic',
        img: 'robots.svg',
        link: '/settings/robots',
        displayName: 'MG_RBS',
        desc: 'ROBOTS_DESC',
      },
      {
        id: 'skills',
        title: 'Skills',
        type: 'basic',
        link: '/settings/skills',
        img: 'skills.svg',
        displayName: 'MG_SKIS',
        desc: 'SKILLS_DESC',
      },

      {
        id: 'robotMaps',
        title: 'COMMON.ROBOT_MAPS',
        type: 'basic',
        link: '/settings/robot_maps',
        img: 'robotmaps.svg',
        displayName: 'MG_ROBOT_MAPS',
        desc: 'ROBOT_MAPS_DESC',
      },

      {
        id: 'robotBatteryPolicy',
        title: 'COMMON.MG_ROBOT_BATTERY_POLICY',
        type: 'basic',
        link: '/settings/robot-battery-policy',
        img: 'robot-low-battery-policy.svg',
        displayName: 'MG_ROBOT_BATTERY_POLICY',
        desc: 'ROBOT_BATTERY_POLICY_DESC',
      },
    ],
  },
  {
    id: 'videoAnalyticsParent',
    title: 'Video Analytics',
    type: 'collapsable',
    children: [
      {
        id: 'vaProviders',
        title: 'COMMON.VA_PROVIDERS',
        type: 'basic',
        img: 'video-analytic-provider.svg',
        link: '/settings/va-providers',
        displayName: 'VA_PROVIDERS',
        desc: 'VA_PROVIDERS_DESC',
      },
      {
        id: 'vaZones',
        title: 'COMMON.VA_ZONES',
        type: 'basic',
        img: 'video-analytic-zone.svg',
        link: '/settings/va-zones',
        displayName: 'VA_ZONES',
        desc: 'VA_ZONES_DESC',
      },
    ],
  },
  {
    id: 'operationsParent',
    title: 'Operations',
    type: 'collapsable',
    children: [
      {
        id: 'layouts',
        title: 'Layouts',
        type: 'basic',
        img: 'layouts.svg',
        link: '/settings/layouts',
        displayName: 'MG_LAYOUTS',
        desc: 'LAYOUT_DESC',
      },
      {
        id: 'missions',
        title: 'Missions',
        type: 'basic',
        img: 'missions.svg',
        link: '/settings/missions',
        displayName: 'Missions',
        desc: 'MISSION_DESC',
      },
      {
        id: 'activityLogs',
        title: 'COMMON.ACTIVITY_LOG',
        type: 'basic',
        img: 'history.svg',
        link: '/settings/activity-log',
        displayName: 'ACTIVITY_LOG',
        desc: 'ACTIVITY_DESC',
      },

      // {
      //     id: 'assets',
      //     title: 'Assets',
      //     type: 'basic',
      //     img: 'assets.svg',
      //     link: '/settings/assets',
      //     displayName: 'Manage Assets',
      //     desc: 'Organize all your files and media',
      // },
    ],
  },
  {
    id: 'infrastructureParent',
    title: 'Infrastructure',
    type: 'collapsable',
    children: [
      {
        id: 'eventSources',
        title: 'COMMON.EVENT_SOURCES',
        type: 'basic',
        img: 'sensors.svg',
        link: '/settings/event-sources',
        displayName: 'event_sources',
        desc: 'EVENT_SOURCES_DESC',
      },
      {
        id: 'lifts',
        title: 'Lifts',
        type: 'basic',
        img: 'lifts.svg',
        link: '/settings/lifts',
        displayName: 'Lifts',
        desc: 'LIFT_DESC',
      },
      {
        id: 'doors',
        title: 'Doors',
        type: 'basic',
        img: 'door.svg',
        link: '/settings/doors',
        displayName: 'Doors',
        desc: 'DOOR_DESC',
      },
      {
        id: 'linkAreas',
        title: 'Link Areas',
        type: 'basic',
        img: 'link-fill.svg',
        link: '/settings/linkareas',
        displayName: 'LINK_AREAS',
        desc: 'LINK_AREA_DESC',
      },
    ],
  },
  {
    id: 'accountParent',
    title: 'Account',
    type: 'collapsable',
    children: [
      {
        id: 'users',
        title: 'Users',
        type: 'basic',
        img: 'users.svg',
        link: '/settings/users',
        displayName: 'MG_USERS',
        desc: 'USER_DESC',
      },
      // {
      //     id: 'workspaces',
      //     title: 'Workspaces',
      //     type: 'basic',
      //     img: 'workspaces.svg',
      //     link: '/settings/workspaces',
      //     displayName: 'Manage Workspaces',
      //     desc: 'Set a default workspace for your monitoring views. Build and configure widgets within each monitoring view',
      // },
      {
        id: 'company',
        title: 'Company',
        type: 'basic',
        img: 'avatar-team.svg',
        link: '/settings/company',
        displayName: 'MG_COMPANY',
        desc: 'COMPANY_DESC',
      },
      {
        id: 'accesskey',
        title: 'COMMON.ACCESS_KEY',
        type: 'basic',
        img: 'access-key.svg',
        link: '/settings/access-key',
        displayName: 'access_key',
        desc: 'ACCESS_KEY_DESC',
      },
    ],
  },
  {
    id: 'appsParent',
    title: 'Extensions',
    type: 'collapsable',
    children: [
      {
        id: 'apps',
        title: 'COMMON.APPS',
        type: 'basic',
        img: 'apps.svg',
        link: '/settings/apps',
        displayName: 'apps',
        desc: 'APPS_DESC',
      },
      {
        id: 'reportIssue',
        title: 'COMMON.REPORT_ISSUE',
        type: 'basic',
        img: 'report-issue.svg',
        link: environment.reportIssueUrl,
        displayName: 'REPORT_ISSUE',
        desc: 'REPORT_ISSUE_DESC',
      },
    ],
  },
];

export const MAIN_NAVIGATION_ITEMS: FuseNavigationItem[] = [
  {
    url: 'assets/images/navbar/apps.svg',
    id: 'opsApps',
    link: '/apps',
    title: 'App',
    type: 'basic',
  },
  {
    url: 'assets/images/navbar/monitoring.svg',
    id: 'opsMonitoring',
    link: '/monitoring',
    title: 'Monitoring',
    type: 'basic',
  },

  {
    url: 'assets/images/navbar/videos2.svg',
    id: 'opsVideos',
    link: '/videos',
    title: 'Videos',
    type: 'basic',
  },

  {
    url: 'assets/images/navbar/events.svg',
    id: 'opsEvents',
    link: '/events',
    title: 'Events',
    type: 'basic',
  },

  {
    url: 'assets/images/navbar/missions.svg',
    id: 'opsMissions',
    link: '/missions',
    title: 'Missions',
    type: 'basic',
  },

  {
    url: 'assets/images/navbar/robots.svg',
    id: 'opsRobots',
    link: '/robots',
    title: 'Robots',
    type: 'basic',
  },

  {
    url: 'assets/images/navbar/lifts.svg',
    id: 'opsLifts',
    link: '/lifts',
    title: 'Lifts',
    type: 'basic',
  },
  {
    url: 'assets/images/navbar/door.svg',
    id: 'opsDoors',
    link: '/doors',
    title: 'Doors',
    type: 'basic',
  },
];

export const DEFAULT_TRACKING_RADIUS = 15;
