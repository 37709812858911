import { Injectable } from '@angular/core';
import { SnackbarComponent } from '../shared/ui-components/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(public snackBar: MatSnackBar) {}
  openSnackBar(data: {
    message: string;
    type?: 'success' | 'failed' | 'info';
    panelClass?: string | string[];
    preClose?: () => void;
    duration?: number;
  }): void {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: data.message,
        type: data.type,
        panelClass: data.panelClass,
        preClose: () => {
          this.snackBar.dismiss();
        },
      },
      duration: data.duration ?? 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
  closeSnackBar(): void {
    this.snackBar.dismiss();
  }
}
