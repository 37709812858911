import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'app/core/user/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map((response) => {
        // Execute the observable
        this._user.next(response);
      })
    );
  }

  getLoginInfo() {
    return this._httpClient.get('/api/v2/auth/loginInfo');
  }
  getFile(name: string): Observable<Blob> {
    // const url = name + '_origin';
    return this._httpClient.get('/api/v2/asset/get-file/' + name, {
      responseType: 'blob',
    });
  }

  getVersion() {
    return this._httpClient.get('/assets/config/version.json', {
      responseType: 'json',
    });
  }
}

export interface RESPONSE {
  code: number;
  result: any;
  message: string;
  error: string;
}
