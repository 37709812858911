import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiBase } from './api-base.service';
import {
  ListQuery,
  Resource,
  ResponseList,
  ResponseOne,
  Role,
  RoleResource,
  RoleUser,
  User,
  UserChangePassword,
  UserCompany,
} from './api.types';

interface InviteUser {
  email: string;
  roles: string[];
}

export interface AcUser extends User {
  name?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiUser extends ApiBase<User> {
  isDirty = false;

  constructor(http: HttpClient) {
    super(http);

    this.resourceBaseUrl = 'user';
  }

  /**
   * Get list of users
   *
   * @param query
   * @returns
   */

  public getLoginInfo(): Observable<ResponseOne<User>> {
    return this.http.get<ResponseOne<User>>('/api/v2/auth/loginInfo');
  }

  public getUserDetails(userId: string): Observable<ResponseOne<User>> {
    return this.http.get<ResponseOne<User>>(
      '/api/v2/user/' + userId,
      this.headers
    );
  }

  public getUserDetailsActivityLog(userId: string): Observable<AcUser> {
    return this.getById(userId, true).pipe(map((response) => response.result));
  }

  public updateUser(user: Partial<User>): Observable<ResponseOne<string>> {
    return this.http.put<ResponseOne<string>>('/api/v2/user/update', user);
  }
  public deleteUserById = (ids: string): Observable<ResponseList<null>> =>
    this.delete(`user/${ids}`);

  public getAllResourcesByType(
    type: number = 0
  ): Observable<ResponseOne<Resource[]>> {
    return this.http.get<ResponseOne<Resource[]>>(
      `/api/resource/GetByType/${type}`
    );
  }

  public updateUserRoles(
    postData: Partial<RoleUser>[]
  ): Observable<ResponseOne<string>> {
    return this.http.post<ResponseOne<string>>(
      '/api/roleuser/saveAll',
      postData
    );
  }

  public updateUserCompanyStatus(
    userId: string,
    status: number
  ): Observable<ResponseOne<string>> {
    return this.http.put<ResponseOne<string>>(`/api/v2/usercompany`, {
      userId,
      status,
    });
  }

  public getUserCompanyList(
    query: ListQuery
  ): Observable<ResponseOne<UserCompany[]>> {
    return this.http.post<ResponseOne<UserCompany[]>>(
      '/api/v2/usercompany/list',
      query
    );
  }

  public getRoleList(payload: ListQuery): Observable<ResponseList<Role>> {
    return this.http.post<ResponseList<Role>>('/api/role/list', payload);
  }

  public getRoleByCompany(companyId: string): Observable<ResponseOne<Role[]>> {
    return this.http.get<ResponseOne<Role[]>>(
      '/api/role/FindRolesByCompany/' + companyId
    );
  }

  public getRoleByUserCompany(
    companyId: string,
    userId: string
  ): Observable<ResponseOne<Role[]>> {
    return this.http.post<ResponseOne<Role[]>>(
      '/api/roleuser/findRoleByUserCompany',
      {
        companyId,
        userId,
      }
    );
  }

  public getRoleResourceCompany(
    companyId: string
  ): Observable<ResponseOne<RoleResource[]>> {
    return this.http.get<ResponseOne<RoleResource[]>>(
      '/api/roleresource/FindByCompany/' + companyId
    );
  }

  public inviteUser(data: InviteUser): Observable<ResponseOne<null>> {
    ///the BE API return null in the result field
    return this.http.post<ResponseOne<null>>('/api/v2/user/invite', data);
  }

  public transferOwnership(
    owner: string,
    password: string
  ): Observable<ResponseOne<null>> {
    const data = {
      owner,
      password,
    };
    ///the BE API return null in the result field
    return this.http.post<ResponseOne<null>>(
      '/api/v2/user/transferOwnership',
      data
    );
  }
  /** Get asset info */
  public getAssetInfo(id: string) {
    return this.http.get(`/api/v2/asset/get-info/${id}`, this.headers);
  }

  /* update password  */

  public changePassword(
    payload: UserChangePassword
  ): Observable<ResponseOne<null>> {
    return this.http.post<ResponseOne<null>>(
      '/api/v2/change-password',
      payload
    );
  }
}
