import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[rmButtonType]',
})
export class ButtonTypeDirective implements OnInit {
  @Input() rmButtonType:
    | ''
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'blue'
    | 'clear'
    | 'link'
    | 'danger' = 'primary';
  @Input() rmButtonSize: 'small' | 'default' | 'large' = 'default';

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    let backgroundColor = 'white';
    let color = '#083a45';
    const borderRadius = '3px';

    let height = '40px';

    switch (this.rmButtonType) {
      case 'disabled':
        backgroundColor = '#999';
        break;
      case '':
      case 'primary':
        backgroundColor = 'white';
        break;
      case 'secondary':
        backgroundColor = '#6554C0';
        color = 'white';
        break;
      case 'blue':
        backgroundColor = '#04b4cd';
        color = 'white';
        break;
      case 'clear':
        backgroundColor = 'transparent';
        color = 'white';
        break;
      case 'danger':
        backgroundColor = '#ff5630';
        color = 'white';
        break;
      case 'link':
        backgroundColor = 'transparent';
        color = '#04B4CD';
        break;

      default:
        break;
    }

    switch (this.rmButtonSize) {
      case 'small':
        height = '30px';
        break;
      case 'large':
        height = '50px';
        break;
    }

    this.el.nativeElement.style.backgroundColor = backgroundColor;
    this.el.nativeElement.style.color = color;
    this.el.nativeElement.style.borderRadius = borderRadius;
    this.el.nativeElement.style.height = height;
    this.el.nativeElement.style.fontWeight = 'normal';
  }
}
