import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { BreadcrumbItem } from '../breadcrumb/breadcrumb.component';
import { Layout } from 'app/services/api.types';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  breadcrumb$ = new ReplaySubject<
    (string | BreadcrumbItem | BreadcrumbItem[])[]
  >();
  viewType$ = new Subject<string>();
  viewType = '';
  viewTypeLayout$ = new ReplaySubject<string>();
  dropdownValues$ = new ReplaySubject();
  currentPath$ = new ReplaySubject<any>();
  has3DMap$ = new Subject<boolean>();
  toggle2DMap$ = new Subject<boolean>();

  constructor() {}

  public toggle3DTab(layout: Layout): void {
    if (layout.threedImageUrl && layout.threedImageUrl !== '') {
      this.has3DMap$.next(true);
    } else {
      this.has3DMap$.next(false);
    }
  }
}
