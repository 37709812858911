import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { AppSettings } from 'app/app.settings';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { ApiNotification } from 'app/services/api-notification.service';
import { Notification } from 'app/services/api.types';
import { IPageInfo } from 'ngx-virtual-scroller/virtual-scroller';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'notifications',
  styleUrls: ['notification.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public notifications: Notification[] = [];

  @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel')
  private _notificationsPanel: TemplateRef<any>;

  flagNormal = '/assets/icons/flag_normal.svg';
  flagCritical = '/assets/icons/flag_critical.svg';
  panelType: string;
  unreadCount: number = 0;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  pageSize = 10;
  pageNo = 1;
  totalRecords = 0;
  timer;
  loading: boolean;
  onlyShowUnread = false;
  disabled = false;
  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _notificationsService: NotificationsService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _router: Router,
    private _appSettings: AppSettings,
    private api: ApiNotification
  ) {
    this._appSettings.socketNotificationData$.subscribe(() => {
      this._getUnreadCount();
    });
  }

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to notification changes
    this._notificationsService.notifications$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Load the notifications
        this._getUnreadCount();
      });

    this._getUnreadCount();
  }

  public handleReadUnread(event: MatSlideToggleChange): void {
    this.loading = true;
    this.onlyShowUnread = event.checked;
    this.resetData();
    this.getNotifications();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the notifications panel
   */
  openPanel(): void {
    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    this.loading = true;
    this.resetData();
    this.getNotifications();

    // Attach the portal to the overlay
    this._overlayRef.attach(
      new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
    );
  }

  /**
   * Close the messages panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  public goToNotificationList(): void {
    this.closePanel();
    this._router.navigate(['/notifications']);
  }

  resetData(): void {
    this.pageNo = 1;
    this.notifications = [];
    this.totalRecords = 0;
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(): void {
    this.api.markAllAs('read').subscribe((result) => {
      if (result.code === 200) {
        this.resetData();
        this.getNotifications();
        this._notificationsService.updateCounter();
      }
    });
  }

  /**
   * Delete the given notification
   */
  // delete(notification: INotification): void {
  //   // Delete the notification
  //   this._notificationsService.delete(notification.id).subscribe();
  // }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the overlay
   */
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(
          this._notificationsOrigin._elementRef.nativeElement
        )
        .withLockedPosition()
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  /**
   * Calculate the unread count
   *
   * @private
   */
  private _getUnreadCount(): void {
    this._notificationsService.getCounter().subscribe((data) => {
      if (data && data['result']) {
        this.unreadCount = data['result'].notif.unread;
        this._changeDetectorRef.markForCheck();
      }
    });
  }

  goToDetails(notification: Notification): void {
    this.api.markAs(notification.id as string, 'read').subscribe((response) => {
      if (response.code === 200) {
        this._notificationsService.updateCounter();
        this.closePanel();
        this._router.navigate([`events/details/${notification.url}`]);
      }
    });
  }

  fetchMore(event: IPageInfo): void {
    if (event.endIndex !== this.notifications.length - 1) {
      return;
    }

    if (this.notifications.length < this.totalRecords) {
      this.pageNo += 1;
      this.getNotifications();
    }
  }

  getNotifications(): void {
    const today = new Date();
    const tomorrow = new Date(new Date().setDate(today.getDate() + 1));
    const priorDate = new Date(new Date().setDate(today.getDate() - 30));
    const filter = [
      {
        name: 'created_at',
        column: 'notifications.created_at',
        operator: 'bt',
        value: [this.toSqlDate(priorDate), this.toSqlDate(tomorrow)],
        extra: '',
        dataType: '',
        virtual: false,
      },
    ];

    if (this.onlyShowUnread) {
      filter.push({
        name: 'status',
        column: 'notifications.read',
        operator: 'eq',
        value: ['false'],
        extra: '',
        dataType: '',
        virtual: false,
      });
    }

    const query = {
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      filter: [...filter],
      order: [
        {
          name: 'created_at',
          column: 'created_at',
          type: 'desc' as const,
        },
      ],
    };

    this.api.list(query).subscribe((response) => {
      switch (response.code) {
        case 200:
          this.loading = false;
          if (response.result.list && Array.isArray(response.result.list)) {
            if (this.pageNo === 1) {
              this.notifications = [...response.result.list];
            } else {
              const preArr = cloneDeep(this.notifications);
              this.notifications = [...preArr, ...response.result.list];
            }
            this.totalRecords = response.result.totalRecords;
          } else {
            this.resetData();
          }
      }
    });
  }

  /**
   * https://stackoverflow.com/a/15021796/1043839
   *
   * @param date
   * @returns
   */
  private toSqlDate(date: Date): string {
    return date.toJSON().slice(0, 10);
  }
}
