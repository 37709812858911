import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThumbnailCardComponent } from './thumbnail-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { TextSofiaModule } from '../text-sofia/text-sofia.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoRootModule } from 'app/transloco-root.module';
@NgModule({
  declarations: [ThumbnailCardComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatGridListModule,
    MatCheckboxModule,
    MatIconModule,
    MatChipsModule,
    TextSofiaModule,
    MatProgressSpinnerModule,
    TranslocoRootModule,
  ],
  exports: [ThumbnailCardComponent],
})
export class ThumbnailCardModule {}
