import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from './company.model';
import { ResponseOne } from 'app/services/api.types';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private _company: ReplaySubject<Company> = new ReplaySubject<Company>(1);

  /**
   * Constructor
   */
  constructor(private httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for company
   *
   * @param value
   */
  set company(value: Company) {
    // Store the value
    this._company.next(value);
  }

  get company$(): Observable<Company> {
    return this._company.asObservable();
  }

  getCompanies(): Observable<ResponseOne<Company[]>> {
    return this.httpClient.get<ResponseOne<Company[]>>('/api/v2/get-companies');
  }
  switchCompany(companyId: string): Observable<AccessToken> {
    return this.httpClient.post<AccessToken>('' + 'api/v2/swithCompany', {
      companyId,
    });
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the company
   *
   * @param company
   */
  update(company: Company): Observable<any> {
    return;
    // this.httpClient.patch<Company>('api/common/company', {company}).pipe(
    //     map((response) => {
    //         // Execute the observable
    //         this._company.next(response);
    //     })
    // );
  }
}
export interface AccessToken {
  token: string;
  error: string;
  expireAt: string;
  expireMinutes: number;
}
