import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogHistory } from 'app/shared/interface/logHistory';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiBase } from './api-base.service';
import {
  Camera,
  Filter,
  ListQuery,
  ResponseList,
  ResponseOne,
  ResponseWithoutList,
  Robot,
  Skill,
} from './api.types';
@Injectable({
  providedIn: 'root',
})
export class ApiRobot extends ApiBase<Robot> {
  constructor(http: HttpClient) {
    super(http);
    this.resourceBaseUrl = 'robot';
  }

  /**
   * Robot List Support Skill Filter
   * https://docs.robotmanager.com/reference/robot-list-support-skill-filter
   *
   * @param data
   * @returns
   */
  public getRobotListWithSkill = (query: ListQuery) =>
    this.post('robot/list-with-skill', query);

  /**
   * Robot List Support Layout Filter
   * https://docs.robotmanager.com/reference/robot-list-support-layout-filter
   *
   * @param data
   * @returns
   */
  public getRobotListByLayout = (query: ListQuery) =>
    this.post('robot/list-with-layout', query);

  public getRobotSkills(id: string): Observable<ResponseWithoutList<Skill>> {
    return this.http.get<ResponseWithoutList<Skill>>(
      `/api/v2/robot-skills/get-by-robot/${id}`
    );
  }

  public listWithCamera(query: ListQuery): Observable<ResponseList<Robot>> {
    return this.list(query).pipe(
      tap((result) => {
        // keep the current response to class property, so it can be called everywhere
        this.dataList = result;
      }),
      map((response) => {
        response.result.list.map((data) => {
          this.http
            .get<ResponseWithoutList<Camera>>(`api/v2/camera/list/${data.id}`)
            .subscribe({
              next: (resp) => {
                data.cameras = resp.result;
                data.cameraCount = resp.result.length;
              },
              error: (error: any) => console.log(`Can't get data:`, error),
            });

          return response;
        });

        // create new ResponseList object from modified data
        const result = {
          ...this.dataList,
        };
        result.result.list = response.result.list;

        return result;
      })
    );
  }

  switchControl(psd) {
    return this.post(`teleop/switch-control`, psd);
  }
  /**
   * List Robots
   *
   * @param query
   * @returns
   */
  public list = (query: ListQuery) => this.post('robot/list', query);

  public deleteRobotById = (id: string) => this.delete(`robot/${id}`);

  /**
   * get robot details by Id
   * @param robotId robotId
   * @returns
   */
  getRobotDetails(robotId: string): Observable<ResponseOne<Robot>> {
    return this.http.get<ResponseOne<Robot>>(`/api/v2/robot/${robotId}`).pipe(
      map((resp) => {
        resp.result.battery = Math.ceil(resp.result.battery as number);
        return resp;
      })
    );
  }

  /**
   * get robot log history
   * @param robotId - robot Id
   * @param page - page number
   * @param size - data size
   * @returns Observable
   */
  getRobotLogHistoryList(
    id: string,
    page: number,
    size: number,
    filters: Filter[] = []
  ): Observable<ResponseList<LogHistory>> {
    return this.http.post<ResponseList<LogHistory>>(
      '/api/v2/robotlog/list',
      {
        pageNo: page,
        pageSize: size,
        filter: [
          ...filters,
          {
            name: 'robotId',
            column: 'robot_id',
            operator: 'eq',
            value: [id],
            extra: '',
            dataType: 'text',
            virtual: false,
          },
        ],
        order: [
          {
            name: 'timestamp',
            column: 'timestamp',
            type: 'desc',
          },
        ],
      },
      this.headers
    );
  }

  // Recursive method to fetch all data with a query payload
  public getAllRobotListByLayout(queryPayload: ListQuery): Promise<Robot[]> {
    const { pageNo, pageSize = 50, ...restQueryParams } = queryPayload;

    // check if pageNo is valid
    if (typeof pageNo !== 'number' || pageNo < 1) {
      return Promise.reject(new Error('Invalid pageNo'));
    }

    return this.getRobotListByLayout({
      pageNo,
      pageSize,
      ...restQueryParams, // Merge any additional query parameters
    })
      .toPromise()
      .then((res: ResponseList<Robot>) => {
        const data = res.result.list;
        const totalRecords = res.result.totalRecords;

        if (data.length === 0 || pageNo * pageSize >= totalRecords) {
          // All data has been fetched or reached the end
          return data;
        } else {
          // Fetch the next page of data
          return this.getAllRobotListByLayout({
            ...queryPayload,
            pageNo: pageNo + 1,
          })
            .then((dataRes) =>
              // Concatenate the current and next page of data
              data.concat(dataRes)
            )
            .catch((err) => Promise.reject(err));
        }
      })
      .catch((err) => Promise.reject(err));
  }
}
