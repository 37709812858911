export const mqttTopic = {
  robotStatus: '/robot/status/',
  mission: '/robot/mission/',
  missionStatus: '/robot/mission/status/',
  videoConference: '/robot/vc/request/',
  event: '/robot/event/',
  robotState: '/robot/state/',
  controlStatus: '/robot/control/status/',
  notificationStatus: '/robot/notify/',
  answerCall: '/robot/call/',
  rmMissions: '/rm/mission/', // to get the mission update data from other users
  liftCommandStatus: '/lift/command/status/',
  liftCommand: '/rm/lift/command/',
  liftStatus: '/lift/status/',
  pathUpdate: '/robot/paths/',
  robotLogs: '/robot/log/',
  robotSensor: '/robot/sensor-actuator/status/',
  rmJobData: '/rm/job/',
  rmEvent: '/rm/event/',
  doorStatus: '/door/status/',
  rmVaZoneItemStatus: '/rm/va-zone-item/status/',
};

export class Settings {
  constructor(public loadingSpinner: boolean) {}
}

export const publishTopics = {
  mission: '/rm/mission/',
  control: '/rm/control/',
  autonomous: '/rm/mode/',
  move: '/rm/move/',
  cameraControl: '/rm/camera/control/',
  teleconference: '/rm/vc/request/',
  callRobot: '/rm/call/',
  liftCommand: '/rm/lift/command/',
  liftStatus: '/lift/status/',
};
