import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { AppModule } from 'app/app.module';
import { ApiRobot } from 'app/services/api-robot.service';
import { Filter, ListQuery, Order } from 'app/services/api.types';
import { QueryParamService } from 'app/services/query-param.service';
import { get } from 'lodash';
import { Subject } from 'rxjs';
import { FilterComponent } from './ui-components/filter/filter.component';
import { PaginatorComponent } from './ui-components/paginator';
import { takeUntil } from 'rxjs/operators';

@Component({
  template: `<div></div>`,
  providers: [{ provide: `CrudInterface`, useClass: ApiRobot }],
})
export class BaseListComponent<T> implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  @ViewChild(FilterComponent) filterComponent: FilterComponent;
  @ViewChild(MatSort) sort: MatSort;

  public filters: Filter[] = [];
  public orders: Order[] = [];
  public bulkActions = [];
  public pageNo: number = 1;
  public pageSize: number = 10;
  public totalData: number = 0;
  public activeSort: string = 'created_at';
  public sortDirection: SortDirection = 'desc';
  public dataSource: T[];
  public dataSourceSubject = new Subject<T[]>();
  public selection?: SelectionModel<T>;
  // concatenatedIds = concat of selected Ids separated by comma, e.g. "1234,444,5555"
  public concatenatedIds = '';
  public columnsToDisplay: string[];
  public queryParam: QueryParamService;
  public isLoading: boolean = false;
  private route: ActivatedRoute;
  private initFilter: Filter[];
  private unsubscribeBaseList: Subject<any> = new Subject<any>();

  constructor(@Inject(`CrudInterface`) private crud) {
    this.route = AppModule.injector.get(ActivatedRoute);
    this.queryParam = AppModule.injector.get(QueryParamService);
    this.selection = new SelectionModel<T>(true, []);

    this.queryParam.length
      .pipe(takeUntil(this.unsubscribeBaseList))
      .subscribe((value) => (this.totalData = value));

    this.queryParam.pageIndex
      .pipe(takeUntil(this.unsubscribeBaseList))
      .subscribe((value) => (this.pageNo = value));

    this.queryParam.pageSize
      .pipe(takeUntil(this.unsubscribeBaseList))
      .subscribe((value) => (this.pageSize = value));

    this.queryParam.filterData
      .pipe(takeUntil(this.unsubscribeBaseList))
      .subscribe((value) => (this.filters = value));

    this.queryParam.order
      .pipe(takeUntil(this.unsubscribeBaseList))
      .subscribe((value) => (this.orders = value));

    this.route.queryParams
      .pipe(takeUntil(this.unsubscribeBaseList))
      .subscribe((params) => {
        if (params.total || params.filter || params.order) {
          this.queryParam.applyExistedParams();
          this.activeSort = this.orders[0]?.column;
          this.sortDirection = this.orders[0]?.type;

          if (params.filter && JSON.parse(params.filter).length > 0) {
            this.fillPredefinedFilters(JSON.parse(params.filter));
          }
        } else {
          this.queryParam.resetParams();

          setTimeout(() => {
            // reset the Paginator to page 1
            if (this.paginator) {
              this.paginator.firstPage();
            }
            if (this.filterComponent) {
              // clear filter and close the filter panel
              this.filterComponent.handleClearFilter(false);
              this.filterComponent.isExpanded = false;
              this.filterComponent.hasActiveFilter = false;
            }
            // set default sort
            this.activeSort = this.queryParam.defaultOrder[0]?.column;
            this.sortDirection = this.queryParam.defaultOrder[0]?.type;

            this.refreshDataSource(true);
          }, 500);
        }
      });
  }

  ngOnInit(): void {
    if (this.filters.length > 0) {
      this.refreshDataSource(true);
    }

    this.selection.changed
      .pipe(takeUntil(this.unsubscribeBaseList))
      .subscribe((event) => {
        this.concatenatedIds = this.selection.selected
          .reduce((acc: string[], item) => {
            acc.push(get(item, 'id'));
            return acc;
          }, [])
          .join(',');
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeBaseList.next();
    this.unsubscribeBaseList.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.paginator) {
        this.paginator.pageIndex = this.pageNo - 1;
      }
    });
  }

  public refreshDataSource = (initialLoad: boolean = false): void => {
    this.initFilter = [];
    if (initialLoad) {
      this.initFilter = this.filters;
    }

    if (this.initFilter.length > 0) {
      //to assign the result of the value of initFilter and filter
      //sameFilter will have true if the initFilter and filter have the same value
      const sameFilter: boolean =
        JSON.stringify(this.initFilter) === JSON.stringify(this.filters);

      // to check if the initFilter and filter have same value or not
      //if the value is different then concant the initFilter to filters
      if (!sameFilter) {
        // If initFilter is not empty set the initial filter to the filters
        this.filters = this.filters.concat(this.initFilter);
      }
    }

    const payload: ListQuery = {
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      filter: this.filters,
      order: this.orders,
    };

    this.crud
      .list(payload)
      .pipe(takeUntil(this.unsubscribeBaseList))
      .subscribe((res) => {
        this.dataSource = res.result.list;
        this.dataSourceSubject.next(this.dataSource);
        this.totalData = res.result.totalRecords;
        if (!initialLoad) {
          this.appendQueryParamsToUrl();
        }
      });
  };

  public isAllSelected = (): boolean => {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  };

  public masterToggle = (): void => {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.forEach((row) => this.selection.select(row));
    }
  };

  public onChangedPagination = (page: number, size: number) => {
    const nextPage = page + 1;
    if (this.pageNo === nextPage && this.pageSize === size) {
      return;
    }
    this.pageNo = nextPage;
    this.pageSize = size;

    this.refreshDataSource();
    this.selection.clear();
    this.appendQueryParamsToUrl();
  };

  public onChangedFilters = (filters: Filter[]) => {
    this.filters = filters;

    // reset page to 1 if filters are changed and page is more than 1
    this.resetPaginator();

    this.refreshDataSource();
    this.selection.clear();
    this.appendQueryParamsToUrl();
  };

  public onChangedOrder = (order: Sort) => {
    this.orders = [
      {
        column: this.queryParam.camelToSnakeCase(order.active),
        type: order.direction,
      },
    ];

    // reset page to 1 if order are changed and page is more than 1
    this.resetPaginator();

    this.refreshDataSource();
    this.selection.clear();
    this.appendQueryParamsToUrl();
  };

  public onBulkActionSelected = (event: string): void => {
    console.log('event :>> ', event);
  };

  public get listPayload(): ListQuery {
    return {
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      filter: this.filters,
      order: this.orders,
    };
  }

  private appendQueryParamsToUrl = () => {
    this.queryParam.appendParamsToUrl(
      {
        length: this.totalData,
        pageIndex: this.pageNo,
        pageSize: this.pageSize,
      },
      this.filters,
      this.orders
    );
  };

  private fillPredefinedFilters = (filter: Filter[]) => {
    setTimeout(() => {
      let num = 0; // initiate num to 0
      filter.map((item) => {
        const formatName = item.name.split(' ').join('-').toLowerCase();
        const filterId = `filter-by-${item.column}-${formatName}`;
        document.getElementById(filterId) && item.value.length > 0
          ? (document
              .getElementById(filterId)
              .querySelectorAll('input')[0].value = item.value[0])
          : null;
        // check if the filter component has some value,
        // if it has value, the number is increased
        if (document.getElementById(filterId) && item.value.length > 0) {
          num++;
        }
      });

      // this.filterComponent.isExpanded = true;
      if (this.filterComponent) {
        this.filterComponent.hasActiveFilter = true;
        this.filterComponent.isExpanded = num > 0; // expanded is based on the value exist on the filter field componen
      }
    }, 500);
  };

  private resetPaginator(): void {
    if (this.pageNo > 1) {
      this.pageNo = 1;
      this.paginator.firstPage();
    }
  }
}
