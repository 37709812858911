import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBase } from './api-base.service';
import { Menu, ResponseOne } from './api.types';
import { CacheBucket, HttpCacheManager, withCache } from '@ngneat/cashew';

@Injectable({
  providedIn: 'root',
})
export class ApiMenu extends ApiBase<Menu> {
  private menusBucket = new CacheBucket();

  constructor(http: HttpClient, private manager: HttpCacheManager) {
    super(http);
    this.resourceBaseUrl = 'menu';
  }

  public invalidateMenus(): void {
    this.manager.delete(this.menusBucket);
  }

  public getMyMenus = (): Observable<ResponseOne<Menu[]>> =>
    this.http.get<ResponseOne<Menu[]>>(
      `${this.base}/${this.resourceBaseUrl}/mine`,
      {
        headers: this.headers,
        context: withCache({
          bucket: this.menusBucket,
        }),
      }
    );

  public getByUser = (userId: string): Observable<ResponseOne<Menu[]>> =>
    this.http.get<ResponseOne<Menu[]>>(
      `${this.base}/${this.resourceBaseUrl}/user/${userId}`,
      this.headers
    );
}
