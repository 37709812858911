import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { DialogCommonDialogComponent } from './common-dialog.component';

@NgModule({
  declarations: [DialogCommonDialogComponent],
  imports: [MatDialogModule, CommonModule, MatButtonModule],
  exports: [DialogCommonDialogComponent],
})
export class CommonDialogModule {}
