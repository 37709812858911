import { CdkTextColumn } from '@angular/cdk/table';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'rm-status-column',
  template: `
    <ng-container matColumnDef>
      <th mat-header-cell *matHeaderCellDef>{{ headerText | transloco }}</th>
      <td mat-cell *matCellDef="let data">
        <rm-status
          statusCode="{{ data.status }}"
          module="{{ module }}"
        ></rm-status>
      </td>
    </ng-container>
  `,
})
export class DatatableStatusColumn<T> extends CdkTextColumn<T> {
  @Input() module: string;
}
