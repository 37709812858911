import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RobotMapsDropdownInputComponent } from './robotmaps-dropdown-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { TextSofiaModule } from '../text-sofia/text-sofia.module';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [RobotMapsDropdownInputComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    TextSofiaModule,
    MatSelectModule,
    TranslocoModule,
  ],
  exports: [RobotMapsDropdownInputComponent],
})
export class RobotMapsDropdownInputModule {}
