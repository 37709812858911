export const LANGUAGES = [
  {
    name: 'English',
    type: 'en',
  },
  {
    name: '中 文',
    type: 'zh',
  },
];

export interface Language {
  name: string;
  type: string;
}
