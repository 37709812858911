import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'apps-avatar',
  template: ` <avatar [srcUrl]="src"></avatar> `,
})
export class AppsAvatarComponent implements OnInit {
  @Input() src?: string;
  constructor() {}

  ngOnInit(): void {
    this.src = this.src === '' ? 'assets/images/settings/apps.svg' : this.src;
  }
}
