import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionButtonComponent } from './action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
interface ButtonProps {
  label: string;
  type: string;
  onClick: () => void;
}
@NgModule({
  declarations: [ActionButtonComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule],
  exports: [ActionButtonComponent],
})
export class ActionButtonModule {}
