import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resource, ResponseWithoutList } from 'app/services/api.types';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { SignInService } from '../../../../modules/auth/sign-in/sign-in.service';
import { FuseNavigationItem } from '@fuse/components/navigation';
export interface SubNavi extends FuseNavigationItem {
  img?: string;
  link?: string;
  displayName?: string;
  desc?: string;
  children?: SubNavi[];
  classes?: {
    title?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ThinService {
  pageName$ = new ReplaySubject<string>();
  options;

  constructor(private http: HttpClient, public _signInService: SignInService) {}

  handleError(error: any) {
    const status = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    if (error.status == 401) {
      window.location.href = '/login';
    } else {
      const message =
        error.status != 500 ? (error._body ? JSON.parse(error._body) : '') : '';
      const errMsg = {
        message: message,
        status: status,
      };
      return throwError(errMsg);
    }
  }
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  private extractDataJSON(res: Response) {
    const body = res.json();
    return body || {};
  }

  private extractDataText(res: Response) {
    const body = res.text();
    return body || {};
  }
  httpHeader() {
    const tempToken =
      this._signInService.accessToken ??
      JSON.parse(this._signInService.accessToken);
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + tempToken.token,
      'Content-Type': 'application/json',
    });
    let options;
    return (options = {
      headers: headers,
    });
  }
  public getAccessRight(
    typeNumber: number
  ): Observable<ResponseWithoutList<Resource>> {
    return this.http.get<ResponseWithoutList<Resource>>(
      `/api/resource/GetByType/${typeNumber}`,
      this.httpHeader()
    );
  }
  public getLoginResources(): Observable<ResponseWithoutList<Resource>> {
    return this.http.post<ResponseWithoutList<Resource>>(
      '/api/v2/login-resources',
      this.httpHeader()
    );
  }
}
