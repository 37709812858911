import { SnackbarService } from './../../../services/snackbarService';
import { ApiUser } from './../../../services/api-user.service';
import { ResponseOne, UserCompany } from './../../../services/api.types';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Company } from 'app/core/company/company.model';
import { CompanyService } from 'app/core/company/company.service';
import { AuthService } from 'app/core/auth/auth.service';
import { translate } from '@ngneat/transloco';
@Component({
  selector: 'company-menu',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'companyMenu',
})
export class CompanyComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = true;
  companyList: Company[];
  isExpanded: boolean = false;

  companyId = localStorage.getItem('companyId');
  listUsersCompany: UserCompany[];
  userLoginId: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  currentCompany: Company;
  /**
   * Constructor
   */
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private companySrvc: CompanyService,
    private snackbarService: SnackbarService,
    private apiUser: ApiUser
  ) {
    this.userLoginId = this.authService.userId;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.getCurrentCompany();
    this.getCompanies();
  }

  private getCurrentCompany(): void {
    this.companySrvc.company$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((selectCompany: Company) => {
        this.currentCompany = selectCompany;
        // Mark for check
        this.changeDetectorRef.markForCheck();
      });
  }

  private getCompanies(): void {
    const currentCompanyId = localStorage.getItem('cucid');
    this.companySrvc
      .getCompanies()
      .subscribe((data: ResponseOne<Company[]>) => {
        if (data['code'] === 200) {
          this.companyList = data['result'];
          this.currentCompany = this.companyList.find(
            (company) => company.id === currentCompanyId
          );
        } else {
          this.companyList = [];
          this.currentCompany = undefined;
        }
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      });
  }

  updateCompany(company: { id: string; name: string }): void {
    const currentCompanyId = localStorage.getItem('cucid');
    if (company.id === currentCompanyId) {
      return;
    }
    localStorage.setItem('cucid', company.id);
    localStorage.setItem('companyId', company.id);
    localStorage.setItem('lst_page', '/monitoring/gis');
    this.companySrvc.switchCompany(company.id).subscribe((data) => {
      if (data['token']) {
        this.currentCompany = {
          id: company.id,
          name: company.name,
        };
        // set accesstoken
        this.authService.accessToken = data['token'];
        localStorage.setItem('accessToken', this.authService.accessToken);
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
        location.href = '/apps';
      } else if (data.error) {
        this.snackbarService.openSnackBar({
          message: data.error,
          type: 'failed',
        });
      } else {
        this.authService.signOut();
      }
    });
    this.isExpanded = !this.isExpanded;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
