import { CdkNoDataRow } from '@angular/cdk/table';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'rm-empty-table',
  template: `
    <div
      class="w-full flex flex-col min-h-128 bg-cyan-medium"
      *ngIf="!dataCount"
    >
      <div class="m-auto">
        <img
          src="/assets/images/shared/folder.svg"
          alt="no-data"
          class="w-16 m-auto p-4"
        />
        {{ message }}
      </div>
    </div>
  `,
})
export class EmptyTableDataComponent {
  @Input() message: string;
  @Input() dataCount: number;
}
