import { Injectable } from '@angular/core';
// import { Router } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
// import { AuthService } from './core/auth/auth.service';
import { Settings } from './app.model';
import { Layout } from './layout/layout.types';

// TODO update type by real format
export interface MenuItem {
  name: string;
}

export interface AccessRight {
  name: string;
}

export interface SocketData {
  id: string;
  robotId: string;
  companyId: string;
  timestamp: number;
  battery: number;
  connectivity: number;
  batteryTimeRemaining: number;
  layout: {
    id: string;
    x: number;
    y: number;
    z: number;
  };
  heading: number;
  location: {
    lat: number;
    lng: number;
    alt: number;
  };
  roll: number;
  pitch: number;
  yaw: number;
}

export interface userData {
  username: string;
  password: string;
  rememberMe: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AppSettings {
  // constructor(
  //     private _authSrvc: AuthService,
  //     private _router: Router
  // ) {}
  private _menuItem: Subject<MenuItem> = new Subject<MenuItem>();
  private _accessRight: Subject<AccessRight> = new Subject<AccessRight>();
  private _socketRobotData: Subject<SocketData> = new Subject<SocketData>();
  private _socketEventData: Subject<any> = new Subject<any>();
  private _socketNotificationData: Subject<any> = new Subject<any>();
  private _socketMissionData: Subject<any> = new Subject<any>();
  private _socketVideoConference: Subject<any> = new Subject<any>();
  private _socketMissionStatus: Subject<any> = new Subject<any>();
  private _sideBarFlag: Subject<Boolean> = new Subject<Boolean>();
  private _logoutFlag: Subject<Boolean> = new Subject<Boolean>();
  private _mailId: Subject<userData> = new Subject<userData>();
  public _loginFlag: Subject<Boolean> = new Subject<Boolean>();
  public _socketControlStatus: Subject<any> = new Subject<any>();
  public _socketAnswerCall: Subject<any> = new Subject<any>();
  private _robotStateSocketData: Subject<any> = new Subject<any>();
  private _socketUserMission: Subject<any> = new Subject<any>();
  private _socketLiftCommandStatus: Subject<any> = new Subject<any>();
  private _socketLiftCommand: Subject<any> = new Subject<any>();
  private _socketLiftStatus: Subject<any> = new Subject<any>();
  private _socketPathPlanner: Subject<any> = new Subject<any>();
  private _socketRobotLog: Subject<any> = new Subject<any>();
  private _socketRobotSensors: Subject<any> = new Subject<any>();
  private _socketJobData: Subject<any> = new Subject<any>();
  private _socketEvent: Subject<any> = new Subject<any>();
  private _socketDoorStatus: Subject<any> = new Subject<any>();
  private _socketVaZoneItems: Subject<any> = new Subject<any>();
  // robot common

  public settings = new Settings(false);

  set menuItem(value) {
    this._menuItem.next(value);
  }

  get menuItem$(): Observable<any> {
    return this._menuItem.asObservable();
  }

  set mailID(value) {
    this._mailId.next(value);
  }

  get mailID$(): Observable<any> {
    return this._mailId.asObservable();
  }

  set accessRight(value) {
    this._accessRight.next(value);
  }

  get accessRight$(): Observable<any> {
    return this._accessRight.asObservable();
  }

  set socketRobotData(value) {
    this._socketRobotData.next(value);
  }
  get socketRobotData$(): Observable<any> {
    return this._socketRobotData.asObservable();
  }

  set socketEventData(value) {
    this._socketEventData.next(value);
  }
  get socketEventData$(): Observable<any> {
    return this._socketEventData.asObservable();
  }

  set socketRbtStateData(value) {
    this._robotStateSocketData.next(value);
  }
  get socketRbtStateData$(): Observable<any> {
    return this._robotStateSocketData.asObservable();
  }
  set socketNotificationData(value) {
    this._socketNotificationData.next(value);
  }
  get socketNotificationData$(): Observable<any> {
    return this._socketNotificationData.asObservable();
  }

  set socketMissionData(value) {
    this._socketMissionData.next(value);
  }

  get socketMissionData$(): Observable<any> {
    return this._socketMissionData.asObservable();
  }

  set socketVC(value) {
    this._socketVideoConference.next(value);
  }

  get socketVC$(): Observable<any> {
    return this._socketVideoConference.asObservable();
  }

  set socketEvent(value) {
    this._socketEvent.next(value);
  }

  get socketEvent$(): Observable<any> {
    return this._socketEvent.asObservable();
  }

  set sideBarFlag(value) {
    this._sideBarFlag.next(value);
  }

  get sideBarFlag$(): Observable<any> {
    return this._sideBarFlag.asObservable();
  }

  set logoutFlag(value) {
    this._logoutFlag.next(value);
  }

  get logoutFlag$(): Observable<any> {
    return this._logoutFlag.asObservable();
  }

  set loginFlag(value) {
    this._loginFlag.next(value);
  }

  get loginFlag$(): Observable<any> {
    return this._loginFlag.asObservable();
  }

  // public handelError(error) {
  //     if (error.code === 401) {
  //         this._authSrvc.signOut();
  //     }
  // }

  set socketMissionStatus(value) {
    this._socketMissionStatus.next(value);
  }

  get socketMissionStatus$(): Observable<any> {
    return this._socketMissionStatus.asObservable();
  }

  set socketControlStatus(value) {
    this._socketControlStatus.next(value);
  }

  get socketControlStatus$(): Observable<any> {
    return this._socketControlStatus.asObservable();
  }

  set socketAnswerCall(value) {
    this._socketAnswerCall.next(value);
  }

  get socketAnswerCall$(): Observable<any> {
    return this._socketAnswerCall.asObservable();
  }

  set socketUserMission(value) {
    this._socketUserMission.next(value);
  }

  get socketUserMission$(): Observable<any> {
    return this._socketUserMission.asObservable();
  }

  set socketLiftCommandStatus(value) {
    this._socketLiftCommandStatus.next(value);
  }

  get socketLiftCommandStatus$(): Observable<any> {
    return this._socketLiftCommandStatus.asObservable();
  }

  get socketLiftCommand$(): Observable<any> {
    return this._socketLiftCommand.asObservable();
  }

  set socketLiftCommand(value) {
    this._socketLiftCommand.next(value);
  }

  set socketLiftStatus(value) {
    this._socketLiftStatus.next(value);
  }

  get socketLiftStatus$(): Observable<any> {
    return this._socketLiftStatus.asObservable();
  }

  set socketPathPlanner(value) {
    this._socketPathPlanner.next(value);
  }

  get socketPathPlanner$() {
    return this._socketPathPlanner.asObservable();
  }

  set socketRobotLog(value) {
    this._socketRobotLog.next(value);
  }

  get socketRobotLog$() {
    return this._socketRobotLog.asObservable();
  }

  set socketRobotSensors(value) {
    this._socketRobotSensors.next(value);
  }

  get socketRobotSensors$() {
    return this._socketRobotSensors.asObservable();
  }

  set socketJobData(value) {
    this._socketJobData.next(value);
  }

  get socketJobData$(): Observable<any> {
    return this._socketJobData.asObservable();
  }

  set socketDoorStatus(value) {
    this._socketDoorStatus.next(value);
  }

  get socketDoorStatus$(): Observable<any> {
    return this._socketDoorStatus.asObservable();
  }

  set socketVaZoneItems(value) {
    this._socketVaZoneItems.next(value);
  }

  get socketVaZoneItems$(): Observable<any> {
    return this._socketVaZoneItems.asObservable();
  }
}
