import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextSofiaComponent } from './text-sofia.component';

@NgModule({
  declarations: [TextSofiaComponent],
  imports: [CommonModule],
  exports: [TextSofiaComponent],
})
export class TextSofiaModule {}
