import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { AppSettings } from 'app/app.settings';
import { TemplatePortal } from '@angular/cdk/portal';
import _ from 'lodash';
import { CallInterfaceService } from './call-interface.service';
import { RobotsService } from '../../../modules/robots/robots.service';
import { CallRobot } from 'app/modules/robots/teleoperations/teleoperation.config';
import { v4 as uuid } from 'uuid';
import { publishTopics } from 'app/app.model';
import { AppComponent } from 'app/app.component';

@Component({
  selector: 'app-call-interface',
  templateUrl: './call-interface.component.html',
  styleUrls: ['./call-interface.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'callInterface',
})
export class CallInterfaceComponent implements OnInit {
  @ViewChild('incomingcallOrigin') private _incomingcallOrigin: MatButton;
  @ViewChild('incomingcallPanel') private _incomingcallPanel: TemplateRef<any>;

  incomingCalls: Array<any> = [];
  private _overlayRef: OverlayRef;
  companyId: string = localStorage.getItem('companyId');

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _router: Router,
    private _appSettings: AppSettings,
    private _callService: CallInterfaceService,
    private robotSvc: RobotsService,
    private _appComp: AppComponent
  ) {}

  ngOnInit(): void {
    this._appSettings.socketAnswerCall$.subscribe((call: any) => {
      const currentCall = JSON.parse(localStorage.getItem('answeredCall'));
      switch (call.action) {
        case 1:
          this.getIncomingData(call);
          break;
        case 2:
          if (currentCall && currentCall['callId'] === call.callId) {
            localStorage.setItem('answeredCall', null);
          }
          // remove the end call from the queue
          this.removeCall(call);
          break;
        case 4:
          // if answered from me, remove call from the header incoming call,
          if (currentCall !== null && currentCall['callId'] === call.callId) {
            this.removeCall(call);
          }
          //else remove the call from the queue
          if (currentCall === null || currentCall['callId'] !== call.callId) {
            this.removeCall(call);
          }
          break;
        default:
          break;
      }
    });

    this.robotSvc.hideIncomingCall.subscribe((call: any) => {
      this.removeCall(call);
    });
  }

  getIncomingData(call) {
    this._callService
      .getRobotDetails(call.robotId)
      .subscribe((response: any) => {
        if (response.code === 200) {
          call.name = response.result && response.result?.name;
        }
        let index = _.findLastIndex(this.incomingCalls, (o) => {
          return o.robotId === call.robotId;
        });
        if (index !== -1) {
          // if the same robot has a call in the list, discard it first
          this.incomingCalls.splice(index, 1);
        }
        this.incomingCalls.push(call);
        localStorage.setItem(
          'IncomingCalls',
          JSON.stringify(this.incomingCalls)
        );

        // remove call from the queue after 30 seconds
        _.delay(
          () => {
            this.removeCall(call);
          },
          30000,
          'later'
        );
      });
  }

  /**
   * Open the call panel
   */
  openPanel(): void {
    const urlItems = this._router.url.split('/');
    let includeRobot = this.findCallRobot();

    if (
      urlItems.includes('teleoperations') &&
      this.incomingCalls.length === 1 &&
      includeRobot
    ) {
      return;
    } else {
      // Create the overlay if it doesn't exist
      if (!this._overlayRef) {
        this.createOverlay();
      }

      // Attach the portal to the overlay
      this._overlayRef.attach(
        new TemplatePortal(this._incomingcallPanel, this._viewContainerRef)
      );
    }
  }

  /**
   * find weather the incoming call is in the same page
   */
  findCallRobot() {
    const urlItems = this._router.url.split('/');
    let includeRobot = this.incomingCalls.find((item) =>
      urlItems.includes(item.robotId)
    );
    return includeRobot;
  }

  /**
   * Close the call panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  createOverlay() {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._incomingcallOrigin._elementRef.nativeElement)
        .withLockedPosition()
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  answerConference(call) {
    //save the current call in localstorage
    localStorage.setItem('answeredCall', JSON.stringify(call));

    this.removeCall(call); // remove the item from the list
    this._overlayRef && this.closePanel();

    this.robotSvc.incomingCall$.next(call);
    // this.robotSvc.headerAnswer.next(true);

    const urlItems = this._router.url.split('/');
    let includeRobot = this.findCallRobot();

    // if (
    //   (urlItems.includes('teleoperations') && includeRobot === undefined) ||
    //   urlItems.includes('monitoring')
    // ) {
    //if the user is in another teleoperations page
    window.open(`/robots/teleoperations/${call.robotId}`, '_blank');
    // } else {
    //   this._router.navigate([`/robots/teleoperations/${call.robotId}`]);
    // }
  }

  rejectConference(call) {
    this.removeCall(call);

    const now = new Date();
    const timestamp = now.getTime();
    const data: CallRobot = {
      id: uuid(),
      timestamp: timestamp,
      robotId: call.robotId,
      companyId: this.companyId,
      content: call.content,
      action: 2,
      callId: call.callId,
    };

    const topic = publishTopics.callRobot + this.companyId;
    this._appComp.mqttClient.publish(topic, JSON.stringify(data));
  }

  removeCall(call) {
    let index = _.findLastIndex(this.incomingCalls, (o) => {
      return o.content === call.content;
    });
    this.incomingCalls.splice(index, 1);
    localStorage.setItem('IncomingCalls', JSON.stringify(this.incomingCalls));

    if (this.incomingCalls.length === 0) {
      this._overlayRef && this.closePanel();
    }
  }

  getClassname() {
    return this.incomingCalls.length === 0 ? 'hideQueue' : 'showQueue';
  }
}
