import { Component, Input } from '@angular/core';

@Component({
  selector: 'rm-label-value',
  template: `
    <div class="my-4">
      <label class=" font-bold">{{ label }}</label>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class LabelValueComponent {
  @Input() label: string;
}
