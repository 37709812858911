import { Component, Input } from '@angular/core';

@Component({
  selector: 'rm-label-event',
  template: `
    <div class="flex flex-wrap gap-2 my-2" *ngIf="labelEvent">
      <div
        class="w-auto bg-grey-dark rounded-md align-middle text-center"
        *ngFor="let data of labelEvent"
      >
        <span class="w-auto font-bold px-2 py-1 text-md ">{{ data }}</span>
      </div>
    </div>
  `,
})
export class LabelEventComponent {
  @Input() labelEvent: string[];
}
