import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
})
export class SnackbarComponent {
  iconUrl = '../../../../assets/icons/warning-red.svg';
  status: string = 'failed';
  message: string;
  dissmissString: string;
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      type?: 'success' | 'failed' | 'info';
      message: string;
      preClose?: () => void;
    }
  ) {
    this.message = translate(data.message);
    this.dissmissString = translate('Dismiss');
    this.status = data.type;
  }
  dismiss(): void {
    this.data.preClose();
  }
}
