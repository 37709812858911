import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DatatableService {
  constructor(private http: HttpClient) {}

  getEnumList(data) {
    return this.http.post<any>('/api/v2/datadict/getByType', data).toPromise();
  }
}
