import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  /** Constructor **/

  isRemoveProfileImage = new Subject<boolean>();
  constructor(private _http: HttpClient) {}
  private headers = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  /** Profile Save **/
  /** @param profileData **/
  saveProfile(profileData) {
    //return null;
    return this._http.post('/api/reset-password', profileData);
  }

  /** Get Profile Data **/
  getProfileData(userID) {
    return this._http.get('/api/v2/user/' + userID, this.headers);
  }

  /** Get Logininfo */
  getLoginInfo() {
    return this._http.get('/api/v2/auth/loginInfo');
  }

  /** Get file from assets */
  getAssetFile(id) {
    return this._http.get('/api/v2/asset/get-file/' + id, {
      responseType: 'blob',
    });
  }
}
