import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopHeaderComponent } from './top-header.component';
import { MatIconModule } from '@angular/material/icon';
import { DirectiveModule } from 'app/shared/directives/directive.module';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
  declarations: [TopHeaderComponent],
  imports: [CommonModule, MatIconModule, DirectiveModule, TranslocoRootModule],
  exports: [TopHeaderComponent],
})
export class TopHeaderModule {}
