// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const hostnameRegex =
  /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[0-9])\.((1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.){2}(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$/;
const ipAddress = hostnameRegex.test(window.location.hostname);
const HOSTNAME = ipAddress
  ? window.location.hostname
  : `video.${window.location.hostname}`;

const SIGNALING_HOSTNAME = ipAddress
  ? `${window.location.hostname}:3478`
  : `l.google.com:19305`;

// const domain = window.location.hostname;
// const videoHost = `video.${domain}`;

export const environment = {
  baseUrl: 'http://localhost:4200',
  production: false,
  mqttHost: 'wss://dev.robotmanager.io:8443',
  peerJsOptions: {
    host: HOSTNAME, // 'video.dev.robotmanager.io',
    port: 9000,
    path: '/peerjs',
    secure: true,
    debug: 0,
    config: {
      iceServers: [
        {
          urls: [`stun:stun.${SIGNALING_HOSTNAME}`],
        },
      ],
    },
  },
  videoServerUrl: `https://${HOSTNAME}`,
  vcTokenExpiry: 720,
  reportIssueUrl: 'https://sgpnp.atlassian.net/servicedesk/customer/portal/4'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
