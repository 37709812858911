import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'rm-pill-button',
  template: `<button
    class="rounded-full mx-1 py-2 px-5 bg-cyan-dark"
    [ngClass]="myNgClass"
    (click)="handleAction()"
  >
    {{ label }}
  </button>`,
})
export class PillButtonComponent implements OnInit {
  @Input() label: string;
  @Input() pointer: boolean = false;
  @Output() clickEvent = new EventEmitter();

  myNgClass: string[];

  constructor() {}

  ngOnInit(): void {
    this.myNgClass = [
      this.pointer ? 'text-cyan pointer-events-auto' : 'pointer-events-none',
    ];
  }

  handleAction(): void {
    this.clickEvent.emit();
  }
}
