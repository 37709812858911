import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BulkActionButtonComponent } from './bulk-action-button.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
  declarations: [BulkActionButtonComponent],
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    TranslocoRootModule,
    FormsModule,
    CommonModule,
  ],
  exports: [BulkActionButtonComponent],
})
export class BulkActionButtonModule {}
