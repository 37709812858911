import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  FuseNavigationItem,
  FuseNavigationService,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { InitialData } from 'app/app.types';
import { AuthService } from 'app/core/auth/auth.service';
import {
  MAIN_NAVIGATION_ITEMS,
  SETTING_NAVIGATION_ITEMS,
} from 'app/modules/settings/constants';
import { ApiConfigService } from 'app/services/api-config.service';
import { ApiMenu } from 'app/services/api-menu.service';
import { Resource } from 'app/services/api.types';
import { MenuService } from 'app/services/menu.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SubNavi, ThinService } from './thin.service';

@Component({
  selector: 'thin-layout',
  templateUrl: './thin.component.html',
  styleUrls: ['./thin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ThinLayoutComponent implements OnInit, OnDestroy {
  data: InitialData;
  isScreenSmall: boolean;
  screenSize: string[];
  public mainNavigation: FuseNavigationItem[] = [];
  public subNavigation: SubNavi[] = [];
  mainMenu: FuseNavigationItem[] = [];
  subMenu: FuseNavigationItem[] = [];
  settings = false;

  navigation: SubNavi[] = [];

  pageName = 'SETTINGS';
  accessRightData: Resource[];
  enabledModules = {};

  errorCode = {
    // 401: this.logout(),
  };

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _thinService: ThinService,
    private _changeDetectEdf: ChangeDetectorRef,
    private _authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer,
    private apiMenu: ApiMenu,
    private configSvc: ApiConfigService,
    private menuSvc: MenuService
  ) {
    this.matIconRegistry.addSvgIcon(
      'settings_tools',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        'assets/icons/settings.svg'
      )
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.generateMainMenu();
    this.generateSubMenu();
    // get enabled navigation items only
    // this.configSvc.getConfig().subscribe((data: ConfigData) => {
    //   // let enabledMianNav = data.enableModules.main_navigation;
    //   this.enabledModules = data.enableModules;

    //   // at the beginning, router event is not fired. Therefore,
    //   // check the current URL. If current URL has /settings, then show/hide
    //   // the setting menu.
    //   this.toggleSettingMenu(this._router.url.startsWith('/settings'));

    //   // only show settings tab, when url contains /settings
    //   // load this in
    //   this._router.events
    //     .pipe(filter((event) => event instanceof NavigationEnd))
    //     .subscribe((event: NavigationEnd) => {
    //       this.toggleSettingMenu(event.url.startsWith('/settings'));
    //     });
    // });

    // check permission for main navigation
    // this.handleParentResourcesNav();
    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('sm');
        this.screenSize = matchingAliases;
      });

    // at the beginning, router event is not fired. Therefore,
    // check the current URL. If current URL has /settings, then show/hide
    // the setting menu.
    this.toggleSettingMenu(this._router.url.startsWith('/settings'));

    // only show settings tab, when url contains /settings
    // load this in
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.toggleSettingMenu(event.url.startsWith('/settings'));
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * Generates the main menu items
   *
   */
  private generateMainMenu(): void {
    this.menuSvc
      .getMainMenus()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((menus) => {
        this.mainMenu = menus;
      });
  }

  /**
   * Generate the sub menu or settings menu for rm core
   *
   */
  private generateSubMenu(): void {
    this.menuSvc
      .getSettingMenus()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((menus) => {
        this.subMenu = menus;
      });
  }

  /**
   * Function to count the total of subMenu that is not hidden
   *
   * @return number
   *
   */
  private countHiddenSubMenu(): number {
    // Filter subMenu items where the hidden() function returns false.
    const hiddenSubMenus = this.subMenu?.filter((item) => !item.hidden(item));

    // Return the count of hidden subMenu items.
    return hiddenSubMenus.length;
  }

  /**
   * Get the number of subMenu that is not hidden
   *
   * @return number
   *
   */
  get numberSubMenu(): number {
    return this.countHiddenSubMenu();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent(name);
    console.log('navigation :>> ', navigation);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  navigateTo(path) {
    this._router.navigate([path]);
    this.settings = true;
  }

  handleError(code) {
    if (code == 401) {
      this.logout();
    }
  }

  logout() {
    return this._authService.signOut();
  }

  /**
   * Toggle setting menu to be displayed or hidden
   *
   * @param show
   */
  private toggleSettingMenu = (show: boolean) => {
    this.settings = show;
    if (this.screenSize.includes('md')) {
      this.navigation = show ? this.subMenu : [];
    }
  };

  // handle resources navigation here
  public handleParentResourcesNav(): void {
    this._thinService
      .getLoginResources()
      .toPromise()
      .then((data) => {
        console.log('data :>> ', data);
        const dataNavigasi = data.result;
        //sort dataNavigasi except 0
        dataNavigasi.sort((a, b) => (a.order > b.order ? -1 : 1));
        // filter data navigasi only get type 2
        const dataNavigasi2 = dataNavigasi.filter((nav) => nav.type === 2);
        this._changeDetectEdf.detectChanges();
        console.log('this.dataNavigasi2 :>> ', dataNavigasi2);
      })
      .catch((error) => this.handleError(error.status));
    // get navigasi main permission
    console.log('SETTING_NAVIGATION_ITEMS :>> ', SETTING_NAVIGATION_ITEMS);
    console.log('this.parentNavigation :>> ', this.mainNavigation);
  }
}
