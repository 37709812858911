import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Robot } from 'app/services/api.types';

@Component({
  selector: 'rm-select-robots',
  templateUrl: './select-robot.html',
  styleUrls: ['./select-robots.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectRobotComponent implements OnInit {
  @Input() robots: Robot[] = [];
  @Input() selectedRobots: Robot[] = [];
  @Input() canSearch: boolean = false;

  @Output() selectRobot = new EventEmitter<Robot>();

  filteredRobots: Robot[] = [];
  checked = false;

  // list Robot
  connectivity = {
    low: 'assets/images/monitoring/robot-list/connect-low.svg',
    mid: 'assets/images/monitoring/robot-list/connect-mid.svg',
    full: 'assets/images/monitoring/robot-list/connect-full.svg',
  };
  battery = {
    low: 'assets/images/monitoring/robot-list/battery-low.svg',
    mid: 'assets/images/monitoring/robot-list/battery-mid.svg',
    full: 'assets/images/monitoring/robot-list/battery-full.svg',
  };
  selectIcon = 'assets/images/monitoring/quick-mission/add-icon.svg';
  selectedIcon = 'assets/images/monitoring/quick-mission/check-icon.svg';

  constructor(private datePipe: DatePipe) {}

  public isSelected(robot: Robot): boolean {
    const found = this.selectedRobots.find((item) => item.id === robot.id);
    return found ? true : false;
  }

  public ngOnInit(): void {
    // console.log('this.robots :>> ', this.robots);
    this.filteredRobots = this.robots.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    console.log('this.filteredRobots :>> ', this.filteredRobots);
  }

  public searchOnChange(search: string): void {
    console.log('search :>> ', search);
    const substr = search.trim().toLowerCase();

    this.filteredRobots = this.robots.filter(
      (robot) => robot.name.toLowerCase().indexOf(substr) > -1
    );
  }

  public handleToggleChange(event: MatSlideToggleChange): void {
    this.checked = event.checked;
    if (event.checked) {
      this.filteredRobots = this.filteredRobots.filter((robot) =>
        this.selectedRobots.some(
          (selectedRobot) => selectedRobot.id === robot.id
        )
      );
    } else {
      this.filteredRobots = this.robots.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    }
  }

  public getStatusString(robot: Robot): string {
    const state = `${robot.state}`;
    switch (state) {
      case '1':
        return 'init';
      case '2':
        return 'idle';
      case '3':
        return 'work';
      case '4':
        return 'charge';
      case '5':
        return 'error';
      case '6':
        return 'teleop';
    }
  }

  public minutesAgo(date: string, format: boolean = false): string {
    try {
      if (format) {
        return `${this.datePipe.transform(date, 'HHmm')} hrs`;
      } else {
        return `${this.datePipe.transform(date, 'HH:mm')}`;
      }
    } catch (err) {
      return date;
    }
  }
}
