import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideToggleComponent } from './slide-toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SlideToggleComponent],
  imports: [CommonModule, MatSlideToggleModule, MatIconModule, FormsModule],
  exports: [SlideToggleComponent],
})
export class SlideToggleModule {}
