import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
@Component({
  selector: 'rm-status',
  template: ` <div [ngClass]="{ 'flex flex-row': showRoundedIcon }">
    <div
      *ngIf="showRoundedIcon"
      class="p-1.5 mr-1 mb-1 self-center rounded-xl"
      [ngClass]="roundedIconStyleClass"
    ></div>
    <span class="rounded p-2" [ngClass]="styleClass">
      {{ status | transloco }}
    </span>
  </div>`,
})
export class StatusComponent implements OnInit {
  @Input() module: string = 'mission';
  @Input() statusCode: string;

  public status: string;
  public styleClass: string[];
  public roundedIconStyleClass: string;

  showRoundedIcon = false;

  constructor() {}

  ngOnInit(): void {
    this.status = this.getStatusString(this.module, this.statusCode);
    this.styleClass = this.getClasses(this.status);
  }

  ngOnChanges(changes: SimpleChange): void {
    this.status = this.getStatusString(this.module, this.statusCode);
    this.styleClass = this.getClasses(this.status);
  }

  /**
   * A helper function to get status string
   *
   * @param module
   * @param statusCode
   * @returns
   */
  public getStatusString(module: string, statusCode: string): string {
    switch (module.toLowerCase()) {
      case 'mission':
        return this.getMissionStatusString(statusCode);
      case 'robot':
        return this.getRobotStatusString(statusCode);
      case 'event':
        return this.getEventStatusString(statusCode);
      case 'sensor':
        return this.getSensorStatusString(statusCode);
      case 'robotloghistory':
        return this.getRobotLogHistoryStatusString(statusCode);
      case 'lift':
        return this.getLiftStatusString(statusCode);
      case 'user':
        return statusCode;
      case 'task':
        return this.getTaskStatusString(statusCode);
      case 'apps':
        return this.getAppsStatusString(statusCode);
      case 'vazoneitem':
        return this.getVaZoneItemStatusString(statusCode);
      case 'door':
        return this.getDoorStatusString(+statusCode);

      default:
        return `${module} status ${statusCode}`;
    }
  }

  /**
   * A helper to get mission status string
   *
   * @param statusCode number
   * @returns string
   */
  private getMissionStatusString(statusCode: string): string {
    switch (statusCode) {
      case `1`:
        return 'Completed';
      case `2`:
        return 'Aborted';
      case `3`:
        return 'Paused';
      case `4`:
        return 'Scheduled';
      case `5`:
        return 'Active';
      case `6`:
        return 'Failed';
      default:
        return `Mission status ${statusCode}`;
    }
  }

  /**
   * Get tailwind classes given a status string
   *
   * @param statusString
   * @returns array of tailwind classes
   */
  public getClasses(statusString: string): string[] {
    switch (statusString) {
      case 'Draft':
        return ['bg-grey', 'text-primary'];
      case 'Completed':
      case 'Resolved':
        return ['bg-success', 'text-white'];
      case 'Online':
      case 'Installed':
        return ['bg-primary', 'text-white']; //Robot Online Status
      case 'Offline':
      case 'Not Installed':
        return ['bg-gray-500', 'text-gray-400']; //Robot Offline Status
      case 'Idle':
        return ['bg-gray-500', 'text-white']; //Robot Idle Status
      case 'Paused':
        return ['bg-danger', 'text-white']; //Robot Paused Status
      case 'Aborted':
      case 'Unresolved':
        return ['bg-black', 'text-white'];
      case 'Scheduled':
        return ['bg-warning', 'text-white'];
      case 'Active':
        return ['bg-primary', 'text-white'];
      case 'Inactive':
      case 'Deactivated':
        return ['bg-grey-dark', 'text-white'];
      case 'Suspended':
        return ['bg-danger', 'text-white'];
      case 'Failed':
        return ['bg-red-700', 'text-white'];
      case 'Info':
      case 'Activated':
        return ['bg-success', 'text-white'];
      case 'Debug':
        return ['bg-primary', 'text-white'];
      case 'Warn':
        return ['bg-warning', 'text-white'];
      case 'Error':
        return ['bg-red-700', 'text-white'];
      case 'Fatal':
        return ['bg-red-700', 'text-white'];
      case 'Ready':
        return ['bg-primary', 'text-white'];
      case 'Busy':
        return ['bg-danger', 'text-white'];
      case 'Opened':
        return ['bg-primary', 'text-white'];
      case 'Moving':
      case 'Activating':
      case 'Deactivating':
        return ['bg-warning', 'text-white'];
      case 'Closed':
        return ['bg-gray-500', 'text-gray-400'];
      default:
        return ['text-white'];
    }
  }

  private getRobotStatusString(statusCode: string): string {
    switch (statusCode) {
      case '1':
        return 'Online';
      case '2':
        return 'Offline';
      case '3':
        return 'Idle';
      case '4':
        return 'Paused';
      default:
        return '';
    }
  }

  private getEventStatusString(statusCode: string): string {
    switch (statusCode) {
      case '1':
        return 'Resolved';
      case '2':
        return 'Unresolved';
      default:
        return statusCode;
    }
  }

  private getRoundedIconStyleClass(statusCode: string): string {
    switch (statusCode) {
      case 'Active':
        return 'bg-primary';
      case 'Inactive':
        return 'bg-grey-dark';
      case 'Pending':
        return 'bg-warning';
      default:
        return 'bg-grey-dark';
    }
  }

  private getSensorStatusString(statusCode: string): string {
    switch (statusCode) {
      case '1':
        return 'Online';
      case '2':
        return 'Offline';
      default:
        return '';
    }
  }
  private getRobotLogHistoryStatusString(statusCode: string): string {
    switch (statusCode) {
      case `1`:
        return 'Info';
      case `2`:
        return 'Debug';
      case `3`:
        return 'Warn';
      case `4`:
        return 'Error';
      case `5`:
        return 'Fatal';
    }
  }

  private getLiftStatusString(statusCode: string): string {
    switch (statusCode) {
      case '1':
        return 'Ready';
      case '2':
        return 'Busy';
      default:
        return '';
    }
  }

  private getTaskStatusString(statusCode: string): string {
    switch (statusCode) {
      case `1`:
        return 'Active';
      case `2`:
        return 'Completed';
      case `3`:
        return 'Failed';
      case `4`:
        return 'Aborted';
      case `5`:
        return 'Paused';
      default:
        return '';
    }
  }

  private getAppsStatusString(statusCode: string): string {
    switch (statusCode) {
      case '1':
        return 'Installed';
      case '2':
        return 'Not Installed';
      default:
        return '';
    }
  }

  private getVaZoneItemStatusString(statusCode: string): string {
    switch (statusCode) {
      case 'activated':
        return 'Activated';
      case 'activating':
        return 'Activating';
      case 'deactivated':
        return 'Deactivated';
      case 'deactivating':
        return 'Deactivating';
      default:
        return statusCode;
    }
  }

  private getDoorStatusString(statusCode: number): string {
    switch (statusCode) {
      case 1:
        return 'Closed';
      case 2:
        return 'Moving';
      case 3:
        return 'Opened';
      default:
        return 'Closed';
    }
  }
}

@NgModule({
  declarations: [StatusComponent],
  imports: [CommonModule, TranslocoModule],
  exports: [StatusComponent],
})
export class StatusModule {}
