import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ListQuery } from 'app/services/api.types';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(private http: HttpClient) {}

  query: ListQuery = {
    pageNo: 1,
    pageSize: 50,
  };

  getEnumList(data) {
    return this.http.post<any>('/api/v2/datadict/getByType', data).toPromise();
  }

  getFilterSpec(modelName) {
    return this.http.get<any>(`/api/v2/query/${modelName}`).toPromise();
  }

  getFilterRobotBrand(data) {
    return this.http.post<any>('/api/v2/robot-make/list', data).toPromise();
  }

  //This just a temp solution for filter data on 12-Jul-2024 start
  datadict(data?) {
    return this.http
      .post<any>('/api/v2/robot-model/list', this.query)
      .toPromise();
  }

  RobotBrand(data?) {
    return this.http
      .post<any>('/api/v2/robot-make/list', this.query)
      .toPromise();
  }
  //This just a temp solution for filter data on 12-Jul-2024 end
}
