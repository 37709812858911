import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Filter, Order } from './api.types';
import { PageEvent } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root',
})
export class QueryParamService {
  length = new BehaviorSubject<number>(0);
  pageIndex = new BehaviorSubject<number>(1);
  pageSize = new BehaviorSubject<number>(10);
  pageEvent = new BehaviorSubject<PageEvent>({
    length: 0,
    pageIndex: 1,
    pageSize: 10,
  });
  defaultOrder: Order[] = [
    {
      column: 'created_at',
      type: 'desc',
    },
  ];
  filterData = new BehaviorSubject<Filter[]>([]);
  order = new BehaviorSubject<Order[]>(this.defaultOrder);
  selectedTab = new BehaviorSubject<number>(0);
  initialQueryParams: Params;

  constructor(private _router: Router, private _route: ActivatedRoute) {
    this.initialQueryParams = {
      total: 0,
      page: 1,
      itemsPerPage: 10,
      filter: JSON.stringify([]),
      order: JSON.stringify([]),
      selectedTab: 0,
    };
  }

  /**
   * append all params to current url
   *
   * @returns void
   */
  public appendParamsToUrl(
    pageEvent: PageEvent = null,
    filter: Filter[] = null,
    order: Order[] = null,
    selectedTab: number = null
  ): void {
    let params: Params;

    if (pageEvent) {
      params = {
        total: pageEvent.length,
        page: pageEvent.pageIndex,
        itemsPerPage: pageEvent.pageSize,
      } as PageParams;
    }

    if (filter) {
      params.filter = JSON.stringify(filter);
    }

    if (order) {
      order[0].column = this.camelToSnakeCase(order[0].column);
      params.order = JSON.stringify(order);
    }

    if (selectedTab !== null) {
      params.selectedTab = selectedTab;
    }

    console.log('debug: Params', params);

    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: params,
      // queryParamsHandling: 'merge',
      queryParamsHandling: 'preserve',
    });
  }

  /**
   * Set query params
   *
   * @returns void
   */
  public setQueryParams(): void {
    this._route.queryParamMap.subscribe((params) => {
      this.initialQueryParams.total = Number(params.get('total'));
      this.initialQueryParams.page = Number(params.get('page'));
      this.initialQueryParams.itemsPerPage = Number(params.get('itemsPerPage'));
      this.initialQueryParams.filter = params.get('filter');
      this.initialQueryParams.order = params.get('order');
      this.initialQueryParams.selectedTab = Number(params.get('selectedTab'));
    });
  }

  public setFilterData(): void {
    this.filterData.next(JSON.parse(this.initialQueryParams.filter));
  }

  public setOrder(): void {
    this.order.next(JSON.parse(this.initialQueryParams.order));
  }

  public setSelectedTab(): void {
    this.selectedTab.next(this.initialQueryParams.selectedTab);
  }

  public setPage(): void {
    this.length.next(this.initialQueryParams.total);
    this.pageIndex.next(this.initialQueryParams.page);
    this.pageSize.next(this.initialQueryParams.itemsPerPage);
  }

  public setPageEvent(): void {
    this.pageEvent.next({
      length: this.initialQueryParams.total,
      pageIndex: this.initialQueryParams.page,
      pageSize: this.initialQueryParams.itemsPerPage,
    });
  }

  public applyExistedParams(): void {
    this.setQueryParams();
    this.setPage();
    this.setFilterData();
    this.setOrder();
    this.setSelectedTab();
  }

  public resetPage(): void {
    this.length.next(0);
    this.pageIndex.next(1);
    this.pageSize.next(10);
  }

  public resetPageEvent(): void {
    this.pageEvent.next({
      length: 0,
      pageIndex: 1,
      pageSize: 10,
    });
  }

  public resetFilterData(): void {
    this.filterData.next([]);
  }

  public resetOrder(): void {
    this.order.next(this.defaultOrder);
  }

  public resetSelectedTab(): void {
    this.selectedTab.next(0);
  }

  public resetParams(): void {
    this.resetPage();
    this.resetFilterData();
    this.resetOrder();
    this.resetSelectedTab();
  }

  public camelToSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  }
}

export interface PageParams {
  page: number;
  total: number;
  itemsPerPage: number;
}

export interface Params extends PageParams {
  filter?: string;
  order?: string;
  selectedTab?: number;
}
