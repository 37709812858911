import { CdkTextColumn } from '@angular/cdk/table';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'rm-link-column',
  template: `
    <ng-container matColumnDef [sticky]="sticky">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="headerClass"
        [disabled]="disabled"
      >
        {{ headerText | transloco }}
      </th>
      <td mat-cell *matCellDef="let row" [ngClass]="columnClass">
        <a
          class=" text-primary hover:text-primary-500"
          [routerLink]="
            this.tokenisedLink.replace(
              ':' + tokenName,
              dataAccessor(row, tokenName)
            )
          "
        >
          {{ dataAccessor(row, name) }}
        </a>
      </td>
    </ng-container>
  `,
})
export class DatatableLinkColumn<T> extends CdkTextColumn<T> {
  @Input() tokenName: string;
  @Input() tokenisedLink: string;
  @Input() headerClass: string;
  @Input() columnClass: string;
  @Input() sticky: boolean = false;
  @Input() disabled: boolean = false;
}
