import { Component, OnInit } from '@angular/core';
import { HeaderService } from './header.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  breadcrumb = [];
  viewType = '2d';
  viewTypeLayout = 'editor';
  displayTabMonitoring: boolean = false;
  displayTabLayouts: boolean = false;
  dropdownMenu = false;
  dropdownValues = [];
  selectedValue: string;
  label: string;
  currentPath: any;
  moduleName: string = 'header';
  has3DMap: boolean = true;

  constructor(
    private _headerService: HeaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e['url'].includes('settings/layouts/')) {
          if (e['url'].includes('settings/layouts/site/')) {
            this.displayTabLayouts = false;
          } else {
            this.displayTabLayouts = true;
          }
        } else {
          this.displayTabLayouts = false;
        }
        this.displayTabMonitoring = e['url'].includes('monitoring')
          ? true
          : false;
        this.dropdownMenu = e['url'].includes('teleoperations') ? true : false;
      }
    });

    this._headerService.toggle2DMap$.subscribe((data) => {
      this.switchViewType('2d');
    });
    if (window.location.href.includes('settings/layouts/')) {
      if (window.location.href.includes('settings/layouts/site/')) {
        this.displayTabLayouts = false;
      } else {
        this.displayTabLayouts = true;
      }
    } else {
      this.displayTabLayouts = false;
    }
    this.displayTabMonitoring = window.location.href.includes('monitoring')
      ? true
      : false;
    this.dropdownMenu = window.location.href.includes('teleoperations')
      ? true
      : false;
  }

  ngOnInit(): void {
    // this._headerService.viewType$.next(this.viewType);
    this._headerService.breadcrumb$.subscribe((result) => {
      this.breadcrumb = result;
    });

    this._headerService.currentPath$.subscribe((result) => {
      this.currentPath = result;
    });
    this._headerService.viewTypeLayout$.next(this.viewTypeLayout);

    this._headerService.dropdownValues$.subscribe((result: any) => {
      this.dropdownValues = result.list;
      this.selectedValue = result.selected;
      this.label = result.label;
      this.dropdownMenu = true;
    });

    this.viewType = localStorage.getItem('viewType') || this.viewType;
    this._headerService.viewType$.next(this.viewType);
    this._headerService.viewType = this.viewType;

    this._headerService.viewType$.subscribe((val) => {
      localStorage.setItem('viewType', val);
    });

    this._headerService.has3DMap$.subscribe((val) => (this.has3DMap = val));
  }

  switchViewType(value) {
    this.viewType = value;
    this._headerService.viewType = value;
    localStorage.setItem('viewType', value);
    this._headerService.viewType$.next(value);
  }

  switchViewTypeLayout(value) {
    this.viewTypeLayout = value;
    this._headerService.viewTypeLayout$.next(value);
  }

  onChange(data) {
    this.router.navigate([`robots/teleoperations/${data.value}`]);
  }
}
