import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutsService } from 'app/modules/settings/layouts/layouts.service';
import { PointRegion, Region } from 'app/services/api.types';
import { CRS, Map, PointExpression } from 'leaflet';
import { MiniLayoutMapService } from './dialog-data-map.service';
import { createUuid } from 'app/services/uuid.utils';

interface ZoneArea extends Omit<Region, 'coordinates' | 'metadata'> {
  coordinates: PointRegion[];
  color: string;
}

@Component({
  template: `
    <h1 mat-dialog-title>
      {{ data.layoutName }} <span *ngIf="data.name">({{ data.name }})</span>
    </h1>
    <div>
      <div class="map-container w-full" style="height: 75vh">
        <div
          id="map"
          class="h-full "
          style="background: #011b24;"
          leaflet
          [leafletOptions]="options"
          (leafletMapReady)="onMapReady($event)"
        ></div>
      </div>
    </div>
    <div mat-dialog-actions class="flex justify-end">
      <button mat-button (click)="onClose()">Close</button>
    </div>
  `,
})
export class MapDialogComponent {
  public options = {
    attributionControl: false,
    center: [0, 0],
    crs: CRS.Simple,
    maxBoundsViscosity: 1,
    maxZoom: 5,
    minZoom: -2,
    scrollWheelZoom: true,
    doubleClickZoom: false,
    zoomControl: false,
    zoom: 1,
    zoomSnap: 0.5,
    keyboard: false,
  };
  public leafletMap: Map;
  public data: Payload;

  constructor(
    private layoutsService: LayoutsService,
    public dialogRef: MatDialogRef<MapDialogComponent>,
    private leafletService: MiniLayoutMapService,
    @Inject(MAT_DIALOG_DATA) payload: Payload
  ) {
    this.data = payload;
  }

  public onMapReady(leafletMap: Map): void {
    this.leafletMap = leafletMap;
    // load layout image
    this.layoutsService
      .getLayoutImageByID(this.data.layoutId)
      .subscribe((response) => {
        this.leafletService.loadMapImage(
          response.url,
          response.width,
          response.height
        );
        this.leafletService.setGridImage('/assets/icons/layout-grid.png');
      });

    this.leafletService.initMap(leafletMap);

    switch (this.data.type) {
      case 'event': {
        this.leafletService.addEventFlag(
          this.data.name,
          this.data.coordinate as PointExpression
        );
        break;
      }
      case 'va-zone': {
        const zone: ZoneArea = {
          id: createUuid(),
          layoutId: this.data.layoutId,
          coordinates: this.data.coordinate as PointRegion[],
          color: this.data.metadata || 'red',
          name: this.data.name,
        };
        this.leafletService.renderZone(zone);
        break;
      }
    }
  }

  onClose(): void {
    this.leafletService.clear();
    this.leafletService.reset();
    this.dialogRef.close();
  }
}

interface Payload {
  name: string;
  layoutId: string;
  layoutName?: string;
  coordinate: PointExpression | PointRegion[];
  center?: PointExpression;
  type: 'event' | 'va-zone';
  metadata?: any;
}
