import { SelectionModel } from '@angular/cdk/collections';
import { CdkTextColumn } from '@angular/cdk/table';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'rm-checkbox-column',
  template: `
    <ng-container matColumnDef="select" [sticky]="sticky">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
  `,
})
export class DatatableCheckboxColumn<T> extends CdkTextColumn<T> {
  @Input() selection: SelectionModel<T>;
  @Input() sticky: boolean = false;
  @Input() isAllSelected: () => boolean;
  @Input() masterToggle: () => void;
}
