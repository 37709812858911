import { HttpHeaders, HttpClient } from '@angular/common/http';
import { forkJoin, Observable, Observer } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { ListQuery, ResponseList, ResponseOne } from './api.types';
import _, { get } from 'lodash';
import { CacheService } from './api-cache.service';

export abstract class ApiBase<T> {
  protected headers = {};
  protected base = '/api/v2';
  protected resourceBaseUrl = '';
  protected cacheService: CacheService<T>;
  protected dataList: ResponseList<T>;
  private pageSize = 50;

  constructor(protected http: HttpClient) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
    this.cacheService = new CacheService<T>();
  }

  public post = (url: string, payload: any) =>
    this.http.post<ResponseList<T>>(
      `${this.base}/${url}`,
      payload,
      this.headers
    );

  public delete = (url: string) =>
    this.http.delete<ResponseList<null>>(`${this.base}/${url}`, this.headers);

  public put = (url: string, payload: Partial<T>) =>
    this.http.put<void>(`${this.base}/${url}`, payload, this.headers);

  public get = (url: string) =>
    this.http.get<ResponseOne<T>>(`${this.base}/${url}`, this.headers);

  public patch = (url: string, payload: any) =>
    this.http.patch<ResponseList<null>>(
      `${this.base}/${url}`,
      payload,
      this.headers
    );

  //////////////////////////////////////////////////////////////////////////////

  public list = (query: ListQuery): Observable<ResponseList<T>> =>
    this.post(`${this.resourceBaseUrl}/list`, query);

  public update = (id: string, payload: UpdatePayload<T>): Observable<void> =>
    this.put(`${this.resourceBaseUrl}/${id}`, payload);

  public getById = (
    id: string,
    fromCache: boolean = false
  ): Observable<ResponseOne<T>> => {
    if (!fromCache) {
      return this.get(`${this.resourceBaseUrl}/${id}`);
    } else {
      let data$ = this.cacheService.getValue(id);

      if (!data$) {
        data$ = this.get(`${this.resourceBaseUrl}/${id}`).pipe(shareReplay(1));
        this.cacheService.setValue(data$, id);
      }
      return data$;
    }
  };

  public deleteById = (id: string) =>
    this.delete(`${this.resourceBaseUrl}/${id}`);

  //////////////////////////////////////////////////////////////////////////////

  /**
   * Get Asset file and return as blob
   *
   * @param id asset ID
   * @returns
   */
  public getAssetFile = (id: string): Observable<Blob> =>
    this.http.get('/api/v2/asset/get-file/' + id, {
      responseType: 'blob',
    });

  /**
   * A helper function to convert Blob to Base64 string. The return value is observable.
   *
   * @param blob
   * @returns
   */
  protected convertBlobToBase64(blob: Blob): Observable<string> {
    const reader = new FileReader();
    const obs$ = new Observable((subscriber: Observer<string>) => {
      reader.onload = () => {
        subscriber.next(reader.result as string);
        subscriber.complete();
      };
      reader.readAsDataURL(blob);
    });
    return obs$;
  }

  public getAssetPublicUrl = (id: string): Observable<string> =>
    this.getAssetFile(id).pipe(map((blob) => URL.createObjectURL(blob)));

  // Recursive method to fetch all data with a query payload
  public getAllDataList(queryPayload: ListQuery): Promise<T[]> {
    const {
      pageNo,
      pageSize = this.pageSize,
      ...restQueryParams
    } = queryPayload;

    // check if pageNo is valid
    if (typeof pageNo !== 'number' || pageNo < 1) {
      return Promise.reject(new Error('Invalid pageNo'));
    }

    return this.list({
      pageNo,
      pageSize,
      ...restQueryParams, // Merge any additional query parameters
    })
      .toPromise()
      .then((res: ResponseList<T>) => {
        if (res.code !== 200) {
          return null;
        }
        const data = res.result.list;
        const totalRecords = res.result.totalRecords;

        if (data.length === 0 || pageNo * pageSize >= totalRecords) {
          // All data has been fetched or reached the end
          return data;
        } else {
          // Fetch the next page of data
          return this.getAllDataList({
            ...queryPayload,
            pageNo: pageNo + 1,
          })
            .then((dataRes) =>
              // Concatenate the current and next page of data
              data.concat(dataRes)
            )
            .catch((err) => Promise.reject(err));
        }
      })
      .catch((err) => Promise.reject(err));
  }

  /**
   * A helper function to get base64 string from asset ID.
   *
   * @param arr
   * @param sourceKey example: 'media.image_id'
   * @param targetKey example: 'media.image_url'
   * @returns
   */
  public mapBase64AssetToArray = (
    arr: T[],
    sourceKey: string,
    targetKey: string
  ): Observable<T[]> =>
    forkJoin(
      arr.map((item) =>
        this.getAssetFile(get(item, sourceKey)).pipe(
          // the API will return a blob, so we need to convert it to base64 string
          switchMap((blob) =>
            this.convertBlobToBase64(blob).pipe(
              map((base64) => {
                _.set<any>(item, targetKey, base64);
                return item;
              })
            )
          )
        )
      )
    );
}

type UpdatePayload<T> = Partial<T> & {
  action?: string;
};
