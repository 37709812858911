import { Overlay } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { DialogCommonDialogComponent } from 'app/shared/ui-components/common-dialog/common-dialog.component';
import { ApiMonitor } from './api-monitor.service';
import { Response } from 'app/modules/monitor/monitor.model';
import { SnackbarService } from './snackbarService';
import moment from 'moment';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})

/// Utils class is for any utility functions
export class Utils {
  constructor(
    private _dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private _apiMonitor: ApiMonitor,
    private snackBar: SnackbarService,
    public overlay: Overlay,
    private _translocoService: TranslocoService
  ) {}

  // convert title with any case to title case, eg. "my title" -> "My Title"
  static getTitleCase(title: string): string {
    const splitString = title.toLowerCase().split(' ');
    for (let i = 0; i < splitString.length; i++) {
      splitString[i] =
        splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
    }
    return splitString.join(' ');
  }

  // delete dialog confirmation
  public openDialogConfirmation(
    title: string,
    type: string,
    deleteCallback: (response: boolean) => void
  ): void {
    this._dialog
      .open(DialogCommonDialogComponent, {
        data: {
          title: title,
          type: type,
        },
        panelClass: 'common-dialog',
        scrollStrategy: this.overlay.scrollStrategies.noop(),
      })
      .afterClosed()
      .subscribe((response) => {
        deleteCallback(response);
      });
  }

  public getTranslation(name: string, moduleName?: string): string {
    if (!name || (!name && !moduleName)) {
      return '-';
    }

    if (!moduleName) {
      return this._translocoService.translate(name);
    }

    return this._translocoService.translate(
      moduleName + '.' + name.toUpperCase()
    );
  }

  /**
   * a function to check if a string is a valid UUID and will return true or false
   *
   * @param input
   * @returns
   */
  public checkIfValidUUID(input: string): boolean {
    // Regular expression to check if string is a valid UUID
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(input);
  }

  public divideIntoChunks(n: number, chunkSize: number): number[] {
    const chunks: number[] = [];
    let remaining = n;

    while (remaining > 0) {
      if (remaining >= chunkSize) {
        chunks.push(chunkSize);
        remaining -= chunkSize;
      } else {
        chunks.push(remaining);
        remaining = 0;
      }
    }

    return chunks;
  }

  public sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public setCookie(token: string): void {
    const domainName = document.domain;
    // Calculate the expiry date as base on value on environment
    const expiryDate = moment()
      .add(environment.vcTokenExpiry, 'minutes')
      .toDate();
    // Convert the expiry date to the UTC string format
    const expires = expiryDate.toUTCString();
    document.cookie = `vc_token=${token};domain=${domainName};expires=${expires};path=/`;
  }

  public getVideoAccessToken(retry: number = 1): void {
    const limitCount = 3;
    const throttleTimeInMs = 1000;
    if (retry > limitCount) {
      this.snackBar.openSnackBar({
        message: 'Failed to get video access token, contact administrator.',
        type: 'failed',
      });
      return;
    }
    this._apiMonitor.getVideoAccessToken().subscribe(
      (response: Response) => {
        if (response.code === 200) {
          if (response.result) {
            this.setCookie(response.result as string);
          } else {
            setTimeout(() => {
              this.getVideoAccessToken(retry + 1);
            }, throttleTimeInMs);
          }
        } else {
          this.snackBar.openSnackBar({
            message: `${response.message}`,
            type: 'failed',
          });
        }
      },
      (error) => {
        this.snackBar.openSnackBar({
          message: `${error.message}`,
          type: 'failed',
        });
        setTimeout(() => {
          this.getVideoAccessToken(retry + 1);
        }, throttleTimeInMs);
      }
    );
  }
}
