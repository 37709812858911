import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-rm-text-input',
  templateUrl: './rm-text-input.component.html',
  styleUrls: ['./rm-text-input.component.scss'],
})
export class RmTextInputComponent implements OnInit {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() formNameControl: FormControl = new FormControl();
  @Input() invalidMessage: string = '';
  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {}

  getErrorMessage() {
    if (this.formNameControl.hasError('required')) {
      return this.translocoService.translate(
        this.invalidMessage || 'This field is required.'
      );
    } else if (this.formNameControl.hasError('email')) {
      return this.translocoService.translate('Not a valid email');
    }
    return this.invalidMessage;
  }
}
