import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

interface Option {
  value: string | number;
  name: string | number;
}
@Component({
  selector: 'bulk-action-button',
  templateUrl: './bulk-action-button.component.html',
  styleUrls: ['./bulk-action-button.component.scss'],
})
export class BulkActionButtonComponent implements OnInit {
  @Input() placeholder: string = 'placeholder';
  @Input() options: Option[];
  @Input() isActive: boolean = false;

  @Output() optionSelected = new EventEmitter();

  selectedItem: string = '';

  isOpen: boolean = false;

  setActive(condition: boolean): void {
    this.isActive = condition;
  }

  ngOnInit(): void {}

  handleOpenState(condition: boolean): void {
    this.isOpen = !this.isOpen;
    if (!condition) {
      this.selectedItem = '';
    }
  }

  handleSelected(event): void {
    this.optionSelected.emit(event);
  }
}
