import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../switch-language/language.service';

export interface BreadcrumbItem {
  id: string;
  name: string;
  icon?: string;
  url?: string;
}

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'breadcrumb',
})
export class BreadcrumbComponent implements OnDestroy, OnChanges {
  @Input() breadcrumb: (string | BreadcrumbItem | BreadcrumbItem[])[];

  protected _breadcrumb: BreadcrumbItem[] = [];
  protected breadcrumpDropdown?: BreadcrumbItem[] = null;
  protected selectedDropdown?: BreadcrumbItem;

  // to save the first time selected dropdown value
  dropdownInit: BreadcrumbItem;

  constructor(private router: Router, private language: LanguageService) {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        const currentLocation = window.location.href;
        if (currentLocation.includes('teleop')) {
          return false;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // format the structure of the breadcrumb and store it in _breadcrumb
    if (changes && changes.breadcrumb && changes.breadcrumb.currentValue) {
      const breadcrumbs = changes.breadcrumb.currentValue as (
        | string
        | BreadcrumbItem
      )[];

      this._breadcrumb = breadcrumbs
        .filter((item) => !Array.isArray(item))
        .map((item) => {
          if (typeof item === 'string') {
            return {
              id: item,
              name: item,
            };
          }
          return item;
        });
      // generate the URL for every breadcumb object
      this._breadcrumb = this._breadcrumb.map((item) => this.generateUrl(item));

      // if the last item in the breadcrumb is an array, it means it's a dropdown
      const lastItem = this.breadcrumb[this.breadcrumb.length - 1];
      this.breadcrumpDropdown = Array.isArray(lastItem) ? lastItem : null;
    }

    // check the selected dropdown from the current URL
    if (this.breadcrumpDropdown) {
      const index = this.breadcrumpDropdown.findIndex((item) =>
        this.router.url.split('/').includes(item.id)
      );
      this.selectedDropdown =
        index > -1 ? this.breadcrumpDropdown[index] : null;
      this.dropdownInit = this.selectedDropdown;
    }
  }

  ngOnDestroy(): void {}

  // a handler function to navigate to a path
  protected navigateTo(path: BreadcrumbItem): Promise<boolean> {
    if (path && path.id && path.id.toLocaleLowerCase() === 'monitoring') {
      const url = localStorage.getItem('lst_page');
      return this.router.navigate([url]);
    }
    return this.router.navigate([path.url]);
  }

  protected changeDropdown(current: BreadcrumbItem): void {
    const currentLocation = window.location.href;
    this.router.navigate([]).then(() => {
      if (currentLocation.includes('teleop')) {
        window.open(`/robots/teleoperations/${current.id}`, '_blank');
      } else if (currentLocation.includes('monitor')) {
        window.open(current.url, '_blank');
      }
    });

    // give delay to change the selected dropdown value
    setTimeout(() => {
      this.selectedDropdown = this.dropdownInit;
    }, 100);
  }

  private generateUrl(path: BreadcrumbItem): BreadcrumbItem {
    if (path.url) {
      return path;
    }

    if (path.id.toLowerCase() === 'home') {
      return { ...path, url: '/dashboard' };
    }

    // If there's no url, or it's not a home, form a path to navigate to.
    // by iterating the _breadcrumb. Break when path is found.
    // example: /settings/skills
    let pathString = '';
    for (const breadcrumbItem of this._breadcrumb) {
      // skip 'home'
      if (breadcrumbItem.id.toLowerCase() === 'home') {
        continue;
      }
      pathString += `/${breadcrumbItem.id}`.toLowerCase();
      if (breadcrumbItem.id === path.id) {
        break;
      }
    }
    return { ...path, url: pathString };
  }
}
