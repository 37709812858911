import { MapDialogComponent } from './dialog-map/dialog-data-map.dialog';
import { MatDialog } from '@angular/material/dialog';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { LayoutsService } from 'app/modules/settings/layouts/layouts.service';
import { LeafletService } from 'app/modules/settings/layouts/services/leaflet.service';
import { CRS, LatLng, Map, PointExpression } from 'leaflet';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { PointRegion, Region } from 'app/services/api.types';

@Component({
  selector: 'rm-mini-map',
  styles: [
    `
      :host ::ng-deep .leaflet-control-zoom-in,
      :host ::ng-deep .leaflet-control-zoom-out {
        font-size: 15px;
      }
      :host ::ng-deep .leaflet-touch .leaflet-bar a {
        width: 20px;
        height: 20px;
        line-height: 20px;
        opacity: 0.6;
      }
    `,
  ],
  template: `
    <div class="map-container w-full  {{ classContainerHeight }}">
      <div
        class="map"
        leaflet
        [leafletOptions]="options"
        (leafletMapReady)="onMapReady($event)"
      ></div>
      <button
        (click)="openDialog()"
        style="
                padding: 5px 5px;
                position: absolute;
                right: 5px;
                z-index: 400;
              "
      >
        <mat-icon>fullscreen</mat-icon>
      </button>
    </div>
  `,
})
export class RmMiniMapComponent implements OnInit, OnDestroy, OnChanges {
  @Input() eventName?: string;
  @Input() layoutId: string;
  @Input() eventCoordinate?: PointExpression;
  @Input() zone?: Region;
  @Input() layoutName?: string;
  @Input() center?: PointExpression;
  @Input() type: 'event' | 'va-zone';
  @Input() classContainerHeight: string = 'h-60';

  mapLeaflet: Map;
  options = {
    attributionControl: false,
    center: [0, 0],
    crs: CRS.Simple,
    maxBoundsViscosity: 0.9,
    maxZoom: 4,
    minZoom: 1,
    scrollWheelZoom: true,
    doubleClickZoom: true,
    zoomControl: true,
    zoom: 1,
  };

  private coordinateZone: PointRegion[];
  private centerZone: LatLng;

  constructor(
    private layoutsService: LayoutsService,
    private leafletService: LeafletService,
    public createDialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.type === 'event') {
      this.eventCoordinate = [this.eventCoordinate[0], this.eventCoordinate[1]];

      if (this.center === undefined) {
        this.center = this.eventCoordinate;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.zone) {
      this.loadZones(changes.zone.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.leafletService.clear();
  }

  onMapReady(map: Map): void {
    // Load the layout image of the event
    this.mapLeaflet = map;
    this.getLayoutImage();
    this.leafletService.initMap(this.mapLeaflet, {
      center: this.center,
      isShowLabels: true,
    });
  }

  private loadZones(arrZone: Region): void {
    this.leafletService.clear();
    if (arrZone) {
      this.coordinateZone = arrZone.coordinates[0];
      const data = {
        ...arrZone,
        color: arrZone.metadata.color,
        coordinates: this.coordinateZone,
      };
      console.log('rendering zone', data);

      // get the center of the zone and render it
      this.centerZone = this.leafletService
        .renderZone(data, {})
        .getBounds()
        .getCenter();

      // center the map to the zone
      this.mapLeaflet.setView(this.centerZone, 1);
    }
  }

  private getLayoutImage(): void {
    this.layoutsService
      .getLayoutImageByID(this.layoutId)
      .subscribe((response) => {
        this.leafletService.loadMapImage(
          response.url,
          response.width,
          response.height,
          this.type === 'event' ? true : false
        );
        if (this.eventCoordinate && this.eventName && this.type === 'event') {
          this.leafletService.addEventFlag(
            this.eventName,
            this.eventCoordinate
          );
        }
      });
  }

  openDialog(): void {
    this.createDialog.open(MapDialogComponent, {
      width: '100%',
      height: 'auto',
      data: {
        name: this.type === 'event' ? this.eventName : this.zone.name,
        layoutId: this.layoutId,
        layoutName: this.layoutName,
        coordinate:
          this.type === 'event' ? this.eventCoordinate : this.coordinateZone,
        center: this.center
          ? this.center
          : this.leafletService.latlngToXy(this.centerZone),
        type: this.type,
        metadata: this.zone?.metadata?.color || undefined,
      },
      scrollStrategy: new NoopScrollStrategy(),
      disableClose: true,
    });
  }
}
