import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { MatIconModule } from '@angular/material/icon';
import { TextSofiaModule } from 'app/shared/ui-components/text-sofia/text-sofia.module';
import { MatButtonModule } from '@angular/material/button';
import { DirectiveModule } from 'app/shared/directives/directive.module';

import { EventCardComponent } from './event-card.component';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
  declarations: [EventCardComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    MatIconModule,
    TextSofiaModule,
    MatButtonModule,
    DirectiveModule,
    TranslocoRootModule,
  ],
  exports: [EventCardComponent],
})
export class EventCardModule {}
