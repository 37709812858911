import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import { IParam, IResponse } from './robots.model';
import {
  SKILL_NAME,
  ILocation,
  CallRobot,
} from './teleoperations/teleoperation.config';
import _ from 'lodash';
import { ResponseList } from 'app/services/api.types';

@Injectable({
  providedIn: 'root',
})
export class RobotsService {
  private headers = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  // robotLocation = new Subject<ILocation>();
  incomingCall$: BehaviorSubject<CallRobot> = new BehaviorSubject(null);
  hideIncomingCall: BehaviorSubject<CallRobot> = new BehaviorSubject(null);
  // headerAnswer: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private _http: HttpClient) {}

  getData(param: IParam): Observable<IResponse> {
    return this._http.post<IResponse>('api/v2/robot/list', param, this.headers);
  }

  getDataByID(id: string): Observable<IResponse> {
    return this._http.get<IResponse>(`api/v2/robot/${id}`, this.headers);
  }

  deleteDataByID(id: string): Observable<IResponse> {
    return this._http.delete<IResponse>(`/api/v2/robot/${id}`, this.headers);
  }

  getRobotListtoTeleop(data) {
    return this._http.post<IResponse>(
      '/api/v2/robot/list-with-skill',
      data,
      this.headers
    );
  }

  getRobotSkills(id) {
    return this._http.get<IResponse>(
      `/api/v2/robot-skills/get-by-robot/${id}`,
      this.headers
    );
  }

  getMissionBy(id: string) {
    return this._http.get<IResponse>(`/api/v2/mission/${id}`, this.headers);
  }

  getRobotMissionList(id: string, page: number, size: number) {
    return this._http.post<IResponse>(
      '/api/v2/mission/list-join-robot',
      {
        pageNo: page,
        pageSize: size,
        filter: [
          {
            name: 'robotId',
            column: 'robot_missions.robot_id',
            operator: 'eq',
            value: [id],
            extra: '',
            dataType: 'text',
            virtual: false,
          },
          //    {
          //         "name": "Status",
          //         "column": "missions.status",
          //         "operator": "in",
          //         "value": ["3", "4", "5"],
          //         "extra": "data_dict('MissionStatus')",
          //         "dataType": "enum",
          //         "virtual": false
          //     },
        ],
        order: [
          {
            name: 'Update Time',
            column: 'updated_at',
            type: 'desc',
          },
        ],
      },
      this.headers
    );
  }

  getRobotEventList(id: string, page: number, size: number) {
    return this._http.post<IResponse>(
      '/api/v2/events/list',
      {
        pageNo: page,
        pageSize: size,
        filter: [
          {
            name: 'robotId',
            column: 'events.robot_id',
            operator: 'eq',
            value: [id],
            extra: '',
            dataType: 'text',
            virtual: false,
          },
        ],
        order: [
          {
            name: 'createdTime',
            column: 'events.Created_at',
            type: 'desc',
          },
        ],
      },
      this.headers
    );
  }

  getRobotLogHistoryList(id: string, page: number, size: number) {
    return this._http.post<IResponse>(
      '/api/v2/robotlog/list',
      {
        pageNo: page,
        pageSize: size,
        filter: [
          {
            name: 'robotId',
            column: 'robot_id',
            operator: 'eq',
            value: [id],
            extra: '',
            dataType: 'text',
            virtual: false,
          },
        ],
        order: [
          {
            name: 'timestamp',
            // column: 'Created_at',
            column: 'timestamp',
            type: 'desc',
          },
        ],
      },
      this.headers
    );
  }

  teleoperationValidity(id, callback) {
    this.getRobotSkills(id).subscribe((response: IResponse) => {
      if (response.code === 200 || response.code === 201) {
        if (response.result.length > 0) {
          if (
            _.findLastIndex(response.result, function (o: any) {
              return o.name.includes(SKILL_NAME);
            }) > -1
          ) {
            callback(true);
          } else {
            callback(false);
          }
        } else {
          callback(false);
        }
      } else {
        callback(false);
      }
    });
  }

  getCamerabyRobot(id) {
    return this._http.get(`/api/v2/camera/list/${id}`, this.headers);
  }

  isJSONString(value: string) {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  }

  getPTZByRobot(id) {
    return this._http.get(`/api/v2/camera/list/${id}`, this.headers);
  }

  getBroadcastFiles(data) {
    return this._http.post('/api/v2/asset/list', data, this.headers);
  }

  getAudioFile(id) {
    return this._http.get(`api/v2/asset/get-file/${id}`, this.headers);
  }

  getInActionMission(id) {
    return this._http.post(
      `/api/v2/mission/action-mission?robotIds=${id}`,
      '',
      this.headers
    );
  }

  getAssetFile(id) {
    return this._http.get('/api/v2/asset/get-file/' + id, {
      responseType: 'blob',
    });
  }

  updateMission(data) {
    return this._http.put('/api/v2/mission/update', data, this.headers);
  }

  updateEvent(data) {
    return this._http.put('/api/v2/events', data, this.headers);
  }

  getTeleopsSkills(payload) {
    return this._http.post('/api/v2/skill/list', payload, this.headers);
  }

  getConfig() {
    return this._http.get('/assets/config/teleoperations.json');
  }

  // Recursive method to fetch all data with a query payload
  public getAllTeleopsSkills(queryPayload): Promise<any[]> {
    const { pageNo, pageSize = 50, ...restQueryParams } = queryPayload;

    // check if pageNo is valid
    if (typeof pageNo !== 'number' || pageNo < 1) {
      return Promise.reject(new Error('Invalid pageNo'));
    }

    return this.getTeleopsSkills({
      pageNo,
      pageSize,
      ...restQueryParams, // Merge any additional query parameters
    })
      .toPromise()
      .then((res: ResponseList<any>) => {
        const data = res.result.list;
        const totalRecords = res.result.list.length;

        if (data.length === 0 || pageNo * pageSize >= totalRecords) {
          // All data has been fetched or reached the end
          return data;
        } else {
          // Fetch the next page of data
          return this.getAllTeleopsSkills({
            ...queryPayload,
            pageNo: pageNo + 1,
          })
            .then((dataRes) =>
              // Concatenate the current and next page of data
              data.concat(dataRes)
            )
            .catch((err) => Promise.reject(err));
        }
      })
      .catch((err) => Promise.reject(err));
  }
}
