import { TranslocoService } from '@ngneat/transloco';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from 'app/services/utils';
import { Subject } from 'rxjs';

export interface DialogData {
  title: string;
  type: string;
}

@Component({
  selector: 'dialog-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss'],
})
export class DialogCommonDialogComponent {
  title = '';
  secondaryButtonText = '';
  primaryButtonText = '';
  icon = '../../../../assets/images/monitoring/quick-mission/alert.svg';
  style: STYLE;
  public subject: Subject<boolean>;

  constructor(
    private utils: Utils,
    public dialogRef: MatDialogRef<DialogCommonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.title = data['title'];
    if (data['type'] === 'delete') {
      this.secondaryButtonText = this.utils.getTranslation(
        'NO_RETURN',
        'COMMON'
      );
      this.primaryButtonText = this.utils.getTranslation('YES', 'COMMON');
    } else if (data['type'] === 'confirm') {
      this.secondaryButtonText = this.utils.getTranslation(
        'NO_RETURN',
        'COMMON'
      );
      this.primaryButtonText = this.utils.getTranslation('YES', 'COMMON');
      this.style = {
        width: '382px',
        height: '102px',
      };
    } else {
      this.secondaryButtonText = utils.getTranslation('NO_DISCARD', 'COMMON');
      this.primaryButtonText = utils.getTranslation('SAVE', 'COMMON');
    }
  }

  onCancel(): void {
    if (this.subject) {
      this.subject.next(false);
      this.subject.complete();
    }
    this.dialogRef.close(false);
  }

  onSave(): void {
    if (this.subject) {
      this.subject.next(true);
      this.subject.complete();
    }
    this.dialogRef.close(true);
  }
}

export interface STYLE {
  width: string;
  height: string;
}
