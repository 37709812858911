import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'avatar',
  template: `
    <div class="profileClass mr-3">
      <span>
        <img class="main-header-icon" [src]="srcUrl" />
      </span>
    </div>
  `,
  styles: [
    `
      .profileClass {
        width: 35px;
        height: 35px;
        background: #083a45;
        border-radius: 100%;
        padding: 8px;
        margin-left: 12px;

        .main-header-icon {
          object-fit: cover;
          height: 21px;
          width: 21px;
          margin: 0px;
          border-radius: 100%;
        }
      }
    `,
  ],
})
export class AvatarComponent {
  @Input() srcUrl: string;
}
