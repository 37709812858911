import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RmDatatableComponent } from './rm-datatable.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
  declarations: [RmDatatableComponent],
  imports: [
    CommonModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule,
    TranslocoRootModule,
  ],
  exports: [RmDatatableComponent],
})
export class RmDatatableModule {}
