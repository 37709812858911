import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { GroupLiftCardComponent } from './group-lift-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { ActionButtonModule } from '../action-button/action-button.module';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [GroupLiftCardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    TranslocoRootModule,
    ActionButtonModule,
    RouterModule,
  ],
  exports: [GroupLiftCardComponent],
})
export class GroupLiftCardModule {}
