import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBase } from './api-base.service';
import { ApiUser } from './api-user.service';
import { ConfigData } from './api.types';
@Injectable({
  providedIn: 'root',
})
export class ApiConfigService extends ApiBase<ConfigData> {
  constructor(http: HttpClient, public apiUser: ApiUser) {
    super(http);
  }

  getConfig() {
    return this.http.get('/assets/config/config.json');
  }
}
