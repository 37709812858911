import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
})
export class SlideToggleComponent implements OnInit {
  @Output() clickEvent = new EventEmitter();
  // @Input() data: string;
  @Input() isChecked: boolean;
  @Input() disabled;
  @Output() onChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  // toggleExpand(): void {
  //     this.isChecked = !this.isChecked;

  //     this.clickEvent.emit(this.isChecked);
  // }

  handleChange(event): void {
    this.onChange.emit(event);
  }
}
