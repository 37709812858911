import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RightSidebarLayoutService } from './right-sidebar-layout.service';

@Component({
  selector: 'right-sidebar-layout',
  host: {
    '[class.right-sidebar-opened]': '_opened',
  },
  templateUrl: './right-sidebar-layout.component.html',
  styleUrls: ['./right-sidebar-layout.component.scss'],
})
export class RightSidebarLayoutComponent implements OnInit {
  @Input() sidebarHeader: string;
  @Input() sidebarHeight: string;
  @Input() iconName: string;
  @Input() iconUrl: string;
  @Input() hideButton: boolean = false;
  @Output() onClose = new EventEmitter();

  _opened = false;

  constructor(private sidebarService: RightSidebarLayoutService) {}

  ngOnInit(): void {
    this._opened = false;
  }

  _toggleSidebar(): void {
    this._opened = !this._opened;
    this.sidebarService.toggleSidebar$.next(this._opened);
  }

  _open(): void {
    this._opened = true;
  }

  handleClose(): void {
    this._toggleSidebar();
    this.onClose.emit();
  }
}
