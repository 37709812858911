import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { User } from 'app/core/user/user.model';
import { RESPONSE, UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';
import { get } from 'lodash';
import { ApiUser } from 'app/services/api-user.service';
import { ClipboardService } from 'ngx-clipboard';
import { SnackbarService } from 'app/services/snackbarService';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'userMenu',
})
export class UserMenuComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = false;
  user: User;
  moduleName: string = 'header';
  profilePicture: string | ArrayBuffer;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public appVersion: string = get(environment, 'appVersion', '');
  versionFlag = false;
  hideLogout = false;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    private _authService: AuthService,
    private apiUser: ApiUser,
    private clipboardApi: ClipboardService,
    public snackBar: SnackbarService,
    private translocoService: TranslocoService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Find user allowed logout
    this.getLogoutFlag();

    // Subscribe to user changes
    this._userService.getLoginInfo().subscribe((data: RESPONSE) => {
      if (data.code === 200) {
        this.user = data.result;
        localStorage.setItem('cuid', this.user.userId);
        // to-do Cannot get picture by call API
        if (this.user.profilePicture) {
          this.getFile(this.user.profilePicture);
        }
        this._changeDetectorRef.detectChanges();
      }
    });

    // this._userService.user$
    //     .pipe(takeUntil(this._unsubscribeAll))
    //     .subscribe((user: User) => {
    //         this.user = user;
    //         // Mark for check
    //         this._changeDetectorRef.markForCheck();
    //     });

    this._userService.getVersion().subscribe((data) => {
      if (data) {
        this.versionFlag = true;
        this.appVersion = data['version'];
      }
    });
  }
  loadImage(url: string) {
    return new Promise((res, rej) => {
      const image = new Image();
      image.src = url;
      image.onload = () => res(image);
      image.onerror = () => rej();
    });
  }

  private getFile(name: string) {
    this.apiUser.getAssetFile(name).subscribe((data) => {
      this.createImageFromBlob(data);
    });
  }
  private createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.profilePicture = reader.result;
        this.showAvatar = true;
        this._changeDetectorRef.detectChanges();
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  // updateUserStatus(status: string): void {
  //     // Return if user is not available
  //     if (!this.user) {
  //         return;
  //     }

  //     // Update the user
  //     this._userService.update({
  //         ...this.user,
  //         status
  //     }).subscribe();
  // }

  /**
   * Sign out
   */
  signOut(): void {
    this._authService.signOut().subscribe((result) => {
      this._router.navigate(['/sign-out']);
    });
  }
  /**
   * Profile
   */
  goToProfile() {
    this._router.navigate(['/profile']);
  }

  copyVersion() {
    console.log(this.appVersion);
    this.clipboardApi.copyFromContent(this.appVersion);
    this.snackBar.openSnackBar({
      message: this.translocoService.translate(
        'Application version copied to clipboard'
      ),
      type: 'success',
    });
  }

  /**
   * find weather the user allowed to lgout
   */
  getLogoutFlag() {
    this._authService.getConfig().subscribe(
      (data: any) => {
        if (data?.hideLogoutButton) {
          this.hideLogout = data?.hideLogoutButton;
        }
      },
      (err) => {
        console.log('getConfig subscribe err ', err);
      }
    );
  }
}
