import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { LayoutsService } from 'app/modules/settings/layouts/layouts.service';
import { LeafletService } from 'app/modules/settings/layouts/services/leaflet.service';
import { MARKER_TYPE } from 'app/modules/settings/layouts/utilities/marker-constanta';
import { ApiLayoutMarker } from 'app/services/api-layout-marker.service';
import { LayoutMarker, LayoutSensor, LiftFloor } from 'app/services/api.types';
import { SnackbarService } from 'app/services/snackbarService';
import { Utils } from 'app/services/utils';
import { createUuid } from 'app/services/uuid.utils';
import {
  CRS,
  LeafletEvent,
  LeafletEventHandlerFn,
  LeafletMouseEvent,
  Map,
  Point,
  PolylineOptions,
} from 'leaflet';
import { get, cloneDeep } from 'lodash';

interface Coordinate {
  id?: string;
  name?: string;
  x: number;
  y: number;
  z: number;
}

interface ImageSize {
  width: number;
  height: number;
}

@Component({
  selector: 'mini-layout-editor',
  templateUrl: './mini-layout-editor.component.html',
  styleUrls: ['./mini-layout-editor.component.scss'],
})
export class MiniLayoutEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() layoutId: string;
  @Input() robotMapId: string;
  @Input() showLayoutMarkers: boolean = false;
  @Input() layoutSensorName: string;
  @Input() floorName: string;
  private _floorNameWaitingPoint: string;
  private _floorNameTransitPoint: string;
  private _floorNameExitPoint: string;
  @Input() coordinate: Coordinate;
  @Input() coordinateWaitingPoint: Coordinate;
  @Input() coordinateExitPoint: Coordinate;
  @Input() coordinateTransitPoint: Coordinate;
  @Input() showEyeIcon: boolean = true;
  @Input() selectedMarkers: string[] = [];

  @Output() getCoordinate: EventEmitter<Point> = new EventEmitter<Point>();
  @Output() getCoordinateWaitingPoint: EventEmitter<Point> =
    new EventEmitter<Point>();
  @Output() getCoordinateExitPoint: EventEmitter<Point> =
    new EventEmitter<Point>();
  @Output() getCoordinateTransitPoint: EventEmitter<Point> =
    new EventEmitter<Point>();
  @Output() getImageSize: EventEmitter<ImageSize> =
    new EventEmitter<ImageSize>();
  @Output() getRobotMapMarkerPosition: EventEmitter<{
    id: string;
    position: Coordinate;
  }> = new EventEmitter<{ id: string; position: Coordinate }>();
  @Output() clickedMarker: EventEmitter<LayoutMarker> =
    new EventEmitter<LayoutMarker>();

  public map: Map;
  public options = {
    attributionControl: false,
    center: [0, 0],
    crs: CRS.Simple,
    maxBoundsViscosity: 1,
    maxZoom: 5,
    minZoom: -2,
    scrollWheelZoom: true,
    doubleClickZoom: false,
    zoomControl: false,
    zoom: 1,
    zoomSnap: 0.5,
  };
  public mapState: 'none' | 'add-marker' | 'add-lift-marker' = 'none';
  public markers: LayoutMarker[] = [];
  public filteredMarkers: LayoutMarker[] = [];
  public sensors: LayoutSensor[] = [];
  public floors: LiftFloor[] = [];
  public isFloorMarkerCreated = false;
  public selectedSensor: LayoutSensor;
  public isMarkerShowed: boolean = true;
  public xCoordinate: number;
  public yCoordinate: number;
  private imagesSize: ImageSize;

  constructor(
    private layoutsService: LayoutsService,
    private leafletService: LeafletService,
    private apiLayoutMarker: ApiLayoutMarker,
    private changeDetectorRefs: ChangeDetectorRef,
    public snackBar: SnackbarService,
    private utils: Utils
  ) {}

  ngOnInit(): void {
    //if this.floorName, then DONT run updateMap, updatemap for floor already call in link-area create
    if (this.layoutId && !this.robotMapId && !this.floorName) {
      this.updateMap();
    }

    if (this.robotMapId) {
      this.updateRobotMap();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedMarkers) {
      // delete all markers
      this.markers.forEach((marker) =>
        this.leafletService.removeMarker(marker.id)
      );
      this.filteredMarkers = this.markers.filter((marker) =>
        this.selectedMarkers.includes(marker.id)
      );

      this.leafletService.renderLayoutMarkers(this.filteredMarkers, {
        draggable: false,
      });
    }

    if (changes.showLayoutMarkers) {
      // show or hide layout markers
      this.toggleLayoutMarkers();
    }

    if (changes.floorNameTransitPoint) {
      this.updateFloorMarkerName(MARKER_TYPE.transitPoint);
    }
    if (changes.floorNameWaitingPoint) {
      this.updateFloorMarkerName(MARKER_TYPE.waitingPoint);
    }

    if (changes.floorNameExitPoint) {
      this.updateFloorMarkerName(MARKER_TYPE.exitPoint);
    }
    if (changes.layoutId) {
      if (this.layoutId) {
        this.isFloorMarkerCreated = false;
        this.updateMap();
      }
    }
  }

  @Input() set floorNameTransitPoint(value: string) {
    this._floorNameTransitPoint = value;
  }

  get floorNameTransitPoint(): string {
    return this._floorNameTransitPoint;
  }
  @Input() set floorNameWaitingPoint(value: string) {
    this._floorNameWaitingPoint = value;
  }

  get floorNameWaitingPoint(): string {
    return this._floorNameWaitingPoint;
  }

  @Input() set floorNameExitPoint(value: string) {
    this._floorNameExitPoint = value;
  }

  get floorNameExitPoint(): string {
    return this._floorNameExitPoint;
  }

  public ngOnDestroy(): void {
    if (
      this.leafletService.map !== undefined &&
      this.leafletService.map !== null
    ) {
      this.leafletService.reset();
      this.leafletService.clear();
    }
  }

  /**
   * Update the map after init.
   * Projecting the image overlay in map and add the existing markers into it
   */
  public updateMap(): void {
    setTimeout(() => {
      this.leafletService.map.eachLayer((layer) => {
        this.leafletService.map.removeLayer(layer);
      });
      this.leafletService.resetMarkerGroup();
      this.leafletService.resetSensorGroup();
      this.leafletService.resetFloorGroup();
      // remove markers from map when change layout map
      this.markers.forEach((marker) => {
        this.leafletService.removeMarker(marker.id);
      });
    }, 200);

    setTimeout(() => {
      // load layout image
      this.layoutsService
        .getLayoutImageByID(this.layoutId)
        .subscribe((response) => {
          this.leafletService.loadMapImage(
            response.url,
            response.width,
            response.height
          );
          this.leafletService.setGridImage('/assets/icons/layout-grid.png');

          this.imagesSize = {
            width: response.width,
            height: response.height,
          };
          console.log('imagesSize2', this.imagesSize);
          // Emit the size of the image
          this.getImageSize.emit(this.imagesSize);
          if (this.layoutSensorName) {
            // create initial sensor marker
            this.createSensorMarker(response.width, response.height);
          }

          if (this.floorName) {
            // create initial floor marker
            // this.createfloorMarker(response.width, response.height);
            this.createfloorMarker();
          }
        });

      // get layout markers, and add them to the map
      this.loadMarkersFromServer();
      // show or hide layout markers
      this.toggleLayoutMarkers();
      this.leafletService.clear();
    }, 300);
  }

  public updateFloorMarkerName(type: string): void {
    if (type === MARKER_TYPE.transitPoint) {
      const idTransitMarker = this.floors[0]?.transitPoint['id'] as string;
      this.leafletService.removeFloor(idTransitMarker, type);
    }

    if (type === MARKER_TYPE.waitingPoint) {
      const idWaitingMarker = this.floors[0]?.waitingPoint['id'] as string;
      this.leafletService.removeFloor(idWaitingMarker, type);
    }

    if (type === MARKER_TYPE.exitPoint) {
      const idExitMarker = this.floors[0]?.exitPoint['id'] as string;
      this.leafletService.removeFloor(idExitMarker, type);
    }

    // this.createfloorMarker(0, 0, type);
    // this.createfloorMarker(0, 0, 'create');
    this.createfloorMarker('create');
  }

  /**
   * Init the map in container
   *
   * @param leafletMap Leaflet Map
   */
  public initMap(leafletMap: Map): void {
    //to allow and send the event if user click the map
    leafletMap.on('click', this.onMapClick.bind(this));

    this.leafletService.initMap(leafletMap);
  }

  //fucntion to get the event if the map clicked
  //if the map click, create lift marker and chenge the map state and the isFloorMarkerCreated
  public onMapClick(e: LeafletMouseEvent): void {
    if (this.mapState === 'none') {
      return;
    }
    const isValid = this.leafletService.isValidCoordinate(e.latlng);
    if (!isValid) {
      return;
    }

    switch (this.mapState) {
      case 'add-lift-marker': {
        this.createLiftMarker(e);
        this.isFloorMarkerCreated = true;
        this.mapState = 'none';
        break;
      }
    }
  }

  //fucntion to create lift marker
  public createLiftMarker(e: LeafletMouseEvent): void {
    const xy = this.leafletService.latlngToXy(e.latlng);

    //creaete floor marker of transitMarker and waitingMarker
    // this.createfloorMarker(0, 0, 'create', xy);
    this.createfloorMarker('create', xy);

    //emit the coordinate of transit point
    this.getCoordinateTransitPoint.emit(xy);

    //emit the coordinate of waiting point
    const xyWaiting = cloneDeep(xy);
    xyWaiting.x = xyWaiting.x - 100;
    xyWaiting.y = xyWaiting.y + 100;
    const xyWaitingPoint = this.leafletService.xYtoLatlng(xyWaiting);
    if (!this.leafletService.isValidCoordinate(xyWaitingPoint)) {
      xyWaiting.x = this.imagesSize.width / 2;
      xyWaiting.y = this.imagesSize.height / 2;
    }
    this.getCoordinateWaitingPoint.emit(xyWaiting);

    //emit the coordinate of exit point
    const xyExit = cloneDeep(xy);
    xyExit.x = xyExit.x + 100;
    xyExit.y = xyExit.y + 100;
    const xyExitPoint = this.leafletService.xYtoLatlng(xyExit);
    if (!this.leafletService.isValidCoordinate(xyExitPoint)) {
      xyExit.x = this.imagesSize.width / 2;
      xyExit.y = this.imagesSize.height / 2;
    }
    this.getCoordinateExitPoint.emit(xyExit);
  }

  /**
   * Click to show or hide the layout markers
   */
  public onClickVisibility(): void {
    this.isMarkerShowed = !this.isMarkerShowed;
    this.leafletService.setShowMarkers(this.isMarkerShowed);
  }

  /**
   * Hide or show the layout markers
   */
  private toggleLayoutMarkers() {
    this.isMarkerShowed = this.showLayoutMarkers;
    this.leafletService.setShowMarkers(this.showLayoutMarkers);
  }

  /**
   * Load layout marker, then draw the markers in map
   */
  private loadMarkersFromServer(): void {
    this.apiLayoutMarker.listLayoutMarkers(this.layoutId).subscribe((resp) => {
      this.markers = resp.result;
      if (this.selectedMarkers.length > 0) {
        this.filteredMarkers = this.markers.filter((marker) =>
          this.selectedMarkers.includes(marker.id)
        );
        this.leafletService.renderLayoutMarkers(this.filteredMarkers, {
          draggable: false,
        });
        return;
      }

      this.leafletService.renderLayoutMarkers(this.markers, {
        opacity: 0.8,
        draggable: false,
        click: (e) => this.onMarkerClick(e),
      });
    });
  }

  /**
   * Place marker in map, initial in the center of the map
   *
   * @param imageOverlayWidth width of the image overlay in projected to map
   * @param imageOverlayHeight height of the image overlay in projected to map
   */
  private createSensorMarker = (
    imageOverlayWidth: number,
    imageOverlayHeight: number
  ): void => {
    let initCoordinate = { x: 0, y: 0, z: 0 };
    let x: number = 0;
    let y: number = 0;
    if (this.coordinate && this.coordinate.x === 0 && this.coordinate.y === 0) {
      x = imageOverlayWidth / 2;
      y = imageOverlayHeight / 2;
      initCoordinate = { x: x, y: y, z: initCoordinate.z };
    } else {
      initCoordinate = this.coordinate;
    }
    const newMarker = {
      id: createUuid(),
      layoutId: this.layoutId,
      name: this.layoutSensorName ?? 'Untitled Sensor',
      coordinate: initCoordinate,
    } as LayoutSensor;

    // add the newMarker to list of markers, and render it on leaflet
    this.sensors.push(newMarker);
    this.addMarkerToMap();
  };

  //create floor marker in the map
  private createfloorMarker = (
    type: string = 'create',
    coordinate?: Point
  ): void => {
    let xTransit: number = 0;
    let yTransit: number = 0;
    let xWaiting: number = 0;
    let yWaiting: number = 0;
    let xExit: number = 0;
    let yExit: number = 0;

    let transitMarker: Coordinate = {
      id: createUuid(),
      name: this._floorNameTransitPoint,
      x: 0,
      y: 0,
      z: 0,
    };

    let waitingMarker: Coordinate = {
      id: createUuid(),
      name: this._floorNameWaitingPoint,
      x: 0,
      y: 0,
      z: 0,
    };

    let exitMarker: Coordinate = {
      id: createUuid(),
      name: this._floorNameExitPoint,
      x: 0,
      y: 0,
      z: 0,
    };

    //if the coordinate is available create marker base on user clicked
    if (coordinate) {
      xTransit = coordinate.x;
      yTransit = coordinate.y;

      // xWaiting = xTransit - 100;
      // yWaiting = yTransit - 100;

      const coordinateWaitingPoint: Point = cloneDeep(coordinate);
      coordinateWaitingPoint.x = xTransit - 100;
      coordinateWaitingPoint.y = yTransit + 100;
      const xyWaitingPoint = this.leafletService.xYtoLatlng(
        coordinateWaitingPoint
      );
      // Check if the  Waiting Point marker generated outside layout preview image
      // eslint-disable-next-line
      const isValid = this.leafletService.isValidCoordinate(xyWaitingPoint);
      if (!isValid) {
        xWaiting = this.imagesSize.width / 2;
        yWaiting = this.imagesSize.height / 2;
      } else {
        xWaiting = coordinateWaitingPoint.x;
        yWaiting = coordinateWaitingPoint.y;
      }
      const coordinateExitPoint: Point = cloneDeep(coordinate);
      coordinateExitPoint.x = xTransit + 100;
      coordinateExitPoint.y = yTransit + 100;
      const xyExitPoint = this.leafletService.xYtoLatlng(coordinateExitPoint);
      // Check if the  Waiting Point marker generated outside layout preview image
      // eslint-disable-next-line
      if (!this.leafletService.isValidCoordinate(xyExitPoint)) {
        xExit = this.imagesSize.width / 2;
        yExit = this.imagesSize.height / 2;
      } else {
        xExit = coordinateExitPoint.x;
        yExit = coordinateExitPoint.y;
      }
      transitMarker = {
        id: createUuid(),
        name: this._floorNameTransitPoint,
        x: xTransit,
        y: yTransit,
        z: transitMarker.z,
      };
      waitingMarker = {
        id: createUuid(),
        name: this._floorNameWaitingPoint,
        x: xWaiting,
        y: yWaiting,
        z: waitingMarker.z,
      };
      exitMarker = {
        id: createUuid(),
        name: this._floorNameExitPoint,
        x: xExit,
        y: yExit,
        z: exitMarker.z,
      };
      //to check if the floor already created but user not click/add the markers
      //do nothing
    } else if (
      this.coordinateTransitPoint &&
      this.coordinateTransitPoint.x === 0 &&
      this.coordinateTransitPoint.y === 0 &&
      this.coordinateWaitingPoint &&
      this.coordinateWaitingPoint.x === 0 &&
      this.coordinateWaitingPoint.y === 0 &&
      this.coordinateExitPoint &&
      this.coordinateExitPoint.x === 0 &&
      this.coordinateExitPoint.y === 0
    ) {
      return;
    } else {
      this.isFloorMarkerCreated = true;
      transitMarker = { ...this.coordinateTransitPoint, id: createUuid() };
      waitingMarker = { ...this.coordinateWaitingPoint, id: createUuid() };
      exitMarker = { ...this.coordinateExitPoint, id: createUuid() };
    }

    const newMarker = {
      id: createUuid(),
      layoutId: this.layoutId,
      userLevel: this.floorName ? this.floorName : 'Untitled User Level',
      systemLevel: '0',
      transitPoint: transitMarker,
      waitingPoint: waitingMarker,
      exitPoint: exitMarker,
    } as LiftFloor;

    // add the newMarker to list of markers, and render it on leaflet
    // this.floors.push(newMarker);
    this.floors = [newMarker];
    if (type === 'create') {
      this.addMarkerToMap(MARKER_TYPE.transitPoint);
      this.addMarkerToMap(MARKER_TYPE.waitingPoint);
      this.addMarkerToMap(MARKER_TYPE.exitPoint);
    } else {
      this.addMarkerToMap(type);
    }

    this.leafletService.drawLines(
      waitingMarker,
      { ...transitMarker, type: 'point' },
      {
        markerId: `${waitingMarker.id}|;|${transitMarker.id}`,
        isSingleLane: false,
      },
      false
    );

    this.leafletService.drawLines(
      transitMarker,
      { ...exitMarker, type: 'point' },
      {
        markerId: `${transitMarker.id}|;|${exitMarker.id}`,
        isSingleLane: false,
      },
      false
    );
  };

  /**
   * Draw marker in map
   */
  public addMarkerToMap(type?: string): void {
    if (this.layoutSensorName) {
      this.leafletService.renderSensorsMarkers(this.sensors, {
        draggable: true,
        color: 'grey',
        dragend: (e) => this.onMarkerDragEnd(e),
      });
    }

    if (this.floorName) {
      if (type === MARKER_TYPE.transitPoint) {
        this.leafletService.renderFloorsMarkers(
          this.floors,
          {
            draggable: true,
            color: 'grey',
            dragstart: (e) => this.onMarkerDragStart(e, type),
            dragend: (e) => this.onMarkerDragEnd(e, type),
          },
          type
        );
      }
      if (type === MARKER_TYPE.waitingPoint) {
        this.leafletService.renderFloorsMarkers(
          this.floors,
          {
            draggable: true,
            color: 'grey',
            dragstart: (e) => this.onMarkerDragStart(e, type),
            dragend: (e) => this.onMarkerDragEnd(e, type),
          },
          type
        );
      }
      if (type === MARKER_TYPE.exitPoint) {
        this.leafletService.renderFloorsMarkers(
          this.floors,
          {
            draggable: true,
            color: 'grey',
            dragstart: (e) => this.onMarkerDragStart(e, type),
            dragend: (e) => this.onMarkerDragEnd(e, type),
          },
          type
        );
      }
    }
  }

  private onMarkerDragStart = (e: LeafletEvent, type?: string) => {
    if (type === MARKER_TYPE.transitPoint) {
      this.leafletService.removeLane(
        this.floors[0].waitingPoint['id'],
        this.floors[0].transitPoint['id']
      );
      this.leafletService.removeLane(
        this.floors[0].transitPoint['id'],
        this.floors[0].exitPoint['id']
      );
    }
    if (type === MARKER_TYPE.waitingPoint) {
      this.leafletService.removeLane(
        this.floors[0].waitingPoint['id'],
        this.floors[0].transitPoint['id']
      );
    }
    if (type === MARKER_TYPE.exitPoint) {
      this.leafletService.removeLane(
        this.floors[0].transitPoint['id'],
        this.floors[0].exitPoint['id']
      );
    }
  };

  /**
   * Event when the marker drag end, get the coordinate,
   * then save the coordinate to form control
   */
  private onMarkerDragEnd = (e: LeafletEvent, type?: string) => {
    // eslint-disable-next-line
    const coordinates = this.leafletService.latlngToXy(e.target.getLatLng());
    const markerId = get(e, 'target.options.markerId', '') as string;

    // Check if the marker placed outside layout preview image
    // eslint-disable-next-line
    const isValid = this.leafletService.isValidCoordinate(e.target.getLatLng());

    if (this.layoutSensorName) {
      if (!isValid) {
        // find moved marker and set it back to last position if the new marker position is invalid
        const found = this.sensors.find((sensor) => sensor.id === markerId);
        const pointMarker = {
          id: found.id,
          x: found.coordinate.x,
          y: found.coordinate.y,
          z: found.coordinate.z,
        };
        this.leafletService.updateMarkerLatLng(pointMarker, MARKER_TYPE.sensor);
        this.snackBar.openSnackBar({
          message: this.utils.getTranslation('SENSOR.ERROR_OUTSIDE_LAYOUT'),
          type: 'failed',
        });
        return;
      }
      this.getCoordinate.emit(coordinates);

      // update the marker with the new position
      this.sensors = this.sensors.map((old) => {
        if (old.id !== markerId) {
          return old;
        }
        return {
          ...old,
          coordinate: {
            x: coordinates.x,
            y: coordinates.y,
            z: 0,
          },
        };
      });
    }

    if (this.floorName && type === MARKER_TYPE.transitPoint) {
      if (!isValid) {
        // set marker back to last position if the new marker position is invalid
        const pointMarker = {
          id: this.floors[0].transitPoint['id'] as string,
          x: this.floors[0].transitPoint.x,
          y: this.floors[0].transitPoint.y,
          z: this.floors[0].transitPoint.z,
        };
        this.leafletService.updateMarkerLatLng(
          pointMarker,
          MARKER_TYPE.transitPoint
        );
        //rerender the line between waiting/exit point and transit point
        this.leafletService.drawLines(
          this.floors[0].waitingPoint,
          { ...this.floors[0].transitPoint, type: 'point' },
          {
            markerId: `${this.floors[0].waitingPoint['id']}|;|${this.floors[0].transitPoint['id']}`,
            isSingleLane: false,
          },
          false
        );
        this.leafletService.drawLines(
          this.floors[0].transitPoint,
          { ...this.floors[0].exitPoint, type: 'point' },
          {
            markerId: `${this.floors[0].transitPoint['id']}|;|${this.floors[0].exitPoint['id']}`,
            isSingleLane: false,
          },
          false
        );
        this.snackBar.openSnackBar({
          message: this.utils.getTranslation('LIFT.ERROR_OUTSIDE_LAYOUT'),
          type: 'failed',
        });
        return;
      }
      this.getCoordinateTransitPoint.emit(coordinates);
      // update the marker with the new position
      this.floors = this.floors.map((old) => {
        if (old.transitPoint['id'] !== markerId) {
          return old;
        }
        return {
          ...old,
          transitPoint: {
            ...old.transitPoint,
            x: coordinates.x,
            y: coordinates.y,
            z: 0,
          },
        };
      });
      //rerender the line between waiting point and transit point
      this.leafletService.drawLines(
        this.floors[0].waitingPoint,
        { ...this.floors[0].transitPoint, type: 'point' },
        {
          markerId: `${this.floors[0].waitingPoint['id']}|;|${this.floors[0].transitPoint['id']}`,
          isSingleLane: false,
        },
        false
      );
      this.leafletService.drawLines(
        this.floors[0].transitPoint,
        { ...this.floors[0].exitPoint, type: 'point' },
        {
          markerId: `${this.floors[0].transitPoint['id']}|;|${this.floors[0].exitPoint['id']}`,
          isSingleLane: false,
        },
        false
      );
    }

    if (this.floorName && type === MARKER_TYPE.waitingPoint) {
      if (!isValid) {
        // set marker back to last position if the new marker position is invalid
        const pointMarker = {
          id: this.floors[0].waitingPoint['id'] as string,
          x: this.floors[0].waitingPoint.x,
          y: this.floors[0].waitingPoint.y,
          z: this.floors[0].waitingPoint.z,
        };
        this.leafletService.updateMarkerLatLng(
          pointMarker,
          MARKER_TYPE.waitingPoint
        );
        this.leafletService.drawLines(
          this.floors[0].waitingPoint,
          { ...this.floors[0].transitPoint, type: 'point' },
          {
            markerId: `${this.floors[0].waitingPoint['id']}|;|${this.floors[0].transitPoint['id']}`,
            isSingleLane: false,
          },
          false
        );
        this.snackBar.openSnackBar({
          message: this.utils.getTranslation('LIFT.ERROR_OUTSIDE_LAYOUT'),
          type: 'failed',
        });
        return;
      }
      this.getCoordinateWaitingPoint.emit(coordinates);

      // update the marker with the new position
      this.floors = this.floors.map((old) => {
        if (old.waitingPoint['id'] !== markerId) {
          return old;
        }
        return {
          ...old,
          waitingPoint: {
            ...old.waitingPoint,
            x: coordinates.x,
            y: coordinates.y,
            z: 0,
          },
        };
      });
      //rerender the line between transit point and exit point
      this.leafletService.drawLines(
        this.floors[0].waitingPoint,
        { ...this.floors[0].transitPoint, type: 'point' },
        {
          markerId: `${this.floors[0].waitingPoint['id']}|;|${this.floors[0].transitPoint['id']}`,
          isSingleLane: false,
        },
        false
      );
    }

    if (this.floorName && type === MARKER_TYPE.exitPoint) {
      if (!isValid) {
        // set marker back to last position if the new marker position is invalid
        const pointMarker = {
          id: this.floors[0].exitPoint['id'] as string,
          x: this.floors[0].exitPoint.x,
          y: this.floors[0].exitPoint.y,
          z: this.floors[0].exitPoint.z,
        };
        this.leafletService.updateMarkerLatLng(
          pointMarker,
          MARKER_TYPE.exitPoint
        );
        this.leafletService.drawLines(
          this.floors[0].transitPoint,
          { ...this.floors[0].exitPoint, type: 'point' },
          {
            markerId: `${this.floors[0].transitPoint['id']}|;|${this.floors[0].exitPoint['id']}`,
            isSingleLane: false,
          },
          false
        );
        this.snackBar.openSnackBar({
          message: this.utils.getTranslation('LIFT.ERROR_OUTSIDE_LAYOUT'),
          type: 'failed',
        });
        return;
      }
      this.getCoordinateExitPoint.emit(coordinates);

      // update the marker with the new position
      this.floors = this.floors.map((old) => {
        if (old.exitPoint['id'] !== markerId) {
          return old;
        }
        return {
          ...old,
          exitPoint: {
            ...old.exitPoint,
            x: coordinates.x,
            y: coordinates.y,
            z: 0,
          },
        };
      });
      this.leafletService.drawLines(
        this.floors[0].transitPoint,
        { ...this.floors[0].exitPoint, type: 'point' },
        {
          markerId: `${this.floors[0].transitPoint['id']}|;|${this.floors[0].exitPoint['id']}`,
          isSingleLane: false,
        },
        false
      );
    }

    if (this.selectedMarkers.length > 0 && this.robotMapId) {
      this.getRobotMapMarkerPosition.emit({
        id: markerId,
        position: { ...coordinates, z: 0 },
      });

      // update the marker with the new position
      this.filteredMarkers = this.filteredMarkers.map((old) => {
        if (old.id !== markerId) {
          return old;
        }
        return {
          ...old,
          position: {
            x: coordinates.x,
            y: coordinates.y,
            z: 0,
          },
        };
      });
    }
  };

  //button to add
  //change the map state so the cursor will change
  toggleAddLiftMarker() {
    this.mapState = 'add-lift-marker';
  }
  /**
   * Update the map after init.
   * Projecting the image overlay in map and add the existing markers into it
   */
  private updateRobotMap(): void {
    setTimeout(() => {
      this.leafletService.map.eachLayer((layer) => {
        this.leafletService.map.removeLayer(layer);
      });
      this.leafletService.resetMarkerGroup();
      this.leafletService.resetSensorGroup();
      this.leafletService.resetFloorGroup();
    }, 200);

    setTimeout(() => {
      // load layout image
      this.layoutsService
        .getRobotMapImageByID(this.robotMapId)
        .subscribe((response) => {
          this.leafletService.loadMapImage(
            response.url,
            response.width,
            response.height
          );
          this.leafletService.setGridImage('/assets/icons/layout-grid.png');
          this.getImageSize.emit({
            width: response.width,
            height: response.height,
          });

          // load layout markers from server
          this.loadSelectedMarkers(response.width, response.height);
        });
      // show or hide layout markers
      this.toggleLayoutMarkers();
    }, 300);
  }

  private loadSelectedMarkers(imageWidth: number, imageHeight: number): void {
    this.apiLayoutMarker.listLayoutMarkers(this.layoutId).subscribe((resp) => {
      this.markers = resp.result;

      this.filteredMarkers = this.markers.filter((marker) =>
        this.selectedMarkers.includes(marker.id)
      );

      this.filteredMarkers.forEach((marker, i) => {
        const modifiedPoint = i === 0 ? -100 : 100;
        marker.position.x = imageWidth / 2 + modifiedPoint;
        marker.position.y = imageHeight / 2 + modifiedPoint;
        this.getRobotMapMarkerPosition.emit({
          id: marker.id,
          position: marker.position,
        });
      });
      this.leafletService.renderLayoutMarkers(this.filteredMarkers, {
        draggable: true,
        dragend: (e) => this.onMarkerDragEnd(e),
      });
    });
  }

  private onMarkerClick(event: LeafletEvent): void {
    const markerId = get(event, 'target.options.markerId', '') as string;
    const selectedMarker = this.markers.find((m) => m.id === markerId);
    if (selectedMarker) {
      this.clickedMarker.emit(selectedMarker);
    }
  }
}
