import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-rm-autocomplete',
  templateUrl: './rm-autocomplete.component.html',
  styleUrls: ['./rm-autocomplete.component.scss'],
})
export class RmAutocompleteComponent implements OnChanges, OnInit {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() formNameControl: FormControl = new FormControl();
  @Input() selectOptions: SelectOption[];
  @Input() invalidMessage: string;
  public filteredOptions: Observable<SelectOption[]>;
  public optionList: SelectOption[];
  ngOnInit(): void {
    console.log('selectOptions', this.selectOptions);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectOptions) {
      this.optionList = changes.selectOptions.currentValue;
      this.filteredOptions = this.formNameControl.valueChanges.pipe(
        startWith(''),
        map((value: SelectOption | string) => {
          const name = typeof value === 'string' ? value : value?.display;
          return name ? this._filter(name) : this.optionList.slice();
        })
      );
    }
  }

  displayFn(option: SelectOption): string {
    return option && option.display ? option.display : '';
  }

  private _filter(name: string): SelectOption[] {
    console.log('name', name);
    this.formNameControl.markAsTouched();
    if (this.optionList) {
      const filterValue = name.toLowerCase();
      return this.optionList.filter((option) =>
        option.display.toLowerCase().includes(filterValue)
      );
    }
  }
}

export interface SelectOption {
  display: string;
  value: any;
  divider?: boolean;
  icon?: string;
}
