/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable space-before-function-paren */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  Component,
  OnInit,
  DoCheck,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { find, filter } from 'lodash';
import { FilterService } from './filter.service';
import { orderBy } from 'lodash';
import { QueryParamService } from 'app/services/query-param.service';
import { ActivatedRoute } from '@angular/router';

export interface FilterType {
  name: string;
  column: string;
  operator: string;
  value: string[];
  extra: string;
  dataType: string;
  virtual: boolean;
  control?: any;
  filteredOptions?: any;
}

interface OrderType {
  name: string;
  type: string;
}
interface FilterData {
  filter: FilterType[];
  order: OrderType[];
}

interface OptionData {
  id: number;
  text: string;
}
@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, DoCheck {
  @Output() filterChange = new EventEmitter();
  @Input() modelName: string;

  isExpanded: boolean = false;
  hasActiveFilter: boolean = false;
  isFilterAvailable: boolean = false;
  filterData: FilterData = {
    filter: [],
    order: [],
  };

  constructor(
    private filterService: FilterService,
    private router: ActivatedRoute
  ) {}

  async ngOnInit() {
    const updatedFilter: FilterType[] = [];

    const filterSpec = await this.filterService.getFilterSpec(this.modelName);
    filterSpec.result.query.filter.forEach(async (filterItem: FilterType) => {
      if (filterItem.dataType === 'enum') {
        //This just a temp solution for filter data on 12-Jul-2024 start
        const commonHandleFlag =
          [`RobotBrand()`, `datadict('Model')`].indexOf(filterItem.extra) > -1;
        const param = commonHandleFlag
          ? filterItem.extra.substring(
              filterItem.extra.indexOf("'") + 1,
              filterItem.extra.lastIndexOf("'")
            )
          : '';
        const functionName = commonHandleFlag
          ? filterItem.extra.substring(0, filterItem.extra.indexOf('('))
          : '';
        const dateRes = commonHandleFlag
          ? await this.filterService[functionName](param)
          : await this.filterService.getEnumList({
              dictType: filterItem.extra.substring(
                filterItem.extra.indexOf("'") + 1,
                filterItem.extra.lastIndexOf("'")
              ),
            });
        //This just a temp solution for filter data on 12-Jul-2024 end
        let sortOrder: any;
        let opt: OptionData[];
        if (commonHandleFlag) {
          sortOrder = orderBy(
            dateRes.result.list,
            [(item: any) => item.name.toLowerCase()],
            ['asc']
          );
          opt = sortOrder.map((item: any) => ({
            id: item.name,
            text: item.name,
          }));
        } else {
          sortOrder = orderBy(
            dateRes.result,
            [(item: any) => item.dictValue.toLowerCase()],
            ['asc']
          );
          // Temporarily modify plan
          // Will be remove after krishalee's response
          // Wait for krishalee's comfirm then ask miao miao to change the response
          if (filterItem.extra === "datadict('VaZoneItemStatus')") {
            sortOrder[0].dictValue = 'Activated';
            sortOrder[0].dictKey = 'activated';
            sortOrder[1].dictValue = 'Deactivated';
            sortOrder[1].dictKey = 'deactivated';
          }
          // Temporarily modify plan
          // Will be remove after krishalee's response
          // Wait for krishalee's comfirm then ask miao miao to change the response

          opt = sortOrder.map((item: any) => ({
            id: item.dictKey,
            text: item.dictValue,
          }));
        }
        const hasSiteSegment = this.router.snapshot.url.some(
          (segment) => segment.path === 'site'
        );

        // check if user is in the site page
        // by checking if the url has `site` name
        if (hasSiteSegment) {
          //exclude the site option from the filter
          opt = opt.filter((item: OptionData) => item.text !== 'Site');
        }

        const controlInstance = new FormControl();
        const filteredOptions: Observable<OptionData[]> =
          controlInstance.valueChanges.pipe(
            startWith(''),
            map(() => opt)
          );
        updatedFilter.push({
          ...filterItem,
          control: controlInstance,
          filteredOptions,
        });
      } else {
        const controlInstance = new FormControl();
        updatedFilter.push({ ...filterItem, control: controlInstance });
      }
    });
    updatedFilter.map((item) => {
      item.name = item.name.replace('Email', 'E-mail');
      item.name = item.name.replace('User Name', 'Username');
      return item;
    });
    this.filterData.filter = updatedFilter;
  }

  ngDoCheck(): void {
    if (this.filterData) {
      const filled = filter(this.filterData.filter, function (o: FilterType) {
        return o?.value?.length !== 0 && o?.dataType !== '';
      });

      const enabledFilterSpec = filter(
        this.filterData.filter,
        function (o: FilterType) {
          return o?.dataType !== '';
        }
      );

      this.isFilterAvailable = enabledFilterSpec.length !== 0;
      this.hasActiveFilter = filled.length !== 0;
    }
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  handleInputChange(e, column, name): void {
    let selectedFilter = null;
    if (name) {
      selectedFilter = find(this.filterData.filter, function (o: any) {
        return o.name === name && o.column === column;
      });
    } else {
      selectedFilter = find(this.filterData.filter, function (o: any) {
        return o.column === column;
      });
    }

    if (e === '') {
      selectedFilter.value = [];
    } else {
      if (typeof e === 'object') {
        selectedFilter.value = [e.id];
      } else {
        let time;
        if (name) {
          if (
            name.toLowerCase() === 'end date' ||
            name.toLowerCase() === 'end time'
          ) {
            time = `${e} 23:59:59`;
          } else if (
            name.toLowerCase() === 'start date' ||
            name.toLowerCase() === 'start time'
          ) {
            time = `${e} 00:00:00`;
          }
        } else {
          time = e;
        }
        selectedFilter.value = [time];
      }
    }
  }

  compareObjects(o1: OptionData, o2: OptionData): boolean {
    return o1.text === o2.text && o1.id === o2.id;
  }

  displayFn(item: OptionData): string {
    return item && item.text ? item.text : '';
  }

  handleApplyFilter(): void {
    this.filterChange.emit(this._cleanFilterDataForParent(this.filterData));
  }

  handleClearFilter(cleanFilterForParent: boolean = true): void {
    this.filterData.filter.forEach((item: FilterType) => {
      if (item.control) {
        item.control.setValue('');
      }
      item.value = [];
    });

    if (cleanFilterForParent) {
      this.filterChange.emit(this._cleanFilterDataForParent(this.filterData));
    }
  }

  setInputIdentifier(column: string, name: string): string {
    const formatName = name.split(' ').join('-').toLowerCase();
    return `filter-by-${column}-${formatName}`;
  }

  private _cleanFilterDataForParent(filterData: FilterData) {
    if (filterData.filter) {
      return filterData.filter.map(
        ({ name, column, operator, value, extra, dataType, virtual }) => ({
          name,
          column,
          operator,
          value,
          extra,
          dataType,
          virtual,
        })
      );
    }
  }
}
