import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectDropdownInputComponent } from './select-dropdown-input.component';
import { ActionButtonModule } from '../action-button/action-button.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TextSofiaModule } from '../text-sofia/text-sofia.module';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
  declarations: [SelectDropdownInputComponent],
  imports: [
    CommonModule,
    ActionButtonModule,
    MatCheckboxModule,
    TextSofiaModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    TranslocoRootModule,
  ],
  exports: [SelectDropdownInputComponent],
})
export class SelectDropdownInputModule {}
