import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Lift } from 'app/services/api.types';

@Component({
  selector: 'lift-card',
  templateUrl: './lift-card.component.html',
  styleUrls: ['./lift-card.component.scss'],
})
export class LiftCardComponent implements OnInit {
  @Input() lift: Lift;
  @Output() controlLift = new EventEmitter();
  @Output() changeStatus = new EventEmitter();
  @Output() goUpper = new EventEmitter();
  @Output() goUp = new EventEmitter();
  @Output() goDown = new EventEmitter();

  public ngOnInit(): void {}

  public handleDialog(): void {
    this.controlLift.emit(this.lift);
  }

  public handleChangeStatus(status: number): void {
    this.changeStatus.emit({ lift: this.lift, status: status });
  }
}
