import {
  Component,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() value: string | number | null;
  @Input() inputType: string;
  @Input() iconName: string;
  @Input() iconUrl: string;
  @Input() multiline: boolean;
  @Input() validationMessage: string;
  @Input() validMessage: string;
  @Input() invalidMessage: string;
  @Input() showValidMessage: boolean = true;
  @Input() showInvalidMessage: boolean = true;
  @Input() maxCharInput = 255;
  @Input() isScriptInput = false;
  @Input() withIcons: boolean = false;
  @Input() readonly: boolean = false;

  //reactive-forms
  @Input() formControl: any;
  @Input() matAutocomplete: any;
  @Input() height: string;

  @Output() valueChange = new EventEmitter();

  textValue: string | number | null = '';
  isActive: boolean = false;

  private _disabled: boolean;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }
  constructor() {}

  ngOnInit(): void {
    this.textValue = this.value;

    if (this.formControl) {
      this.textValue = this.formControl.value;
      this.formControl.valueChanges.subscribe((val) => {
        this.textValue = val;
      });
    }
  }

  ngOnChanges(): void {
    this.textValue = this.value || '';

    if (this.formControl) {
      this.textValue = this.formControl.value;
      this.formControl.valueChanges.subscribe((val) => {
        this.textValue = val;
      });
    }
  }

  handleValueChange(text): void {
    this.valueChange.emit(text);
  }
}
