import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LayoutsService } from 'app/modules/settings/layouts/layouts.service';
import { LeafletService } from 'app/modules/settings/layouts/services/leaflet.service';
import { AVAILABLE_ZONE_AREA_COLOR } from 'app/modules/settings/layouts/utilities/marker-constanta';
import { ApiLayoutMarker } from 'app/services/api-layout-marker.service';
import { PointRegion, Region } from 'app/services/api.types';
import { PointExpression, Polygon, PolylineOptions, polygon } from 'leaflet';

interface ZoneArea extends Omit<Region, 'coordinates' | 'metadata'> {
  coordinates: PointRegion[];
  color: string;
}

interface RmPolylineOptions extends PolylineOptions {
  name?: string;
  markerId?: string;
  color?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MiniLayoutMapService extends LeafletService {
  constructor(
    protected apiLayoutMarker: ApiLayoutMarker,
    protected translocoService: TranslocoService,
    protected layoutsService: LayoutsService
  ) {
    super(apiLayoutMarker, translocoService, layoutsService);
  }

  /**
   * Add zone area to map
   *
   * @param zone
   * @param options
   * @returns
   */
  public renderZone(
    zone: ZoneArea,
    options?: Partial<RmPolylineOptions>
  ): Polygon {
    if (this._tempZone) {
      this.removeTempZone();
    }

    options = {
      ...options,
      markerId: zone.id,
      color: AVAILABLE_ZONE_AREA_COLOR[zone.color],
      name: zone.name,
    };

    const latlngs = zone.coordinates.map((point) => this.xYtoLatlng(point));
    const cfMarker = polygon(latlngs, options).addTo(this.map);

    //add tooltip for the door name
    cfMarker.bindTooltip(options.name, {
      permanent: true,
      opacity: 1,
      direction: 'center',
      className: 'zone-label',
    });

    // add to markers group
    this._zonesGroup.addLayer(cfMarker);

    // add to the index
    this._zones[zone.id] = cfMarker;

    return cfMarker;
  }

  addEventFlag(eventName: string, coordinate: PointExpression): void {
    const eventCoordinate = this.xYtoLatlng(coordinate);
    this.setEventFlag(eventCoordinate);
    // add label
    this.addEventName(eventCoordinate, eventName);
  }
}
