import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rm-short-uuid',
  template: ` {{ shortenedUuid }} `,
})
export class ShortUuidComponent implements OnInit {
  @Input() uuid: string;

  public shortenedUuid: string;

  ngOnInit(): void {
    this.shortenedUuid = this.uuid.replace('-', '').substring(0, 6);
  }
}
