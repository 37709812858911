import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { HttpClientModule } from '@angular/common/http';
import { SignInService } from 'app/modules/auth/sign-in/sign-in.service';
import { AppSettings } from './app.settings';
import { TranslocoCoreModule } from './core/transloco/transloco.module';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';

const routerConfig: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  preloadingStrategy: PreloadAllModules,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse config
    FuseModule,
    FuseConfigModule.forRoot(appConfig),

    // Core
    CoreModule,

    // Layout
    LayoutModule,

    // 3rd party modules
    MarkdownModule.forRoot({}),
    TranslocoCoreModule,
    HttpClientModule,
    HttpCacheInterceptorModule.forRoot({
      //time to live 5 minutes for the cache
      ttl: 5 * 60 * 1000,
    }),
  ],
  bootstrap: [AppComponent],
  providers: [SignInService, AppSettings],
})
export class AppModule {
  static injector: Injector;
  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
