import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Mission } from 'app/shared/interface/mission';
import { Card, CardEvent } from 'app/shared/interface/card';
import { SnackbarService } from 'app/services/snackbarService';
import { CardService } from '../card.service';
import { IResponse } from 'app/modules/robots/robots.model';
import { Utils } from 'app/services/utils';

const statusMapping = {
  abort: 2,
  pause: 3,
  resume: 5,
  start: 5,
};

@Component({
  selector: 'mission-card',
  templateUrl: './mission-card.component.html',
  styleUrls: ['./mission-card.component.scss'],
})
export class MissionCardComponent implements OnInit {
  @Input() item: Mission;
  @Input() card: Card;
  @Input() hideRobotInfo: boolean;
  @Input() parent: string;
  @Output() action = new EventEmitter<CardEvent>();

  oldItemStatus: number;

  chevronUpIcon: string = 'app/../assets/icons/chevron-up.svg';
  chevronDownIcon: string = 'app/../assets/icons/chevron-down.svg';
  robotIcon = '/assets/icons/robot_blue.svg';

  constructor(
    private _snackbar: SnackbarService,
    private cardSvc: CardService,
    private utils: Utils
  ) {}

  ngOnInit(): void {
    this.item.updatedAt = new Date(this.item.updatedAt).toLocaleString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
    this.oldItemStatus = this.item.status;
  }

  toggleShow(): void {
    this.card.isOpen = !this.card.isOpen;
  }

  toggleSelected(): void {
    this.card.isSelected = !this.card.isSelected;
    this.action.emit({
      type: 'select',
      id: this.item.id,
      data: this.card.isSelected,
    });
  }

  toggleAction(type: string): void {
    this.item.status = statusMapping[type];
    // this._snackbar.openSnackBar({
    //   message: 'Success',
    //   type: 'success',
    // });
    const psd = {
      id: this.item.id,
      // name: this.item.name,
      // description: this.item.description,
      // priority: 0,
      status: this.item.status,
      // type: 0,
    };
    this.cardSvc.updateMission(psd).subscribe((response: IResponse) => {
      switch (response.code) {
        case 200:
          this._snackbar.openSnackBar({
            message: this.utils.getTranslation('Success'),
            type: 'success',
          });
          break;
        case 400:
          this._snackbar.openSnackBar({
            message: response.message,
            type: 'failed',
          });
          console.log('Update Mission error', response);
          break;
        default:
          console.log(response.code, response.message);
      }
    });
    this.action.emit({
      type: 'action',
      id: this.item.id,
      data: type,
    });
  }

  handleNavigate(type: string, event: Event): void {
    event.stopPropagation();
    this.action.emit({
      type: 'navigate',
      id: this.item.id,
      data: type,
    });
  }

  statusNumToText(status: number): string {
    switch (status) {
      case 1:
        return 'Completed';
      case 2:
        return 'Aborted';
      case 3:
        return 'Paused';
      case 4:
        return 'Scheduled';
      case 5:
        return 'Active';
      case 6:
        return 'Failed';
      default:
        return;
    }
  }
}
