import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Map, NavigationControl, Marker } from 'maplibre-gl';

interface EventLocation {
  lat: number;
  lng: number;
}

@Component({
  selector: 'event-map-mini',
  templateUrl: './event-map.component.html',
  styleUrls: ['./event-map.component.scss'],
})
export class EventMapComponent implements OnInit {
  @ViewChild('mapContainer', { static: true })
  public mapContainer: ElementRef<HTMLDivElement>;

  @ViewChild('robotCanvas', { static: true })
  public robotCanvasRef: ElementRef<HTMLCanvasElement>;

  @Input() name: string;
  @Input() eventLocation: EventLocation;

  map: Map;

  constructor() {}

  ngOnInit(): void {
    this.initMap();
    this.drawMarker();
  }

  initMap() {
    const offset = -0.0001;
    this.map = new Map({
      container: this.mapContainer.nativeElement,
      style:
        'https://api.maptiler.com/maps/streets/style.json?key=CddPes2siR2dLXJRRVw0',
      zoom: 17.5,
      center: [this.eventLocation.lng, this.eventLocation.lat + offset],
      antialias: true,
      // pitch: 40,
    });
    this.map['doubleClickZoom'].disable();
    this.map.boxZoom.disable();
    this.map.dragRotate.disable();
    this.map.on('contextmenu', (e) => false);
    this.map.on('load', () => {
      this.map.resize(); //resize mapbox on load to fix positioning issues with babylon
    });
  }

  drawMarker() {
    // create a DOM element for the marker
    const el = document.createElement('div');
    el.className = 'point-container';

    const point = document.createElement('div');
    point.className = 'point';

    const label = document.createElement('span');
    label.className = 'label';
    label.innerText = this.name;

    el.append(point);
    el.append(label);

    // add marker to map
    new Marker(el)
      .setLngLat([this.eventLocation.lng, this.eventLocation.lat])
      .addTo(this.map);
  }
}
