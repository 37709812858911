import { Pipe, PipeTransform } from '@angular/core';
import { Robot } from 'app/services/api.types';

@Pipe({
  name: 'robotFilter',
})
export class RobotTableFilterPipe implements PipeTransform {
  transform(list: RobotWithRelation[], value: boolean): RobotWithRelation[] {
    return value ? list.filter((item) => item.assigned === value) : list;
  }
}

interface RobotWithRelation extends Robot {
  assigned?: boolean;
}
