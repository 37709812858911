import { MapDialogComponent } from './ui-components/dialog-map/dialog-data-map.dialog';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule, MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule, MAT_TABS_CONFIG } from '@angular/material/tabs';
import { TranslocoModule } from '@ngneat/transloco';
import { EventMapModule } from 'app/modules/events/event-map/event-map.module';
import { ActionBarModule } from './layout/action-bar/action-bar.module';
import { RightSidebarLayoutModule } from './layout/right-sidebar-layout/right-sidebar-layout.module';
import { TopHeaderModule } from './layout/top-header/top-header.module';
import { ActionButtonModule } from './ui-components/action-button/action-button.module';
import { ActionPromptModule } from './ui-components/action-prompt/action-prompt.module';
import { AutocompleteInputModule } from './ui-components/autocomplete-input/autocomplete-input.module';
import { BulkActionButtonModule } from './ui-components/bulk-action-button/bulk-action-button.module';
import { CommonDialogModule } from './ui-components/common-dialog/common-dialog.module';
import { SnackbarModule } from './ui-components/snackbar/snackbar.module';
import { FileInputModule } from './ui-components/file-input/file-input.module';
import { FilterModule } from './ui-components/filter/filter.module';
import { MultiSelectModule } from './ui-components/multi-select/multi-select.module';
import { RmDatatableModule } from './ui-components/rm-datatable/rm-datatable.module';
import { SearchInputModule } from './ui-components/search-input/search-input.module';
import { SelectDropdownInputModule } from './ui-components/select-dropdown-input/select-dropdown-input.module';
import { SlideToggleModule } from './ui-components/slide-toggle/slide-toggle.module';
import { TextInputModule } from './ui-components/text-input/text-input.module';
import { TextSofiaModule } from './ui-components/text-sofia/text-sofia.module';
import { ThumbnailCardModule } from './ui-components/thumbnail-card/thumbnail-card.module';
// import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CardModule } from './ui-components/card/card.module';
import { RobotMapsDropdownInputModule } from './ui-components/robotmaps-dropdown-input/robotmaps-dropdown-input.module';
// import { ButtonTypeDirective } from './directives/button.directive';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
  MatSortModule,
  MAT_SORT_DEFAULT_OPTIONS,
} from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SidebarModule } from 'ng-sidebar';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { DirectiveModule } from './directives/directive.module';
import { ContainerScrollComponent } from './ui-components/container-scroll';
import { DatatableAssignCheckColumn } from './ui-components/datatable/datatable-assign-check.component';
import { DatatableCheckboxColumn } from './ui-components/datatable/datatable-checkbox.component';
import { DatatableDatetimeColumn } from './ui-components/datatable/datetime-column.component';
import { DatatableLinkColumn } from './ui-components/datatable/link-column.component';
import { DatatableStatusColumn } from './ui-components/datatable/status-column.component';
import { EmptyTableDataComponent } from './ui-components/empty-table-data.component';
import { RmMiniMapComponent } from './ui-components/rm-mini-map.component';
import { LabelValueComponent } from './ui-components/label-value';
import { PaginatorComponent } from './ui-components/paginator';
import { PillButtonComponent } from './ui-components/pill-button.component';
import { ShortUuidComponent } from './ui-components/short-uuid';
import { StatusModule } from './ui-components/status.component';
import { SelectRobotModule } from './ui-components/select-robots';

import { AvatarModule } from './ui-components/avatar/avatar.module';
import { ClickableLabelValueComponent } from './ui-components/clickable-label-value';
import { LabelEventComponent } from './ui-components/label/label-event.component';
import { RobotTableFilterPipe } from 'app/modules/settings/pipes/robot-table-filter.pipe';
import { MultiSelectDropdownComponent } from './ui-components/multi-select-dropdown.component';
import { MatTreeModule } from '@angular/material/tree';
import { MiniLayoutEditorComponent } from './ui-components/mini-layout-editor/mini-layout-editor.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { LiftCardModule } from './ui-components/lift-card/lift-card.module';
import { RobotSensorModule } from './ui-components/robot-sensor/robot-sensor.module';
import { PrettyPrintPipe } from 'app/modules/settings/skills/skill-setup/pretty-print.pipe';
import { AssignedUsersFilterPipe } from 'app/modules/settings/pipes/assignedusers-filter.pipe';
import { AssignedResourcesFilterPipe } from 'app/modules/settings/pipes/assignedresources-filter.pipe';
import { RmAutocompleteComponent } from './ui-components/rm-autocomplete/rm-autocomplete.component';
import { RmTextInputComponent } from './ui-components/rm-text-input/rm-text-input.component';
import { DoorCardModule } from './ui-components/door-card/door-card.module';
import { MatSliderModule } from '@angular/material/slider';
import { GroupLiftCardModule } from './ui-components/group-lift-card/group-lift-card.module';

const MAT_MODULES = [
  TranslocoModule,
  MatTreeModule,
  MatButtonModule,
  MatIconModule,
  MatDividerModule,
  MatProgressBarModule,
  MatButtonToggleModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatSidenavModule,
  MatCardModule,
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  MatCheckboxModule,
  TextFieldModule,
  MatTabsModule,
  RightSidebarLayoutModule,
  TopHeaderModule,
  SearchInputModule,
  TextSofiaModule,
  ThumbnailCardModule,
  LiftCardModule,
  GroupLiftCardModule,
  ActionBarModule,
  BulkActionButtonModule,
  MatAutocompleteModule,
  FileInputModule,
  MatTooltipModule,
  ActionButtonModule,
  TextInputModule,
  MultiSelectModule,
  ActionPromptModule,
  FilterModule,
  MatListModule,
  RmDatatableModule,
  SelectDropdownInputModule,
  AutocompleteInputModule,
  MatPaginatorModule,
  MatSnackBarModule,
  MatDialogModule,
  CommonDialogModule,
  SnackbarModule,
  SlideToggleModule,
  MatDatepickerModule,
  EventMapModule,
  CardModule,
  RobotMapsDropdownInputModule,
  DirectiveModule,
  SidebarModule,
  MatCheckboxModule,
  MatSortModule,
  RouterModule,
  SidebarModule,
  VirtualScrollerModule,
  LeafletModule,
  MatExpansionModule,
  DoorCardModule,
  MatSliderModule,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StatusModule,
    SelectRobotModule,
    ...MAT_MODULES,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StatusModule,
    PillButtonComponent,
    DatatableStatusColumn,
    DatatableCheckboxColumn,
    DatatableLinkColumn,
    DatatableAssignCheckColumn,
    RmAutocompleteComponent,
    RmTextInputComponent,
    DatatableDatetimeColumn,
    RmMiniMapComponent,
    MapDialogComponent,
    ContainerScrollComponent,
    LabelValueComponent,
    LabelEventComponent,
    ShortUuidComponent,
    EmptyTableDataComponent,
    PaginatorComponent,
    SelectRobotModule,
    AvatarModule,
    ClickableLabelValueComponent,
    RobotTableFilterPipe,
    MultiSelectDropdownComponent,
    MiniLayoutEditorComponent,
    RobotSensorModule,
    PrettyPrintPipe,
    AssignedUsersFilterPipe,
    AssignedResourcesFilterPipe,
    ...MAT_MODULES,
  ],
  declarations: [
    // StatusComponent,
    PillButtonComponent,
    DatatableCheckboxColumn,
    DatatableStatusColumn,
    DatatableLinkColumn,
    DatatableAssignCheckColumn,
    DatatableDatetimeColumn,
    RmMiniMapComponent,
    MapDialogComponent,
    ContainerScrollComponent,
    LabelValueComponent,
    LabelEventComponent,
    ShortUuidComponent,
    EmptyTableDataComponent,
    PaginatorComponent,
    ClickableLabelValueComponent,
    RobotTableFilterPipe,
    MultiSelectDropdownComponent,
    MiniLayoutEditorComponent,
    PrettyPrintPipe,
    AssignedUsersFilterPipe,
    AssignedResourcesFilterPipe,
    RmAutocompleteComponent,
    RmTextInputComponent,
  ],
  //fix dropdown paging issue
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        disableOptionCentering: true,
      },
    },
    {
      provide: MAT_SORT_DEFAULT_OPTIONS,
      useValue: {
        disableClear: true,
      },
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: {
        animationDuration: '0ms',
      },
    },
  ],
})
export class SharedModule {}
