import { ResponseOne, Map } from './../../../services/api.types';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { INewMap, IResponse, IRobotMap } from './robot_maps.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RobotMapsService {
  private headers = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  isDirty = false;
  dataList = new BehaviorSubject<IRobotMap[]>([]);

  /** Constructor **/
  constructor(private _http: HttpClient) {}
  getMap(param: any): Observable<any> {
    return this._http.post('/api/v2/map/list', param);
  }

  getDataByIDInternal(id) {
    /** With mock data */
    // console.log(this.dataList.getValue());
    return this.dataList.value.find((i) => i.id === id);
  }

  getDataByID(id: string) {
    return this._http.get(`/api/v2/map/${id}`, this.headers);
  }

  addNew(map: IRobotMap) {
    const data = {
      name: map.name,
      type: map.type,
      fileId: map.fileId,
      robots: [],
      scale: [1, 1],
      translate: [0, 0],
      angle: 0,
      layoutID: map.layoutID,
    };
    return this._http.post('/api/v2/map', data, this.headers);
  }

  updateDataByID(map: IRobotMap) {
    const data: INewMap = {
      name: map.name,
      type: map.type,
      fileId: map.fileId,
      robots: [],
      scale: [1, 1],
      translate: [0, 0],
      angle: 0,
      layoutID: map.layoutID,
    };
    return this._http.put(`/api/v2/map/${map.id}`, data, this.headers);
  }

  deleteDataByID(id: string) {
    return this._http.delete(`/api/v2/map/${id}`, this.headers);
  }

  deleteDataInBulk(idArray: string[]) {
    let idList = '';
    idArray.forEach((id, i) => {
      idList += i === idArray.length - 1 ? id : `${id},`; // To test comma later
    });
    return this._http.delete(`/api/v2/map/${idList}`, this.headers);
  }

  duplicateDataInBulk(id: string[]) {
    return console.log('call api to bulk duplicate >>', id);
  }

  /** Map Type */
  getMapTypes() {
    const data = {
      dictType: 'MapType',
    };
    return this._http.post('/api/v2/datadict/getByType', data, this.headers);
  }

  createMapType(key: string, value: string) {
    const data = {
      dictType: 'MapType',
      dictKey: key,
      dictValue: value,
    };
    return this._http.post('/api/v2/datadict/create', data, this.headers);
  }

  deleteMapType(id: string) {
    return this._http.delete(`/api/v2/datadict/datadict/${id}`, this.headers);
  }

  updateMapType(id: string, key: string, value: string) {
    const data = {
      dictType: 'MapType',
      dictKey: key,
      dictValue: value,
    };
    return this._http.put(
      `/api/v2/datadict/datadict/${id}`,
      data,
      this.headers
    );
  }

  /** Get file from assets */
  getAssetFile(id: string) {
    return this._http.get('/api/v2/asset/get-file/' + id, {
      responseType: 'blob',
    });
  }

  getFile(id: string): Observable<Blob> {
    return this._http.get(`/api/v2/asset/get-file/${id}`, {
      responseType: 'blob',
    });
  }

  getRobotMapRobotsRelation(
    robotIds: string[],
    mapIds: string[]
  ): Observable<ResponseOne<MapRobot[]>> {
    const payload = {
      robotIds: robotIds,
      mapIds: mapIds,
    };
    return this._http.post<ResponseOne<MapRobot[]>>(
      `/api/v2/robot-map/list`,
      payload,
      this.headers
    );
  }

  assignRobot(mapId: string, robotId: string): Observable<ResponseOne<Map>> {
    const payload = {
      mapId: mapId,
      robotId: robotId,
    };
    return this._http.post<ResponseOne<Map>>(
      '/api/v2/robot-map',
      payload,
      this.headers
    );
  }
  unassignRobot(mapId: string, robotId: string): Observable<ResponseOne<Map>> {
    const payload = {
      mapId: mapId,
      robotId: robotId,
    };
    return this._http.post<ResponseOne<Map>>(
      '/api/v2/robot-map/unbind',
      payload,
      this.headers
    );
  }

  getRobotMapRelationship(
    mapIds: string[],
    robotId: string[]
  ): Observable<ResponseOne<RobotMapRelation[]>> {
    const payload = {
      mapIds: mapIds,
      robotIds: robotId,
    };
    return this._http.post<ResponseOne<RobotMapRelation[]>>(
      '/api/v2/robot-map/list',
      payload,
      this.headers
    );
  }
}
interface MapRobot {
  companyId: string;
  id: string;
  mapId: string;
  robotId: string;
}
interface RobotMapRelation {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  mapId: string;
  robotId: string;
  companyId: string;
  createdBy: string;
  updatedBy: string;
}
