import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit {
  @Output() valueChangeEvent = new EventEmitter<string>();

  _opened = false;

  ngOnInit(): void {}

  _toggleSidebar(): void {
    this._opened = !this._opened;
  }

  textValueChange(event: any): void {
    this.valueChangeEvent.emit(event.target.value);
  }
}
