import { CdkTextColumn } from '@angular/cdk/table';
import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'rm-assign-column',
  template: `
    <ng-container
      matColumnDef="assign"
      [sticky]="stickyColumn === 'yes' ? true : null"
    >
      <th mat-header-cell *matHeaderCellDef [ngStyle]="{ width: width }">
        {{ 'REUSED_COMP.ASSIGN' | transloco }} +
      </th>
      <td mat-cell *matCellDef="let row" [ngStyle]="{ width: width }">
        <button mat-mini-fab (click)="handleAddRemove(row)" class="add-remove">
          <mat-icon
            [ngClass]="row.assigned ? 'assigned' : 'unassigned'"
            [svgIcon]="
              row.assigned
                ? 'heroicons_solid:check-circle'
                : 'heroicons_solid:plus-circle'
            "
          ></mat-icon>
        </button>
      </td>
    </ng-container>
  `,
  styles: [
    `
      .mat-mini-fab {
        box-shadow: none !important;
      }
      .mat-mini-fab.mat-accent {
        background-color: transparent !important;
      }
      .unassigned {
        color: #ffffff !important;
      }
      .assigned {
        color: #04b4cd !important;
      }
    `,
  ],
})
export class DatatableAssignCheckColumn<T> extends CdkTextColumn<T> {
  @Input() stickyColumn: string = 'no';
  @Input() width: string = '90px';
  @Output() addRemove = new EventEmitter();

  handleAddRemove(data) {
    this.addRemove.emit(data);
  }
}
