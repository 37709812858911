import { ForbiddenComponent } from './layout/common/forbidden/forbidden.component';
import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { NotificationListComponent } from './layout/common/notifications/notification-list/notification-list';
import { MainAccessGuard } from './core/auth/guards/main-access.guard';
// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'apps' },

  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'apps' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.module'
          ).then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            'app/modules/auth/forgot-password/forgot-password.module'
          ).then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('app/modules/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            (m) => m.AuthSignInModule
          ),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then(
            (m) => m.AuthSignUpModule
          ),
      },
      {
        path: 'terms',
        loadChildren: () =>
          import('app/modules/auth/policy/policy.module').then(
            (m) => m.PolicyModule
          ),
      },
      {
        path: 'policy',
        loadChildren: () =>
          import('app/modules/auth/policy/policy.module').then(
            (m) => m.PolicyModule
          ),
      },
      // {path: 'organization' , loadChildren: () => import('app/modules/auth/organization/organization.module').then(m=>m.OrganizationModule)}
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'apps',
        canActivate: [MainAccessGuard],
        loadChildren: () =>
          import('app/modules/app-list/app-list.module').then(
            (m) => m.AppListModule
          ),
        // find the menu id in the app/modules/settings/constants.ts file
        data: { menuItemId: 'opsApps' },
      },
      {
        path: 'monitoring',
        canActivate: [MainAccessGuard],
        loadChildren: () =>
          import('app/modules/monitor/monitor.module').then(
            (m) => m.MonitorModule
          ),
        // find the menu id in the app/modules/settings/constants.ts file
        data: { menuItemId: 'opsMonitoring' },
      },
      {
        path: 'videos',
        canActivate: [MainAccessGuard],
        loadChildren: () =>
          import('app/modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        // find the menu id in the app/modules/settings/constants.ts file
        data: { menuItemId: 'opsVideos' },
      },
      {
        path: 'events',
        canActivate: [MainAccessGuard],
        loadChildren: () =>
          import('app/modules/events/events.module').then(
            (m) => m.EventsModule
          ),
        // find the menu id in the app/modules/settings/constants.ts file
        data: { menuItemId: 'opsEvents' },
      },

      {
        path: 'missions',
        canActivate: [MainAccessGuard],
        loadChildren: () =>
          import('app/modules/missions/missions.module').then(
            (m) => m.MissionModule
          ),
        // find the menu id in the app/modules/settings/constants.ts file
        data: { menuItemId: 'opsMissions' },
      },
      {
        path: 'robots',
        canActivate: [MainAccessGuard],
        loadChildren: () =>
          import('app/modules/robots/robots.module').then(
            (m) => m.RobotsModule
          ),
        // find the menu id in the app/modules/settings/constants.ts file
        data: { menuItemId: 'opsRobots' },
      },
      {
        path: 'lifts',
        canActivate: [MainAccessGuard],
        loadChildren: () =>
          import('app/modules/lifts/lifts.module').then((m) => m.LiftsModule),
        // find the menu id in the app/modules/settings/constants.ts file
        data: { menuItemId: 'opsLifts' },
      },

      {
        path: 'doors',
        canActivate: [MainAccessGuard],
        loadChildren: () =>
          import('app/modules/doors/doors.module').then((m) => m.DoorsModule),
        // find the menu id in the app/modules/settings/constants.ts file
        data: { menuItemId: 'opsDoors' },
      },

      {
        path: 'settings',
        loadChildren: () =>
          import('app/modules/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('app/layout/common/header/profile/profie.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'robot-logs/:id',
        loadChildren: () =>
          import('app/modules/robot-logs/robot-logs.module').then(
            (m) => m.RobotLogsModule
          ),
      },
      {
        path: 'notifications',
        component: NotificationListComponent,
      },
      {
        path: 'noaccess',
        loadChildren: () =>
          import('app/modules/noaccess-page/noaccess.module').then(
            (m) => m.NoAccessModule
          ),
      },
      {
        path: 'forbidden',
        component: ForbiddenComponent,
      },
    ],
  },
];
