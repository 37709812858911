import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'rm-multi-select-dropdown',
  template: `
    <div [class.invalid]="validation === 'invalid'">
      <mat-form-field id="select-multiple">
        <mat-select
          [(value)]="selectedOption"
          [disabled]="disabled"
          multiple
          #select
          #selectedOpt="ngModel"
          ngModel
        >
          <mat-select-trigger>
            {{ 'REUSED_COMP.ASSIGN' | transloco }} ({{ selectedOption.length }})
          </mat-select-trigger>
          <mat-optgroup id="select-multiple-optgroup" label="{{ panelLabel }}">
            <mat-option
              (onSelectionChange)="onSelectOption($event)"
              *ngFor="let option of optionList"
              [value]="option.id"
            >
              {{ option.name }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <div
        class="error"
        *ngIf="
          validation === 'invalid' &&
          (validated || selectedOpt.touched || selectedOpt.dirty)
        "
      >
        <img src="../../../../assets/icons/error.svg" />
        <p>
          {{ 'This field is required.' | transloco }}
        </p>
      </div>
    </div>
  `,
  styles: [
    `
      ::ng-deep
        #select-multiple.mat-form-field.mat-form-field-appearance-fill
        .mat-form-field-wrapper
        .mat-form-field-flex,
      ::ng-deep
        .dark
        #select-multiple.mat-form-field.mat-form-field-appearance-fill
        .mat-form-field-wrapper
        .mat-form-field-flex {
        height: 45px;
        min-height: 45px;
        border: 1px solid #344563;
        background: rgba(0, 0, 0, 0.71) !important;
        color: #7a869a;
        border-radius: 3px;
        padding-right: 10px;
        padding-left: 0px;
      }

      ::ng-deep
        #select-multiple.mat-form-field.mat-form-field-appearance-fill
        .mat-form-field-wrapper {
        margin-bottom: 0px;
      }

      #select-multiple.mat-form-field {
        width: 100%;
      }

      #select-multiple-optgroup .mat-optgroup-label {
        color: white;
      }

      .invalid {
        .form-container:focus-within {
          border: 2px solid #de350b;
        }
        mat-label {
          color: #ff5630 !important;
        }
        .error {
          align-items: center;
          display: flex;
          color: #ff5630 !important;
          font-size: 11px;
          img {
            width: 16px;
          }
        }
      }
    `,
  ],
})
export class MultiSelectDropdownComponent {
  @Input() validation = '';
  @Input() panelLabel = '';
  @Input() selectedOption: string[] = [];
  @Input() optionList = [];
  @Input() isCloseDropdown = false;
  @Input() disabled;
  @Input() validated = false;
  @Output() selectOption: EventEmitter<{ value: string; checked: boolean }> =
    new EventEmitter<{ value: string; checked: boolean }>();
  @ViewChild('select') private select: MatSelect;

  public onSelectOption(event): void {
    if (event.isUserInput) {
      this.selectOption.emit({
        value: event.source.value,
        checked: event.source.selected,
      });
      if (this.isCloseDropdown) {
        this.select.close();
      }
    }
  }
}
