import { Component, Input, OnInit } from '@angular/core';
import { AppSettings } from 'app/app.settings';
import { ApiRobotSensors } from 'app/services/api-robotsensors.service';
import {
  ResponseList,
  RobotSensorList,
  ResponseWithoutList,
} from 'app/services/api.types';
import { DatePipe } from '@angular/common';
import _ from 'lodash';
import moment from 'moment';

@Component({
  selector: 'robot-sensor',
  templateUrl: './robot-sensor.component.html',
  styleUrls: ['./robot-sensor.component.scss'],
})
export class RobotSensorComponent implements OnInit {
  @Input() robotId: string;
  sensorList: Array<RobotSensorList> = [];

  constructor(
    private appSettings: AppSettings,
    private robotSensorsSvc: ApiRobotSensors,
    private _datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    if (this.robotId) {
      this.getRobotSensorData();
      this.sensorListnerData();
    }
  }

  getRobotSensorData() {
    this.robotSensorsSvc
      .getRobotSensorsbyId(this.robotId)
      .subscribe((data: ResponseWithoutList<RobotSensorList>) => {
        if (data.code === 200) {
          this.sensorList = data.result;
        }
      });
  }

  sensorListnerData() {
    this.appSettings.socketRobotSensors$.subscribe((sensor) => {
      if (sensor.robotId === this.robotId) {
        let index = _.findLastIndex(this.sensorList, (o) => {
          return o.type === sensor.type && o.name === sensor.name;
        });
        this.sensorList[index] = { ...sensor };
      }
    });
  }

  formatDate(sensor) {
    let date;
    if (sensor.updatedAt) {
      date = moment(sensor.updatedAt);
    } else if (sensor.timestamp) {
      date = moment.unix(sensor.timestamp);
    }
    try {
      return `${this._datepipe.transform(date, 'dd/MM/yyyy, HH:mm')}`;
    } catch (err) {
      return date;
    }
  }
}
