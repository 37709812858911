import { Component, OnInit } from '@angular/core';
interface ListProps {
  name: string;
  checked: boolean;
}
@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit {
  lists: ListProps[] = [
    { name: 'Admin A', checked: false },
    { name: 'Admin B', checked: false },
    { name: 'Admin C', checked: false },
    { name: 'User A', checked: false },
    { name: 'User B', checked: false },
    { name: 'User C', checked: false },
  ];
  constructor() {}

  ngOnInit(): void {}
}
