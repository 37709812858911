import { CdkTextColumn } from '@angular/cdk/table';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'rm-datetime-column',
  template: `
    <ng-container matColumnDef>
      <th mat-header-cell *matHeaderCellDef>{{ headerText | transloco }}</th>
      <td mat-cell *matCellDef="let row">
        {{ dataAccessor(row, name) | date: 'short' }}
      </td>
    </ng-container>
  `,
})
export class DatatableDatetimeColumn<T> extends CdkTextColumn<T> {}
