import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { DatatableService } from './datatable.service';
import { uniq, find, isEmpty } from 'lodash';

@Component({
  selector: 'rm-datatable',
  templateUrl: './rm-datatable.component.html',
  styleUrls: ['./rm-datatable.component.scss'],
})
export class RmDatatableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() tableData;
  @Input() columnHeader;
  @Input() enableCheckbox = false;
  @Input() assignUnassign = false;
  @Input() noData: string;
  @Output() rowClick = new EventEmitter();
  @Output() selectChange = new EventEmitter();
  @Output() addRemove = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<any>(true, []);
  dataSource: any;
  sticky: boolean;
  displayedColumns: string[];
  enumField: any = {};

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private service: DatatableService
  ) {}

  async ngOnInit() {
    this.dataSource = new MatTableDataSource(this.tableData);

    this.populateEnumCache();

    this.selection.changed.subscribe(() => {
      this.selectChange.emit(this.selection.selected);
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    const mappedHeadres = this.columnHeader.map((def: any) => def.selector);
    if (this.enableCheckbox) {
      this.displayedColumns = ['select', ...mappedHeadres];
    } else if (this.assignUnassign) {
      this.displayedColumns = ['addRemove', ...mappedHeadres];
    } else {
      this.displayedColumns = mappedHeadres;
    }
  }

  ngOnChanges() {
    const mapped = this.columnHeader.map((def: any) => def.selector);
    if (this.enableCheckbox) {
      this.displayedColumns = ['select', ...mapped];
    } else if (this.assignUnassign) {
      this.displayedColumns = ['addRemove', ...mapped];
    } else {
      this.displayedColumns = mapped;
    }
    this.dataSource = new MatTableDataSource(this.tableData);

    this.populateEnumCache();

    this.changeDetectorRefs.detectChanges();
  }

  populateEnumCache() {
    let populatedEnumKey: string[] = [];

    this.tableData.forEach((data) => {
      Object.keys(data).forEach((key) => {
        if (typeof data[key] === 'object') {
          if (data[key].type === 'enum') {
            populatedEnumKey.push(data[key].reference);
          }
        }
      });
    });

    populatedEnumKey = uniq(populatedEnumKey);

    populatedEnumKey.forEach(async (dictType) => {
      const dataRes = await this.service.getEnumList({
        dictType,
      });
      this.enumField[dictType] = dataRes.result;
    });
  }

  renderEnumValue(reference, value): string {
    if (!isEmpty(this.enumField)) {
      const enumData = find(this.enumField[reference], {
        dictKey: `${value}`,
      });
      if (enumData) {
        return enumData.dictValue;
      } else {
        return '-';
      }
    }
    return 'loading...';
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableData.length;
    return numSelected === numRows;
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  masterToggle() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isAllSelected()
      ? this.selection.clear()
      : this.tableData.forEach((row: any) => this.selection.select(row));
  }

  trackByIndex(i) {
    return i;
  }

  isObject(data) {
    return typeof data === 'object';
  }

  handleRowClick(data) {
    console.log('CLICKED', data);
    this.rowClick.emit(data);
  }

  handleAddRemove(data) {
    this.addRemove.emit(data);
  }

  clearSelections() {
    this.selection.clear();
  }
}
