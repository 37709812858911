import { TranslocoService } from '@ngneat/transloco';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FileInputService } from './file-input-service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { SnackbarService } from 'app/services/snackbarService';
import { RobotService } from 'app/modules/settings/robots/robots.service';
import { Subscription } from 'rxjs';
import { ProfileService } from 'app/layout/common/header/profile/profile.service';

interface ObservableFile extends File {
  id?: string;
  detail?: any;
  uploadProgress?: number;
}

@Component({
  selector: 'file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements OnDestroy {
  @Input() title: string;
  @Input() description: string;
  @Input() type: string;
  @Input() accept: string;
  @Input() multiple: boolean;
  @Input() isShowValidationError: Boolean;
  @Input() isShowImageContent: boolean = true;

  @Output() uploadChange = new EventEmitter();
  @Output() clickEvent = new EventEmitter();
  @Output() removeFile = new EventEmitter();
  @Output() handleIsSaveEnabled = new EventEmitter();

  files: ObservableFile[] = [];
  extensions: string = '*';

  private isRemoveImageSubscribe: Subscription;
  private isRemoveProfileSubscribe: Subscription;

  constructor(
    private fileInputService: FileInputService,
    private snackBar: SnackbarService,
    private translocoService: TranslocoService,
    private robotSvc: RobotService,
    private profileSvc: ProfileService,
  ) {
    //listener to remove image profile and thread if user discard the input/saved
    this.isRemoveImageSubscribe = this.robotSvc.isRemoveImage.subscribe(
      (isRemove: boolean) => {
        if (isRemove) {
          this.onRemove('robot-image'); //the 'robot-image' don't have any meaning, just a placeholder value
        }
      }
    );
    this.isRemoveProfileSubscribe = this.profileSvc.isRemoveProfileImage.subscribe( (isRemove: boolean) => {
      if (isRemove) {
        this.onRemove('');
      }
    });
  }

  getFileType(fileType: string) {
    return fileType.split('/').shift();
  }
  getFileExtension(fileType: string) {
    return fileType.split('/').pop();
  }
  getNotUploadedCount() {
    const count = this.files.filter(
      (item: ObservableFile) => item.uploadProgress === undefined
    ).length;

    if (count !== 0) {
      return `(${count})`;
    }
    return undefined;
  }

  onSelect(event) {
    if (this.multiple) {
      this.files.push(...event.addedFiles);
    } else {
      if (event.addedFiles.length > 0) {
        this.files[0] = event.addedFiles[0];
        this.handleUpload();
      } else {
        this.snackBar.openSnackBar({
          message: this.translocoService.translate('File is not allowed'),
          type: 'failed',
        });
      }
    }
  }

  onRemove(event) {
    this.removeFile.emit();
    this.files.splice(this.files.indexOf(event), 1);
  }

  handleAction(): void {
    this.clickEvent.emit();
  }

  handleUpload(): void {
    this.handleIsSaveEnabled.emit(false);
    this.files.forEach((file) => {
      if (file.uploadProgress === undefined) {
        file.uploadProgress = 0;
        this.fileInputService
          .uploadFile(file)
          .subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                file.uploadProgress = Math.round(
                  (event.loaded / event.total) * 100
                );
                break;
              case HttpEventType.Response:
                file.id = event.body.result;
                // Give value to all subscriptions
                this.fileInputService.watchIdValue$.next(file.id);

                this.fileInputService
                  .getAsset(event.body.result)
                  .subscribe((data: any) => {
                    file.detail = data.result;

                    this.uploadChange.emit(this.files);
                    this.handleIsSaveEnabled.emit(true);
                  });
            }
          });
      }
    });
  }

  clearFiles() {
    this.files = [];
  }

  ngOnDestroy(): void {
    if (this.isRemoveImageSubscribe) {
      this.isRemoveImageSubscribe.unsubscribe();
    }
    this.isRemoveProfileSubscribe?.unsubscribe();
  }
}
