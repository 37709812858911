import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Door } from 'app/services/api.types';

@Component({
  selector: 'door-card',
  templateUrl: './door-card.component.html',
  styleUrls: ['./door-card.component.scss'],
})
export class DoorCardComponent implements OnInit {
  @Input() door: Door;
  @Output() changeStatus = new EventEmitter();

  public ngOnInit(): void {}

  public handleChangeStatus(status: number): void {
    this.changeStatus.emit({ door: this.door, status: status });
  }
}
