import { TranslocoService } from '@ngneat/transloco';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  public pageText = 'Page';
  public ofText = 'of';
  constructor(private translocoService: TranslocoService) {
    super();
    this.translocoService.load('en').subscribe();
    this.translocoService.load('zh').subscribe();
    this.translocoService.langChanges$.subscribe(() => {
      this.translocoService.selectTranslate('Page').subscribe((res: string) => {
        this.pageText = res;
      });
      this.translocoService
        .selectTranslate('Items per page:')
        .subscribe((res: string) => {
          this.itemsPerPageLabel = res;
        });
      this.translocoService.selectTranslate('of').subscribe((res: string) => {
        this.ofText = res;
      });
      this.translocoService
        .selectTranslate('Next page')
        .subscribe((res: string) => {
          this.nextPageLabel = res;
        });
      this.translocoService
        .selectTranslate('Previous page')
        .subscribe((res: string) => {
          this.previousPageLabel = res;
        });
      this.changes.next();
    });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    const totalPage = Math.ceil(length / pageSize);
    return `${this.pageText} ${page + 1} ${this.ofText} ${totalPage}`;
  };
}
