import { NgModule } from '@angular/core';
import { SidebarModule } from 'ng-sidebar';
import { RightSidebarLayoutComponent } from './right-sidebar-layout.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [RightSidebarLayoutComponent],
  imports: [SidebarModule, MatIconModule, CommonModule],
  exports: [RightSidebarLayoutComponent],
})
export class RightSidebarLayoutModule {}
