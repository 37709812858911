import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import _ from 'lodash';
import { map } from 'rxjs/operators';
import { ApiBase } from './api-base.service';
import { ListQuery, Notification } from './api.types';
@Injectable({
  providedIn: 'root',
})
export class ApiNotification extends ApiBase<Notification> {
  constructor(http: HttpClient, private auth: AuthService) {
    super(http);
    this.resourceBaseUrl = 'notification';
  }
  /**
   * Mark the notification as read
   */
  public markAs = (id: string, action: 'read' | 'unread') =>
    this.patch(`${this.resourceBaseUrl}?ids=${id}`, { action });
  public markAllAs = (action: 'read' | 'unread') =>
    this.patch(`${this.resourceBaseUrl}`, { action });

  public list = (query: ListQuery) => {
    // Because API returns all notifications from all users,
    // we need to manually add a filter to only return user's notifications.

    const customFilter = [
      ...query.filter,
      {
        name: 'userId',
        column: 'notifications.user_id',
        operator: 'eq',
        value: [this.auth.userId],
        extra: '',
        dataType: '',
        virtual: false,
      },
    ];

    query.filter = customFilter;

    return this.post(`${this.resourceBaseUrl}/list`, query).pipe(
      // convert the status from property `read: boolean` to propery `status: string`
      map((res) => {
        const notifications = res.result.list.map((notification) => ({
          ...notification,
          status: notification.read ? 'read' : 'unread',
        }));
        return _.set(res, 'result.list', notifications);
      })
    );
  };
}
