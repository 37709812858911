import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  private headers = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private _http: HttpClient) {}

  updateMission(data) {
    return this._http.put('/api/v2/mission/update', data, this.headers);
  }

  updateEvent(data) {
    return this._http.put('/api/v2/events', data, this.headers);
  }

  getAsset(id: string) {
    return this._http.get('/api/v2/asset/get-file/' + id, {
      responseType: 'blob',
    });
  }
}
