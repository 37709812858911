import { CustomMatPaginatorIntl } from 'app/modules/events/custom-mat-paginator-int';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { SharedModule } from 'app/shared/shared.module';
import { NotificationDetailComponent } from './notification-detail';
import { NotificationListComponent } from './notification-list/notification-list';
import { NotificationStatusComponent } from './notification-status';

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationListComponent,
    NotificationStatusComponent,
    NotificationDetailComponent,
  ],
  imports: [OverlayModule, PortalModule, SharedModule, ScrollingModule],
  exports: [NotificationsComponent, NotificationListComponent],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
  ],
})
export class NotificationsModule {}
