import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'text-sofia',
  templateUrl: './text-sofia.component.html',
  styleUrls: ['./text-sofia.component.scss'],
})
export class TextSofiaComponent implements OnInit {
  @Input() type?: string;
  @Input() enableAction?: boolean;
  @Output() clickEvent = new EventEmitter();

  ngOnInit(): void {}

  handleAction(): void {
    this.clickEvent.emit();
  }
}
