import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBase } from './api-base.service';
import { ResponseWithoutList, RobotSensorList } from './api.types';

@Injectable({
  providedIn: 'root',
})
export class ApiRobotSensors extends ApiBase<RobotSensorList> {
  constructor(http: HttpClient) {
    super(http);
    this.base = '/api/v2/';
  }

  public getRobotSensorsbyId = (id: string) =>
    this.http.get<ResponseWithoutList<RobotSensorList>>(
      `${this.base}robotsensordata/getByRobot?robotId=${id}`,
      this.headers
    );
}
