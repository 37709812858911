import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { LiftCardComponent } from './lift-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { ActionButtonModule } from '../action-button/action-button.module';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  declarations: [LiftCardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    TranslocoRootModule,
    ActionButtonModule,
    MatTooltipModule,
  ],
  exports: [LiftCardComponent],
})
export class LiftCardModule {}
