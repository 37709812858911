export const TIME_INTERVAL = 3000;
export const SKILL_NAME = 'RM-TELEOP';
export const FILTER = [
  {
    name: 'Skill Name',
    column: 'skills.name',
    operator: 'rlike',
    value: [SKILL_NAME],
    extra: '',
    dataType: 'text',
    virtual: false,
  },
];
export const MOVE_SKILLNAME = 'RM-TELEOP-MOVE';
export const TEXTTOSPEECH_SKILLNAME = 'RM-TTS';
export const CAMERAPTZ_SKILLNAME = 'RM-CAMERA';
export const BROADCAST_SKILLNAME = 'RM-BROADCAST';
export const SWITCH_LIGHT = 'RM-LIGHT';
export const SWITCH_SPEAKER = 'RM-SPEAKER';
export const TELECONFERENCE_SKILLNAME = 'RM-TELECONFERENCE';
export const E_STOP = 'RM-REMOTE E-STOP';

export interface IControlData {
  id: string;
  robotId: string;
  companyId: string;
  timestamp: any;
  operateType: number; // 1:tts,2: paly media,3:skill
  payload: any;
}

export interface IMoveData {
  id: string;
  robotId: string;
  companyId: string;
  timestamp: any;
  payload: {
    x: number;
    y: number;
  };
}

export interface IRobotWS {
  id: string;
  timestamp: any;
  robotId: string;
  companyId: string;
  enableVideo: boolean;
  enableAudio: boolean;
  autonomous: boolean;
  hostUrl: string;
  token: string;
}

export interface IVideoConference {
  id: string;
  timestamp: any;
  robotId: string;
  companyId: string;
  streams: any[];
}

export interface IAutonomous {
  id: string;
  robotId: string;
  companyId: string;
  timestamp: any;
  teleoperation: boolean;
}

export interface IControlTTS {
  id: string;
  robotId: string;
  companyId: string;
  timestamp: any;
  operateType: number;
  executeType: number;
  payload: {
    skill: string;
    parameters: [
      {
        key: string; // "repeat",
        value: number; // -1: continuous loop, 0: no repeat
      },
      {
        key: string; // "tts",
        value: string;
      }
    ];
  };
  commandId: string;
}

export interface ILocation {
  lat: number;
  lng: number;
}

export interface IControlMedia {
  id: string;
  robotId: string;
  companyId: string;
  timestamp: any;
  operateType: number;
  executeType: number;
  payload: {
    skill: string;
    parameters: [
      {
        key: string; // "switch"
        value: number; // 1: on, 2: off
      },
      {
        key: string; // "repeat"
        value: number; // -1: continuous loop, 0: no repeat
      },
      {
        key: string; // "url"
        value: string;
      },
      {
        key: string; // "type"
        value: number; // 1: image, 2: audio, 3: video
      }
    ];
    // type: number;
    // url: string;
    // repeat: number; // 1-play once, 0-recurring
  };
  commandId: string;
}

export interface IPtzcameraMove {
  id: string;
  robotId: string;
  companyId: string;
  timestamp: number;
  operateType: number; // 1=move, 2=zoom
  name: string;
  payload: {
    move: {
      x: number;
      y: number;
    };
  };
}

export interface IPtzcameraZoom {
  id: string;
  robotId: string;
  companyId: string;
  timestamp: number;
  operateType: number; // 1=move, 2=zoom
  name: string;
  payload: {
    zoom: number;
  };
}

export const audioRequest = {
  pageNo: 1,
  pageSize: 100,
  filter: [
    {
      name: 'User Name',
      column: 'assets.type',
      operator: 'eq',
      value: ['audio'],
      extra: '',
      dataType: 'text',
      virtual: false,
    },
  ],
  order: [
    {
      name: 'Update Time',
      column: 'updated_at',
      type: 'desc',
    },
  ],
};

export interface IPtzcameraLift {
  id: string;
  robotId: string;
  companyId: string;
  timestamp: number;
  operateType: number; // 1=move, 2=zoom, 3=lift
  name: string;
  payload: {
    lift: number;
  };
}

export interface IPtzCameraReset {
  id: string;
  robotId: string;
  companyId: string;
  timestamp: number;
  operateType: number; // 1=move, 2=zoom, 3=lift, 4=reset
  name: string;
  payload: {
    presetPosition: string;
  };
}

export const LIGHTDEVICES = [
  {
    device: 'Light',
    resouceType: 1,
  },
];

export const SPEAKERDEVICES = [
  {
    device: 'Speaker',
    resouceType: 2,
  },
];

export interface IControlSwitch {
  id: string;
  robotId: string;
  companyId: string;
  timestamp: any;
  operateType: number; // 4
  payload: {
    resouceType: number; // 1:light,2:broadcast
    type: number; // 1:on,2:off
  };
  commandId: string;
}

export interface ControlStatus {
  robotId: string;
  companyId: string;
  timestamp: any;
  commandId: string;
  status: number;
}

export interface VideoRequest {
  id: string;
  timestamp: any;
  robotId: string;
  companyId: string;
  enableVideo: boolean;
  enableAudio: boolean;
  hostUrl: any;
  token: string;
}

export interface CallRobot {
  id: string;
  companyId: string;
  timestamp: any;
  robotId: string;
  content: string;
  action: number;
  callId: string;
}

// export const WHITELIST_SKILLS = [
//   'RM-BROADCAST',
//   'RM-CAMERA',
//   // "RM-CAMERA-STREAM",
//   // "RM-GOTO",
//   'RM-HORN',
//   'RM-LIGHT',
//   // "RM-LOCALIZE",
//   // "RM-OFF-SIREN",
//   'RM-REMOTE E-STOP',
//   // "RM-ROUTINE",
//   // "RM-SIREN",
//   'RM-SPEAKER',
//   // "RM-TELEOP-MOVE",
//   // "RM-TELEOP-VC",
//   // "RM-TELEROBOTICS",
//   'RM-TTS',
//   'RM-TTS-SOCIAL DISTANCING',
//   'RM-TTS-WEAR MASK',
//   'RM-TELECONFERENCE',
//   'RM-PATROL',
//   'RM-BLINKER',
// ];

export const TELEOPSSKILLSFILTER = [
  {
    name: 'Show in Teleops',
    column: 'skills.show_on_teleops',
    operator: 'eq',
    extra: '',
    dataType: 'number',
    virtual: false,
    value: ['1'],
  },
];

export const TELEOPSSKILLSPAGENO = 1;
export const TELEOPSSKILLSPAGESIZE = 50;
