import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Card, CardEvent } from 'app/shared/interface/card';
import { LogHistory } from 'app/shared/interface/logHistory';
import * as moment from 'moment';

@Component({
  selector: 'app-log-history-card',
  templateUrl: './log-history-card.component.html',
  styleUrls: ['./log-history-card.component.scss'],
})
export class LogHistoryCardComponent implements OnInit {
  @Input() item: LogHistory;
  @Input() card: Card;

  @Output() action = new EventEmitter<CardEvent>();

  chevronUpIcon: string = 'app/../assets/icons/chevron-up.svg';
  chevronDownIcon: string = 'app/../assets/icons/chevron-down.svg';

  constructor() {}

  ngOnInit(): void {}

  toggleShow(): void {
    this.card.isOpen = !this.card.isOpen;
  }

  toggleSelected(): void {
    this.card.isSelected = !this.card.isSelected;
    this.action.emit({
      type: 'select',
      id: this.item.id,
      data: this.card.isSelected,
    });
  }

  public showTimestamp(value: number) {
    return moment(value).format('DD/MM/YYYY, hh:mm A');
  }

  public showTimeAgo(value: number) {
    return moment(value).fromNow();
  }
}
