import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AutoCompleteService {
  constructor(private http: HttpClient) {}

  getSelections(modelName) {
    return this.http
      .post<any>('/api/v2/datadict/getByType', modelName)
      .toPromise();
  }

  addRecord(modelName, value) {
    return this.http
      .post<any>('/api/v2/datadict/create', {
        dictType: modelName,
        dictKey: value,
        dictValue: value,
      })
      .toPromise();
  }
}
