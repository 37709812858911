import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogHistoryCardComponent } from './log-history-card.component';
import { TextSofiaModule } from 'app/shared/ui-components/text-sofia/text-sofia.module';
import { StatusModule } from '../../status.component';

@NgModule({
  declarations: [LogHistoryCardComponent],
  imports: [CommonModule, TextSofiaModule, StatusModule],
  exports: [LogHistoryCardComponent],
})
export class LogHistoryCardModule {}
